import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { StaticMetaItemService } from '@mt-ng2/base-service';
import { ILineHideType } from '../../../model/interfaces/line-hide-type';

@Injectable({
    providedIn: 'root',
})
export class LineHideTypeService extends StaticMetaItemService<ILineHideType> {
    constructor(public http: HttpClient) {
        super('LineHideTypeService', 'LineHideType', 'LineHideTypeIds', '/line-hide-types', http);
    }

}
