import { Component, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { LocalStorageService } from '@common/services/local-storage.service';
import { IHeatPumpConfigurationStatus } from '@model/interfaces/custom/heat-pump-configuartion-status';
import { IPart } from '@model/interfaces/part';
import { ModalService } from '@mt-ng2/modal-module';
import { CustomerProposalInterfaceStateService } from 'admin-portal/estimator/services/customer-proposal-interface-state.service';
import { HeatPumpFormStateService } from 'admin-portal/estimator/services/heat-pump-form-state.service';
import { HeatPumpService } from 'admin-portal/estimator/services/heat-pump.service';
import { Subscription } from 'rxjs';

@Component({
    selector: 'heat-pumps',
    styles: [
        `
            .table {
                color: black !important;
            }
            .btn-fab-md {
                float: right;
            }
        `,
    ],
    template: '',
})
export class HeatPumpsComponent implements OnDestroy {
    parts: IPart[] = [];
    subscriptions: Subscription = new Subscription();
    heatPumpConfigurationStatus: IHeatPumpConfigurationStatus;
    customerProposalId: number;

    constructor(
        private _proposalStateService: CustomerProposalInterfaceStateService,
        private _heatPumpStateService: HeatPumpFormStateService,
        private _localStorageService: LocalStorageService,
        private _modalService: ModalService,
        private _heatPumpService: HeatPumpService,
    ) {}

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    updateHeatPumpConfigurationStatus(): void {
        this.heatPumpConfigurationStatus.IsValid = this.configStatusIsValid();
        this._proposalStateService.heatPumpConfigurationStatus$.next(this.heatPumpConfigurationStatus);
    }

    private configStatusIsValid(): boolean {
        return this._heatPumpService.validateHeatPumpConfig(this.heatPumpConfigurationStatus).length === 0;
    }

    getIdFromRoute(route: ActivatedRoute, param: string): number {
        const id = route.snapshot.paramMap.get(param);
        const parsedInt = parseInt(id, 10);
        return isNaN(parsedInt) ? null : parsedInt;
    }

    validateBranchBoxPrompt(): void {
        const timestamp: string | number | Date = this._localStorageService.get('BranchBoxPromptTimestamp');
        const lastPromptTimestamp = new Date(timestamp) ?? null;
        if (!lastPromptTimestamp || this.promptHasExpired(lastPromptTimestamp)) {
            this._modalService
                .showModal({
                    text: 'The Unit you selected requires a branch box, please add the right configuration to the accessories',
                    title: 'Branch Box',
                    icon: 'info',
                })
                .subscribe((res) => {
                    if (res.isConfirmed) {
                        this._localStorageService.set('BranchBoxPromptTimestamp', new Date());
                    }
                });
        }
    }

    private promptHasExpired(lastPromptTimestamp: Date): boolean {
        lastPromptTimestamp.setMinutes(lastPromptTimestamp.getMinutes() + this._heatPumpStateService.branchBoxPromptTimeout);
        return lastPromptTimestamp.getTime() < new Date().getTime();
    }
}
