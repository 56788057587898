import { NgModule } from '@angular/core';

import { AdminPortalSharedModule } from '@common/shared.module';
import { AdminPortalFeatureModule } from '@common/feature.module';
import { EstimatorRoutingModule } from './estimator-routing.module';

import { EstimatorSettingsComponent } from './estimator-settings/estimator-settings.component';
import { CustomerProposalFormComponent } from './customer-proposal-form/customer-proposal-form.component';
import { CustomerProposalInterfaceComponent } from './customer-proposal-interface/customer-proposal-interface.component';
import { ProposalStatusComponent } from './proposal-status/proposal-status.component';
import { HeatPumpsFormComponent } from './heat-pumps-form/heat-pumps-form.component';
import { OutdoorHeatPumpsComponent } from './heat-pumps-form/outdoor-heat-pumps/outdoor-heat-pumps.component';
import { IndoorHeatPumpsComponent } from './heat-pumps-form/indoor-heat-pumps/indoor-heat-pumps.component';
import { HeatPumpsComponent } from './heat-pumps-form/heat-pumps.component';
import { ProposalPricingDetailComponent } from './proposal-pricing-detail/proposal-pricing-detail.component';

import { ProposalJobDetailsFormComponent } from './proposal-job-details-form/proposal-job-details-form.component';
import { ProposalElectricalDetailsFormComponent } from './proposal-electrical-details-form/proposal-electrical-details-form.component';
import { AccessoriesComponent } from './heat-pumps-form/accessories/accessories.component';
import { ProposalPricingPageComponent } from './proposal-pricing-page/proposal-pricing-page.component';
import { ProposalSignatureComponent } from './proposal-signature/proposal-signature.component';
import { ModalModule } from '@mt-ng2/modal-module';
import { ImageCropperModule } from 'ngx-image-cropper';
import { IndoorHeatPumpLineItemComponent } from './heat-pumps-form/indoor-heat-pumps/indoor-heat-pump-line-item/indoor-heat-pump-line-item.component';
import { OutdoorHeatPumpLineItemComponent } from './heat-pumps-form/outdoor-heat-pumps/outdoor-heat-pump-line-item/outdoor-heat-pump-line-item.component';
import { ProposalPricingOverrideComponent } from './proposal-pricing-detail/proposal-pricing-overrides/proposal-pricing-override.component';
import { ManufacturerOverrideComponent } from './proposal-pricing-detail/manufacturer-overrides/manufacturer-override.component';
import { OverrideComponent } from './proposal-pricing-detail/override.component';
import { ProposalLoanCalculatorComponent } from './proposal-pricing-detail/proposal-loan-calculator/proposal-loan-calculator.component';
import { ManufacturerMetaItemManagedListComponent } from './estimator-settings/manufacturer-meta-item-managed-list.component';
import { BtuCalculationsFormComponent } from './btu-calculations-form/btu-calculations-form.component';
import { BtuCalculationLineItemComponent } from './btu-calculations-form/btu-calculation-line-item/btu-calculation-line-item.component';
import { WarrantySettingsComponent } from './estimator-settings/warranty-settings/warranty-settings.component';
import { ManufacturerComponent } from './estimator-settings/manufacturer.component';
import { CustomerDepositOverrideComponent } from './proposal-pricing-detail/customer-deposit-amount-override/customer-deposit-amount-override.component';
import { ProposalJobDescriptionComponent } from './proposal-pricing-detail/proposal-job-description/proposal-job-description.component';
import { AdminCheckoutComponent } from './admin-checkout/admin-checkout.component';
@NgModule({
    declarations: [
        EstimatorSettingsComponent,
        CustomerProposalFormComponent,
        CustomerProposalInterfaceComponent,
        ProposalStatusComponent,
        HeatPumpsFormComponent,
        OutdoorHeatPumpsComponent,
        IndoorHeatPumpsComponent,
        HeatPumpsComponent,
        ProposalJobDetailsFormComponent,
        ProposalPricingDetailComponent,
        ProposalElectricalDetailsFormComponent,
        AccessoriesComponent,
        ProposalPricingPageComponent,
        ProposalSignatureComponent,
        IndoorHeatPumpLineItemComponent,
        OutdoorHeatPumpLineItemComponent,
        ProposalPricingOverrideComponent,
        ManufacturerOverrideComponent,
        OverrideComponent,
        ProposalLoanCalculatorComponent,
        ManufacturerMetaItemManagedListComponent,
        BtuCalculationsFormComponent,
        BtuCalculationLineItemComponent,
        WarrantySettingsComponent,
        ManufacturerComponent,
        CustomerDepositOverrideComponent,
        AdminCheckoutComponent,
        ProposalJobDescriptionComponent,
    ],
    exports: [ProposalSignatureComponent],
    imports: [AdminPortalSharedModule, AdminPortalFeatureModule, EstimatorRoutingModule, ModalModule, ImageCropperModule],
})
export class EstimatorModule { }
