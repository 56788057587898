import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MetaItemService } from '@mt-ng2/base-service';
import { IManufacturer } from '@model/interfaces/manufacturer';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class ManufacturerService extends MetaItemService<IManufacturer> {
    constructor(public http: HttpClient) {
        super('ManufacturerService', 'Manufacturer', 'ManufacturerIds', '/estimator/manufacturers', http);
    }

    updateManufacturer(manufacturer: IManufacturer): Observable<void> {
        return this.http.put<void>(`/estimator/manufacturers/update-manufacturer`, manufacturer);
    }
}
