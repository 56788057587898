<th>
    <select
        *ngIf="parts.length > 0"
        class="form-control"
        id="part"
        [(ngModel)]="selectedOutdoorHeatPumps[index].PartId"
        (ngModelChange)="onPartUpdate($event, index)"
        [ngModelOptions]="{ standalone: true }"
        [disabled]="proposalIsApproved"
    >
        <option *ngFor="let p of parts" [value]="p.Id">{{ p.Name }}</option>
    </select>
</th>
<th>
    <span (click)="adjustPartQuantity(1, index)" *ngIf="!proposalIsApproved">
        <i class="fa fa-lg fa-plus-circle"></i>
    </span>
    <span>{{ selectedOutdoorHeatPumps[index].Quantity }}</span>
    <span *ngIf="selectedOutdoorHeatPumps[index].Quantity > 0 && !proposalIsApproved" (click)="adjustPartQuantity(-1, index)">
        <i class="fa fa-lg fa-minus-circle"></i>
    </span>
</th>
<th>
    {{ selectedOutdoorHeatPumps[index].MinIDU }}
</th>
<th>
    {{ selectedOutdoorHeatPumps[index].MaxIDU }}
</th>
<th>
    {{ selectedOutdoorHeatPumps[index].RequiresBranchBox ? 'Yes' : 'No' }}
</th>
<th>
    <img src="{{ heatPumpImgPath }}" />
</th>
<th>
    <button class="btn btn-default" (click)="removeOutdoorHeatPump(index)" [disabled]="proposalIsApproved">Remove</button>
</th>
