import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { StaticMetaItemService } from '@mt-ng2/base-service';
import { IElectricalFinishedBasement } from '../../../model/interfaces/electrical-finished-basement';

@Injectable({
    providedIn: 'root',
})
export class ElectricalFinishedBasementService extends StaticMetaItemService<IElectricalFinishedBasement> {
    constructor(public http: HttpClient) {
        super('ElectricalFinishedBasementService', 'ElectricalFinishedBasement', 'BasementIds', '/electrical-finished-basements', http);
    }

}
