import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseService, IMetaItem } from '@mt-ng2/base-service';
import { IPart } from '@model/interfaces/part';
import { Observable } from 'rxjs';
import { IAutoIncludeAccessory } from '@model/interfaces/auto-include-accessory';

@Injectable({
    providedIn: 'root',
})
export class PartService extends BaseService<IPart> {
    constructor(public http: HttpClient) {
        super('/parts', http, null, { entityName: 'Parts' });
    }

    createPart(part: IPart, compatibleIndoorUnits: number[], autoIncludedAccecssories: IAutoIncludeAccessory[]): Observable<number> {
        return this.http.post<number>(`/parts/create`, { Part: part, CompatibleIndoorUnitIds: compatibleIndoorUnits, AutoIncludeAccessories: autoIncludedAccecssories });
    }

    updatePart(part: IPart, compatibleIndoorUnits: number[], autoIncludedAccecssories: IAutoIncludeAccessory[]): Observable<void> {
        return this.http.put<void>(`/parts/update`, { Part: part, CompatibleIndoorUnitIds: compatibleIndoorUnits, AutoIncludeAccessories: autoIncludedAccecssories });
    }

    getAvailableIndoorUnits(partId: number, manufacturerId: number): Observable<IMetaItem[]> {
        return this.http.get<IMetaItem[]>(`/parts/${partId}/manufacturer/${manufacturerId}/available-indoor-units`);
    }

    getAvailableOutdoorUnitsByManufacturer(manufacturerId: number, proposalId: number): Observable<IPart[]> {
        return this.http.get<IPart[]>(`/parts/manufacturer/${manufacturerId}/available-outdoor-units/${proposalId}`);
    }

    getAvailableIndoorUnitsByManufacturerAndOutdoorUnits(proposalId: number, manufacturerId: number, outdoorUnitIds: number[], categoryId?: number): Observable<HttpResponse<IPart[]>> {
        let params = new HttpParams();
        params = params.append('ManufacturerId', manufacturerId.toString());
        params = params.append('OutdoorUnitIds', outdoorUnitIds.toString());
        if (categoryId) {
            params = params.append('CategoryId', categoryId.toString());
        }
        return this.http.get<IPart[]>(`/parts/compatible-indoor-units/${proposalId}`, { observe: 'response', params: params });
    }

    getAvailableAccessoriesByManufacturerAndProposal(manufacturerId: number, proposalId: number): Observable<IPart[]> {
        return this.http.get<IPart[]>(`/parts/accessories/manufacturer/${manufacturerId}/${proposalId}`);
    }

    getAvailableAccessoriesByManufacturer(manufacturerId: number): Observable<IPart[]> {
        return this.http.get<IPart[]>(`/parts/accessories/manufacturer/${manufacturerId}`);
    }

    getDrainLines(): Observable<IPart[]> {
        return this.http.get<IPart[]>(`/parts/drain-lines`);
    }

    uploadHeatPumpImage(imageBase64: string, partId: number): Observable<string> {
        const byteString = atob(imageBase64.split(',')[1]);
        const ab = new ArrayBuffer(byteString.length);
        const ia = new Uint8Array(ab);
        for (let i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }
        const bb = new Blob([ab]);
        const formData: FormData = new FormData();
        formData.append('file', bb);
        return this.http.post<string>(`/parts/${partId}/image`, formData);
    }

    formatTitleText(part: IPart): void {
        this.setTitle(`${part.Name} ${part.NeedsReview ? '[NEEDS REVIEW]' : ''}`);
    }
}
