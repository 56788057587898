import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseService } from '@mt-ng2/base-service';
import { IProposalBtuCalculation } from '@model/interfaces/proposal-btu-calculation';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class ProposalBtuCalculationService extends BaseService<IProposalBtuCalculation> {

    constructor(public http: HttpClient) {
        super('/proposal-btu-calculations', http);
    }

    updateProposalBtuCalculations(proposalId: number, calculations: IProposalBtuCalculation[]): Observable<void> {
        return this.http.put<void>(`/proposal-btu-calculations/proposal/${proposalId}/update`, calculations);
    }

    getByProposalId(proposalId: number): Observable<IProposalBtuCalculation[]> {
        return this.http.get<IProposalBtuCalculation[]>(`/proposal-btu-calculations/proposal/${proposalId}`);
    }

    getBtuCalculationsPdf(proposalId: number): Observable<string> {
        return this.http.get<string>(`/proposal-btu-calculations/proposal/${proposalId}/pdf`);
    }
}
