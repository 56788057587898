import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IManufacturer } from '@model/interfaces/manufacturer';
import { NumericControlTypes } from '@mt-ng2/numeric-control';

@Component({
    selector: 'manufacturer',
    template: `
    <div class="miles-card padded">
                <h4>{{manufacturer.Name}}</h4>
                <div class="container-fluid nopadding">
                    <div class="row-fluid">
                        <div class="nopadding">
                            <label style="padding-left: 12px">Markup Percentage</label>
                        </div>
                    </div>
                </div>
                <div>
                    <div>
                        <div class="container-fluid nopadding">
                            <div class="row-fluid">
                                <div class="nopadding">
                                    <div class="input-group">
                                        <mt-numeric
                                            [type]="numericControlTypes.Percentage"
                                            [scale]="2"
                                            [(ngModel)]="manufacturer.MarkupPercentage"
                                            (ngModelChange)="onMarkupPercentageChange()"
                                        ></mt-numeric>
                                        <span class="input-group-addon">%</span>
                                    </div>
                                </div>
                            </div>
                            <br />
                            <button type="submit" class="btn btn-primary" (click)="onUpdate.emit(manufacturer)">Save</button>
                        </div>
                    </div>
                </div>
            </div>`,
})

export class ManufacturerComponent {
    @Input() manufacturer: IManufacturer;
    @Output() onUpdate: EventEmitter<IManufacturer> = new EventEmitter<IManufacturer>();
    numericControlTypes = NumericControlTypes;

    constructor() { }

    onMarkupPercentageChange(): void {
        if (!this.manufacturer.MarkupPercentage) {
            this.manufacturer.MarkupPercentage = 0;
        }
    }
}
