import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { environment } from '@environment';
import { IOutdoorHeatPumpLineItem } from '@model/interfaces/custom/outdoor-heat-pump-line-item';
import { IOutdoorHeatPumpLineItemUpdateEvent } from '@model/interfaces/custom/outdoor-heat-pump-line-item-update-event';
import { IPart } from '@model/interfaces/part';
import { ModalService } from '@mt-ng2/modal-module';
import { HeatPumpFormStateService } from 'admin-portal/estimator/services/heat-pump-form-state.service';
import { PartService } from 'admin-portal/parts/services/part.service';

@Component({
    selector: '[outdoor-heat-pump-line-item]',
    templateUrl: 'outdoor-heat-pump-line-item.component.html',
})
export class OutdoorHeatPumpLineItemComponent implements OnInit, OnChanges {
    @Input() proposalIsApproved: boolean;
    @Input() proposalId: number;
    @Input() index: number;
    @Input() selectedOutdoorHeatPumps: IOutdoorHeatPumpLineItem[];
    @Input() parts: IPart[];
    @Input() manufacturerId: number;
    @Input() categoryId: number;

    @Output() onSelectedHeatPumpsUpdate: EventEmitter<IOutdoorHeatPumpLineItemUpdateEvent> = new EventEmitter<IOutdoorHeatPumpLineItemUpdateEvent>();
    imgPath = `${environment.docPath}heat-pump-images/`;
    heatPumpImgPath: string;
    requiresBranchBox = false;
    partId: number;

    constructor(private partService: PartService, private heatPumpStateService: HeatPumpFormStateService, private modalService: ModalService) {}

    ngOnInit(): void {
        this.partId = +this.selectedOutdoorHeatPumps[this.index].PartId;
    }

    ngOnChanges(): void {
        if (this.parts.length > 0 && this.selectedOutdoorHeatPumps) {
            const partId = this.selectedOutdoorHeatPumps[this.index].PartId;
            if (partId) {
                const selectedPart = this.parts.find((p) => p.Id === +partId);
                this.heatPumpImgPath = selectedPart.Image ? this.imgPath + selectedPart.Image : '';
                this.requiresBranchBox = selectedPart.RequiresBranchBox;
            }
        }
    }

    onPartUpdate(partIdString: string, index: number): void {
        const selectedPartId = +partIdString;
        this.partService.getAvailableIndoorUnitsByManufacturerAndOutdoorUnits(this.proposalId, this.manufacturerId, [+selectedPartId], this.categoryId).subscribe((parts) => {
            const indoorHeatPumps = this.heatPumpStateService.proposalIndoorHeatPumps$.value;
            const partIds = parts.body.map((p) => p.Id);

            if (indoorHeatPumps.length > 0 && !indoorHeatPumps.every((h) => partIds.includes(h.PartId))) {
                this.modalService
                    .showModal({
                        title: 'Warning',
                        text: 'On proceeding, incompatible IDUs will be replaced with the first compatible IDU',
                        showCancelButton: true,
                    	icon: 'warning',
})
                    .subscribe((res) => {
                        if (res.isConfirmed) {
                            this.partId = selectedPartId;
                            this.updatePart(selectedPartId, index);
                        } else {
                            this.updatePart(selectedPartId, index);
                        }
                    });
            } else {
                this.updatePart(selectedPartId, index);
            }
        });
    }

    updatePart(partId: number, index: number) {
        const selectedPart = this.parts.find((p) => p.Id === partId);
        this.selectedOutdoorHeatPumps[index].PartId = selectedPart.Id;
        this.selectedOutdoorHeatPumps[index].Quantity = 1;
        this.selectedOutdoorHeatPumps[index].MinIDU = selectedPart.MinIduConnections;
        this.selectedOutdoorHeatPumps[index].MaxIDU = selectedPart.MaxIduConnections;
        this.selectedOutdoorHeatPumps[index].RequiresBranchBox = selectedPart.RequiresBranchBox;
        this.heatPumpImgPath = selectedPart.Image ? this.imgPath + selectedPart.Image : '';
        this.requiresBranchBox = selectedPart.RequiresBranchBox;
        this.emitPumpsUpdate();
    }

    adjustPartQuantity(quantity: number, index: number): void {
        const selectedPart = this.parts.find((p) => p.Id === this.selectedOutdoorHeatPumps[index].PartId);
        if (selectedPart) {
            this.selectedOutdoorHeatPumps[index].Quantity += quantity;
            this.selectedOutdoorHeatPumps[index].MinIDU = selectedPart.MinIduConnections * this.selectedOutdoorHeatPumps[index].Quantity;
            this.selectedOutdoorHeatPumps[index].MaxIDU = selectedPart.MaxIduConnections * this.selectedOutdoorHeatPumps[index].Quantity;
            this.emitPumpsUpdate();
        }
    }

    removeOutdoorHeatPump(index: number): void {
        this.selectedOutdoorHeatPumps.splice(index, 1);
        this.emitPumpsUpdate();
    }

    emitPumpsUpdate(): void {
        this.onSelectedHeatPumpsUpdate.emit({
            RequiresBranchBoxPrompt: this.requiresBranchBox,
            SelectedPumps: this.selectedOutdoorHeatPumps,
        });
    }
}
