import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { LoginComponent, ForgotPasswordComponent, ResetPasswordComponent, AdminAccessComponent } from '@mt-ng2/login-module';

import { appPaths } from './admin-portal/default-routes/app-paths.library';
import { MtCanLoadAuthGuard } from '@mt-ng2/auth-module';
import { PublicProposalPricingPageComponent } from 'admin-portal/public/public-proposal-pricing-page/public-proposal-pricing-page.component';

const customerProposalEntityConfig = {
    proposalUniqueIdParam: 'proposalUniqueIdParam',
};

const appRoutes: Routes = [
    
    { path: appPaths.login, component: LoginComponent , title: 'Login' },
    
    { path: appPaths.forgotPassword, component: ForgotPasswordComponent , title: 'Forgot Password' },
    
    { path: appPaths.resetPassword, component: ResetPasswordComponent , title: 'Reset Password' },
    
    { path: appPaths.adminAccess, component: AdminAccessComponent , title: 'Admin Access' },
    {
        component: PublicProposalPricingPageComponent,
        path: `public/proposal/:${customerProposalEntityConfig.proposalUniqueIdParam}`,
    },
    { canLoad: [MtCanLoadAuthGuard], path: '', loadChildren: () => import('./admin-portal/admin-portal.module').then(m => m.AdminPortalModule) },
];

@NgModule({
    exports: [RouterModule],
    imports: [RouterModule.forRoot(appRoutes, { onSameUrlNavigation: 'reload' })],
})
export class AppRoutingModule { }
