import { Component } from '@angular/core';
import { Manufacturers } from '@model/Manufacturers';
import { MetaItemManagedListComponent } from '@mt-ng2/managed-list-control';
import { ModalService } from '@mt-ng2/modal-module';
import { NotificationsService } from '@mt-ng2/notifications-module';

@Component({
    selector: 'manufacturer-meta-item-managed-list',
    template: `
        <mt-managed-list
            [componentTitle]="cleanTitle"
            [items]="items"
            [sortable]="sortable"
            [sortPropertyName]="sortPropertyName"
            [duplicateCheck]="duplicateCheck"
            [duplicateCheckFields]="duplicateCheckFields"
            [dynamicForm]="dynamicForm"
            [fields]="fields"
            [canAdd]="canAdd"
            [canRemove]="canRemove"
            [canEdit]="canEdit"
            (saved)="saveItems($event)"
        ></mt-managed-list>
    `,

})

export class ManufacturerMetaItemManagedListComponent extends MetaItemManagedListComponent {
    constructor(private _notificationsService: NotificationsService, private _modalService: ModalService) {
        super(_notificationsService);
    }

    /**
     * Override the default method to not include Materials and Mitsubishi (users can't remove these items)
     */
    getItems(): void {
        this.service.getAll().subscribe((answer) => {
            this.items = answer.filter((a) => {
                return a.Id !== Manufacturers.Materials && a.Id !== Manufacturers.Mitsubishi;
            });
        });
    }

    // Override the default method saveItems to notify users about updating Margin
    saveItems(form): void {
        this._modalService
            .showModal({
                text: 'On saving, it will update Margin for all the Customer Proposals that are in progress. Are you sure you want to save the changes?',
                title: 'Confirm Save',
                })
            .subscribe((answer) => {
                if (answer.isConfirmed) {
                    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
                    super.saveItems(form);
                }
            });
    }
}
