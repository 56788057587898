import { ProposalElectricalDetailDynamicControls } from '../form-controls/proposal-electrical-detail.form-controls';
import { Validators } from '@angular/forms';
import { IProposalElectricalDetail } from '../interfaces/proposal-electrical-detail';
import { DynamicField, DynamicFieldType, DynamicFieldTypes, noZeroRequiredValidator, SelectInputTypes } from '@mt-ng2/dynamic-form';
import { IProposalElectricalDetailDynamicControlsParametersPartial } from '../partials/proposal-electrical-detail-dynamic-controls-parameters';

export class ProposalElectricalDetailDynamicControlsPartial extends ProposalElectricalDetailDynamicControls {
    constructor(proposalElectricalDetail: IProposalElectricalDetail, additionalParameters?: IProposalElectricalDetailDynamicControlsParametersPartial) {
        super(proposalElectricalDetail, additionalParameters);

        (this.Form.PanelTypeId as DynamicField).type.inputType = SelectInputTypes.Dropdown;
        (this.Form.ElectricalDistanceFromOduId as DynamicField).type.inputType = SelectInputTypes.Dropdown;
        (this.Form.ElectricalFinishedBasementId as DynamicField).type.inputType = SelectInputTypes.Dropdown;
        (this.Form.ElectricalIndoorLineCoverId as DynamicField).type.inputType = SelectInputTypes.Dropdown;
        (this.Form.ElectricalOutdoorLineCoverId as DynamicField).type.inputType = SelectInputTypes.Dropdown;
        (this.Form.ElectricalJobDifficultyId as DynamicField).type.inputType = SelectInputTypes.Dropdown;
        (this.Form.HouseTypeId as DynamicField).type.inputType = SelectInputTypes.Dropdown;
        (this.Form.McCableLengthft as DynamicField).labelHtml = '<label>MC Cable Length</label>';
        (this.Form.ElectricalOutdoorGfi as DynamicField).labelHtml = '<label>Electrical Outdoor GFI</label>';
        (this.Form.ElectricalPanelUpgrade as DynamicField).disabled = true;
        (this.Form.EvChargingStationNeeded as DynamicField).labelHtml = '<label>EV Charging Station Needed</label>';
    }
}
