<div>
    <div class="row">
        <div class="col-md-4">
            <h2>Customer Proposal Entry Form</h2>
        </div>
        <div class="col-md-3" *ngIf="isAwaitingSalesOrder()">
            <button class="btn btn-danger" (click)="retryCreateStrivenSalesOrder()">Retry Sales Order Create</button>
        </div>
    </div>
</div>
<div *ngIf="formCreated">
    <form [formGroup]="customerProposalForm" (ngSubmit)="formSubmitted()">
        <div class="row">
            <div class="col-md-8">
                <div class="miles-form padded">
                    <h4>Customer Proposal</h4>
                    <mt-dynamic-field [field]="abstractCustomerProposalControls.Name" [form]="customerProposalForm"></mt-dynamic-field>
                    <mt-dynamic-field [field]="abstractCustomerProposalControls.DateOfProposal" [form]="customerProposalForm"></mt-dynamic-field>
                    <h4>Customer Information</h4>
                    <mt-dynamic-field [field]="abstractCustomerProposalControls.CustomerName" [form]="customerProposalForm"></mt-dynamic-field>
                    <mt-dynamic-field [field]="abstractCustomerProposalControls.CustomerLocationId" [form]="customerProposalForm"></mt-dynamic-field>
                    <mt-dynamic-field [field]="abstractCustomerProposalControls.JobLocationId" [form]="customerProposalForm"></mt-dynamic-field>
                    <mt-dynamic-field [field]="abstractCustomerProposalControls.PrimaryPhone" [form]="customerProposalForm"></mt-dynamic-field>
                    <mt-dynamic-field [field]="abstractCustomerProposalControls.AltPhone" [form]="customerProposalForm"></mt-dynamic-field>
                    <mt-dynamic-field
                        [field]="abstractCustomerProposalControls.EmailAddress"
                        (valueChanges)="onEmailAddressChange($event)"
                        [form]="customerProposalForm"
                    ></mt-dynamic-field>
                </div>
            </div>
            <div class="col-md-4">
                <div class="miles-form padded">
                    <h4>Consultant Information</h4>
                    <mt-dynamic-field [field]="abstractCustomerProposalControls.OfficeId" [form]="customerProposalForm"></mt-dynamic-field>
                    <mt-dynamic-field [field]="abstractCustomerProposalControls.SalesPersonId" [form]="customerProposalForm"></mt-dynamic-field>
                </div>
                <br />
                <mt-dynamic-field
                    hidden
                    [field]="abstractCustomerProposalControls.CustomerJobDescription"
                    [form]="customerProposalForm"
                ></mt-dynamic-field>
                <div>
                    <button type="submit" mtDisableClickDuringHttpCalls [disabled]="proposalIsApproved" Class="btn btn-flat btn-primary">Save</button>
                    <button type="button" Class="btn btn-flat btn-default" (click)="cancelClick()">Cancel</button>
                </div>
            </div>
        </div>
        <br />
    </form>
</div>
