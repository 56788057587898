import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { BaseService } from '@mt-ng2/base-service';
import { IProposalIndoorHeatPump } from '@model/interfaces/proposal-indoor-heat-pump';
import { catchError, Observable, throwError } from 'rxjs';
import { IPart } from '@model/interfaces/part';
import { IProposalIndoorHeatPumpLineHide } from '@model/interfaces/proposal-indoor-heat-pump-line-hide';

@Injectable({ providedIn: 'root' })
export class ProposalIndoorHeatPumpService extends BaseService<IProposalIndoorHeatPump> {

    constructor(public http: HttpClient) {
        super('/proposal-indoor-heat-pumps', http);
    }

    updateProposalIndoorHeatPumps(proposalId: number, pumps: IProposalIndoorHeatPump[], lineHides: IProposalIndoorHeatPumpLineHide[]): Observable<void> {
        return this.http.put<void>(`/proposal-indoor-heat-pumps/proposal/${proposalId}/update`, {
            IndoorHeatPumps: pumps,
            LineHides: lineHides,
        }).pipe(
            catchError((error: HttpErrorResponse) => {
              let errorMessage = 'An error occurred while updating the proposal.';
              if (error.status === 400 && error.error?.message) {
                errorMessage = error.error.message;
              }
              return throwError(errorMessage);
            })
          );
    }

    getByProposalId(proposalId: number): Observable<IProposalIndoorHeatPump[]> {
        return this.http.get<IProposalIndoorHeatPump[]>(`/proposal-indoor-heat-pumps/proposal/${proposalId}`);
    }

    uploadHeatPumpImage(imageBase64: string): Observable<string> {
        const byteString = atob(imageBase64.split(',')[1]);
        const ab = new ArrayBuffer(byteString.length);
        const ia = new Uint8Array(ab);
        for (let i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }
        const bb = new Blob([ab]);
        const formData: FormData = new FormData();
        formData.append('file', bb);
        return this.http.post<string>(`/proposal-indoor-heat-pumps/image`, formData);
    }

    buildLineSetPortLabel(selectedPart: IPart): string {
        const labels = [];
        if (selectedPart.LineSetPortSizeOneFourth) {
            labels.push('1/4');
        }
        if (selectedPart.LineSetPortSizeOneHalf) {
            labels.push('1/2');
        }
        if (selectedPart.LineSetPortSizeThreeEights) {
            labels.push('3/8');
        }
        if (selectedPart.LineSetPortSizeFiveEigths) {
            labels.push('5/8');
        }
        if (selectedPart.LineSetPortSizeThreeFourths) {
            labels.push('3/4');
        }
        return labels.length > 0 ? labels.join(' X ') : '';
    }
}
