import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { BaseService } from '@mt-ng2/base-service';

import { ICustomerLocation } from '@model/interfaces/customer-location';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class CustomerLocationService extends BaseService<ICustomerLocation> {
    constructor(public http: HttpClient) {
        super('/customer-locations', http);
    }

    GetActiveLocationsByCustomerId(customerId: number): Observable<ICustomerLocation[]> {
        return this.http.get<ICustomerLocation[]>(`/customer-locations/customer/${customerId}`);
    }

    CheckAddressContainsDynamicPermitZip(customerLocationId: number): Observable<boolean> {
        return this.http.get<boolean>(`/customer-locations/check-address-contains-dynamic-permit-zip/${customerLocationId}`);
    }
}
