<div class="row">
    <div class="col-md-7">
        <div class="miles-card padded">
            <h4>Proposal Status</h4>
            <div class="row">
                <div class="col-md-3">
                    <div class="nav-option">
                        <i *ngIf="!isCompletedFormStep(ProposalFormSteps.JobDetails)" class="fa fa-2x fa-times-circle"></i>
                        <i *ngIf="isCompletedFormStep(ProposalFormSteps.JobDetails)" class="fa fa-2x fa-check-circle"></i>
                        <button class="btn btn-default" (click)="updateCurrentFormStep(ProposalFormSteps.JobDetails)">Job Details</button>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="nav-option">
                        <i
                            *ngIf="!isCompletedFormStep(ProposalFormSteps.BtuCalculations) && btuCalculationIsRequired && !proposalIsComplete()"
                            class="fa fa-2x fa-times-circle"
                        ></i>
                        <i *ngIf="isCompletedFormStep(ProposalFormSteps.BtuCalculations)" class="fa fa-2x fa-check-circle"></i>
                        <button class="btn btn-default" (click)="updateCurrentFormStep(ProposalFormSteps.BtuCalculations)">BTU Calculations</button>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="nav-option">
                        <i *ngIf="!isCompletedFormStep(ProposalFormSteps.HeatPumps)" class="fa fa-2x fa-times-circle"></i>
                        <i *ngIf="isCompletedFormStep(ProposalFormSteps.HeatPumps)" class="fa fa-2x fa-check-circle"></i>
                        <button class="btn btn-default" (click)="checkIfUserRequiresBtuCalculation()">Heat Pumps</button>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="nav-option">
                        <i *ngIf="!isCompletedFormStep(ProposalFormSteps.ElectricalInformation)" class="fa fa-2x fa-times-circle"></i>
                        <i *ngIf="isCompletedFormStep(ProposalFormSteps.ElectricalInformation)" class="fa fa-2x fa-check-circle"></i>
                        <button class="btn btn-default" (click)="updateCurrentFormStep(ProposalFormSteps.ElectricalInformation)">
                            Electrical Information
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="col-md-5">
        <button *ngIf="canEdit" class="btn btn-default btn-finalize" [disabled]="!proposalIsComplete()" (click)="finalizeProposalDetails()">
            Finalize Details
        </button>
        <button class="btn btn-default btn-finalize" [disabled]="!proposalHasPricings" (click)="navigateToPricingPage()">View Proposal</button>
        <button *ngIf="canEdit" class="btn btn-default btn-finalize" (click)="copyCustomerProposal()">Copy Proposal</button>
    </div>
</div>
