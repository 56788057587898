import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, ValidationErrors } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { forkJoin, Subscription } from 'rxjs';
import { markAllFormFieldsAsTouched } from '@mt-ng2/common-functions';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { ElectricalPanelTypeService } from '../services/electrical-panel-type.service';
import { ElectricalDistanceFromOduService } from '../services/electrical-distance-from-odu.service';
import { HouseTypeService } from '../services/house-type.service';
import { ElectricalFinishedBasementService } from '../services/electrical-finished-basement.service';
import { ElectricalIndoorLineCoverService } from '../services/electrical-indoor-line-cover.service';
import { ElectricalOutdoorLineCoverService } from '../services/electrical-outdoor-line-cover.service';
import { ElectricalJobDifficultyService } from '../services/electrical-job-difficulty.service';
import { ProposalElectricalDetailDynamicControlsPartial } from '@model/partials/proposal-electrical-detail.form-controls';
import { ElectricalJobDifficulties } from '@model/ElectricalJobDifficulties';
import { IProposalElectricalDetail } from '@model/interfaces/proposal-electrical-detail';
import { ProposalFormSteps } from '@model/ProposalFormSteps';
import { CustomerProposalInterfaceStateService } from '../services/customer-proposal-interface-state.service';
import { ICustomerProposal } from '@model/interfaces/customer-proposal';
import { ProposalElectricalDetailService } from '../services/proposal-electrical-detail.service';
import { JobTypes } from '@model/JobTypes';
import { CustomerProposalService } from '../services/customer-proposal.service';
import { IExpandableObject } from '@model/expandable-object';

@Component({
    selector: 'proposal-electrical-details-form',
    styles: [`
        .easy-difficulty {
            border: 5px solid green;
            padding: 5px;
        }
        .moderate-difficulty {
            border: 5px solid orange;
            padding: 5px;
        }
        .hard-difficulty {
            border: 5px solid red;
            padding: 5px;
        }
        #electrical-panel-upgrade__checkbox--wrapper {
            color: #bfbfbf;
        }
    `],
    templateUrl: './proposal-electrical-details-form.component.html',
})
export class ProposalElectricalDetailsFormComponent implements OnInit, OnDestroy {

    // abstract controls
    abstractProposalElectricalDetailControls: IExpandableObject;

    proposalElectricalDetailsForm: UntypedFormGroup;
    doubleClickIsDisabled = false;
    formCreated = false;
    jobDifficultyId = 0;
    customerProposal: ICustomerProposal;
    subscriptions: Subscription = new Subscription();
    electricalDetail: IProposalElectricalDetail;
    ProposalFormSteps = ProposalFormSteps;
    displayDisabledMCCableWarning: boolean;
    proposalIsApproved = false;
    showDistanceToPanelField = false;

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private fb: UntypedFormBuilder,
        private cdr: ChangeDetectorRef,
        private notificationsService: NotificationsService,
        private electricalPanelTypeService: ElectricalPanelTypeService,
        private electricalDistanceFromOduService: ElectricalDistanceFromOduService,
        private electricalFinishedBasementService: ElectricalFinishedBasementService,
        private electricalIndoorLineCoverService: ElectricalIndoorLineCoverService,
        private electricalOutdoorLineCoverService: ElectricalOutdoorLineCoverService,
        private electricalJobDifficultyService: ElectricalJobDifficultyService,
        private houseTypeService: HouseTypeService,
        private stateService: CustomerProposalInterfaceStateService,
        private proposalElectricalDetailService: ProposalElectricalDetailService,
        private customerProposalService: CustomerProposalService,
    ) { }

    ngOnInit(): void {
        forkJoin([
            this.electricalPanelTypeService.getItems(),
            this.electricalDistanceFromOduService.getItems(),
            this.electricalFinishedBasementService.getItems(),
            this.electricalIndoorLineCoverService.getItems(),
            this.electricalOutdoorLineCoverService.getItems(),
            this.electricalJobDifficultyService.getItems(),
            this.houseTypeService.getItems(),
        ]).subscribe(() => {
            this.createForm();
            this.initializeCustomerProposalSubscription();
            this.electricalDetail = this.electricalDetail ?? this.proposalElectricalDetailsForm.value.ProposalElectricalDetail;
            this.showDistanceToPanelField = this.electricalDetail.EvChargingStationNeeded;
            this.proposalElectricalDetailsForm.valueChanges.subscribe((value) => {
                const formIsDirty = this.checkIfFormIsDirty(this.electricalDetail, value.ProposalElectricalDetail as IProposalElectricalDetail);
                this.customerProposalService.setFormIsDirty(formIsDirty);
                this.showDistanceToPanelField = (value.ProposalElectricalDetail as IProposalElectricalDetail).EvChargingStationNeeded;
            });
        });
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    initializeCustomerProposalSubscription(): void {
        this.subscriptions.add(
            this.stateService.customerProposal$.subscribe((value) => {
                this.customerProposal = value;
                // Lock a Proposal after approval
                if (!this.customerProposalService.proposalIsInProgress(this.customerProposal)) {
                    this.proposalElectricalDetailsForm.disable();
                    this.proposalIsApproved = true;
                }
            }),
        );
    }

    createForm(): void {
        this.getControls();
        this.proposalElectricalDetailsForm = this.assignFormGroups();
        this.formCreated = true;
        this.cdr.detectChanges();
        // Only Commercial Jobs can edit the MC Cable Length field
        if (this.customerProposal.ProposalJobDetails && this.customerProposal.ProposalJobDetails.length) {
            this.jobTypeChanged(this.customerProposal.ProposalJobDetails[0].JobTypeId);
        }
    }

    getControls(): void {
        this.customerProposal = this.stateService.customerProposal$.value;
        this.electricalDetail = this.customerProposal && this.customerProposal.ProposalElectricalDetails && this.customerProposal.ProposalElectricalDetails.length ? this.customerProposal.ProposalElectricalDetails[0] : null;
        this.abstractProposalElectricalDetailControls = new ProposalElectricalDetailDynamicControlsPartial(
            this.electricalDetail,
            {
                electricalDistanceFromOdus: this.electricalDistanceFromOduService.items,
                electricalFinishedBasements: this.electricalFinishedBasementService.items,
                electricalIndoorLineCovers: this.electricalIndoorLineCoverService.items,
                electricalJobDifficulties: this.electricalJobDifficultyService.items,
                electricalOutdoorLineCovers: this.electricalOutdoorLineCoverService.items,
                formGroup: 'ProposalElectricalDetail',
                houseTypes: this.houseTypeService.items,
                panelTypes: this.electricalPanelTypeService.items,
                proposals: null,
            },
        ).Form;
    }

    assignFormGroups(): UntypedFormGroup {
        return this.fb.group({
            ProposalElectricalDetail: this.fb.group({}),
        });
    }

    jobTypeChanged(event: number): void {
        if (event === JobTypes.Residential) {
            this.displayDisabledMCCableWarning = true;
            (<UntypedFormGroup>this.proposalElectricalDetailsForm.controls.ProposalElectricalDetail).controls.McCableLengthft.setValue(null);
            (<UntypedFormGroup>this.proposalElectricalDetailsForm.controls.ProposalElectricalDetail).controls.McCableLengthft.disable();
        } else {
            this.displayDisabledMCCableWarning = false;
            (<UntypedFormGroup>this.proposalElectricalDetailsForm.controls.ProposalElectricalDetail).controls.McCableLengthft.enable();
        }
    }
    
    evNonZeroValidation = (control: UntypedFormControl): ValidationErrors | null => {
        if (!control.value) {
            return {
                customRequiredValidator: `Distance To Panel is required and should be greater than 0`,
            };
        }
        return null;
    }

    formSubmitted(): void {
        const isEvChargingStationNeeded = this.proposalElectricalDetailsForm.get('ProposalElectricalDetail.EvChargingStationNeeded').value;
        
        if (isEvChargingStationNeeded) {
            const distanceToPanelControl = this.proposalElectricalDetailsForm.get('ProposalElectricalDetail.DistanceToPanel');
            distanceToPanelControl.addValidators([this.evNonZeroValidation]);
            distanceToPanelControl.updateValueAndValidity();
            
        } 

        if (this.proposalElectricalDetailsForm.valid) {
            const saveElectricalDetail: IProposalElectricalDetail = { ...this.electricalDetail };
            this.assignFormValues(saveElectricalDetail, this.proposalElectricalDetailsForm.value.ProposalElectricalDetail as IProposalElectricalDetail);
            saveElectricalDetail.ProposalId = this.customerProposal.Id;
            this.saveElectricalDetails(saveElectricalDetail);
        } else {
            markAllFormFieldsAsTouched(this.proposalElectricalDetailsForm);
            this.error();
        }
    }

    cancelClick(): void {
        void this.router.navigate(['../'], { relativeTo: this.route });
    }

    error(): void {
        this.notificationsService.error('Save failed.  Please check the form and try again.');
    }

    assignFormValues(electricalDetail: IProposalElectricalDetail, currentFormValue: IProposalElectricalDetail): void {
        for (const key in currentFormValue) {
            if (electricalDetail.hasOwnProperty.call(currentFormValue, key)) {
                electricalDetail[key] = currentFormValue[key];
            }
        }
    }

    saveElectricalDetails(saveElectricalDetail: IProposalElectricalDetail): void {
        if (!this.electricalDetail || !this.electricalDetail.Id) {
            this.proposalElectricalDetailService.create(saveElectricalDetail)                .subscribe((answer) => {
                    saveElectricalDetail.Id = answer;
                    this.stateService.markStepCompleted(ProposalFormSteps.ElectricalInformation);
                    this.success();
                    this.customerProposalService.setFormIsDirty(false);
                });
        } else {
            this.proposalElectricalDetailService.update(saveElectricalDetail)                .subscribe(() => {
                    this.success();
                    this.customerProposalService.setFormIsDirty(false);
                });
        }
    }

    success(): void {
        this.customerProposalService.getById(this.customerProposal.Id).subscribe((cp) => {
            this.stateService.customerProposal$.next(cp);
        });
        this.notificationsService.success('Electrical Details saved successfully.');
        this.updateCurrentFormStep(ProposalFormSteps.BasicInfo);
    }

    updateCurrentFormStep(step: ProposalFormSteps): void {
        this.stateService.currentFormStep$.next(step);
    }

    getJobDifficultyStyling(): string {
        switch (this.jobDifficultyId) {
            case ElectricalJobDifficulties.Easy:
                return 'easy-difficulty';
            case ElectricalJobDifficulties.Moderate:
                return 'moderate-difficulty';
            case ElectricalJobDifficulties.Hard:
                return 'hard-difficulty';
            default:
                return '';
        }
    }

    private checkIfFormIsDirty(proposedElectricalDetails: IProposalElectricalDetail, currentElectricDetails: IProposalElectricalDetail): boolean {
        for (const key in currentElectricDetails) {
            if (proposedElectricalDetails.hasOwnProperty.call(currentElectricDetails, key)) {
                const proposedElectricalDetail = proposedElectricalDetails[key];
                const currentElectricDetail = currentElectricDetails[key];
                if (proposedElectricalDetail !== currentElectricDetail) {
                    return true;
                }
            }
        }
        return false;
    }
}
