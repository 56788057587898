import { Injectable } from '@angular/core';

@Injectable()
export class FileService {

    public static save(data: string, outputFileName: string, mimeType = 'application/txt', dataIsBase64 = false): void {
        if (!mimeType) {
            mimeType = 'application/txt';
        }

        let outputData: Uint8Array = null;
        if (dataIsBase64) {
            // convert the base64 string to a byte array
            const byteCharacters = atob(data);
            const byteNumbers = new Array(byteCharacters.length);
            for (let i = 0; i < byteCharacters.length; i++) {
                byteNumbers[i] = byteCharacters.charCodeAt(i);
            }
            outputData = new Uint8Array(byteNumbers);
        }

        // using a blob of the byte array, force the download by creating a
        // dummy link and simulating a click on it
        const blob = (outputData !== null)
            ? new Blob([outputData], { type: mimeType })
            : new Blob([data], { type: mimeType });

        const downloadLink = document.createElement('a');
        downloadLink.style.display = 'none';
        downloadLink.href = (window.URL || (window).webkitURL).createObjectURL(blob);
        downloadLink.download = outputFileName;

        document.body.appendChild(downloadLink); // fix for Firefox

        downloadLink.click();

        setTimeout(() => { // fix for Firefox
            document.body.appendChild(downloadLink);
        }, 100);

    }

}
