import { ManufacturerDynamicControls, IManufacturerDynamicControlsParameters } from '../form-controls/manufacturer.form-controls';
import { DynamicField, DynamicFieldType, DynamicFieldTypes, DynamicLabel, InputTypes, NumericInputTypes } from '@mt-ng2/dynamic-form';
import { IManufacturer } from '../interfaces/manufacturer';
import { Validators } from '@angular/forms';
import { NumberFormatDisplayFunction, NumericControlTypes } from '@mt-ng2/numeric-control';
import { formatNumber } from '@angular/common';

export function allowZeroConversionFunction(value: number, type: NumericControlTypes, scale: number): number {
    return value ? value / 100 : 0;
}
export class ManufacturerDynamicControlsPartial extends ManufacturerDynamicControls {
    constructor(manufacturer?: IManufacturer, additionalParamters?: IManufacturerDynamicControlsParameters) {
        super(manufacturer, additionalParamters);

        this.Form = {
            MarkupPercentage: new DynamicField({
                formGroup: this.formGroup,
                label: 'Markup Percentage',
                name: 'MarkupPercentage',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: NumericInputTypes.Percentage,
                    numericFunctions: {
                        convertFromDisplayToForm: allowZeroConversionFunction,
                    },
                    scale: 2,
                }),
                validation: [Validators.required],
                validators: { 'required': true },
                value: manufacturer && manufacturer.MarkupPercentage || null,
            }),
            Name: new DynamicField({
                formGroup: this.formGroup,
                label: 'Name',
                name: 'Name',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [Validators.maxLength(100)],
                validators: { 'maxlength': 100 },
                value: manufacturer && manufacturer.hasOwnProperty('Name') && manufacturer.Name !== null ? manufacturer.Name.toString() : '',
            }),
        };

        this.View = {
            MarkupPercentage: new DynamicLabel({
			    label: 'Markup Percentage',
			    value: manufacturer && manufacturer.MarkupPercentage || null,
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Numeric,
			        inputType: null,
			        scale: 4,
			    })
			}
            ),
            Name: new DynamicLabel({
			    label: 'Name',
			    value: manufacturer && manufacturer.hasOwnProperty('Name') && manufacturer.Name !== null ? manufacturer.Name.toString() : '',
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Input,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
        };

    }
}
