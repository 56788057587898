<div>
    <div class="row">
        <div class="col-md-7">
            <h2>Heat Pumps</h2>
        </div>
    </div>
</div>

<div class="miles-card padded" [ngClass]="getConfigurationStatus()">
    <div class="row">
        <div class="col-md-3">
            <label>Manufacturer</label>
            <select
                *ngIf="manufacturers.length > 0"
                class="form-control"
                id="manufacturer"
                [(ngModel)]="selectedManufacturerId"
                (ngModelChange)="onManufacturerUpdate()"
                [ngModelOptions]="{ standalone: true }"
                [disabled]="proposalIsApproved"
            >
                <option *ngFor="let m of manufacturers" [value]="m.Id">{{ m.Name }}</option>
            </select>
        </div>
        <div class="col-md-3">
            <label>Category</label>
            <select 
                class="form-control"    
                [(ngModel)]="categoryId"
                (ngModelChange)="onCategoryUpdate()" 
                [ngModelOptions]="{ standalone: true }"
                [disabled]="proposalIsApproved"
            >
                <option [value]="0">All</option>
                <option *ngFor="let category of categories" [value]="category.Id">{{ category.Name }}</option>
            </select>

        </div>
    </div>
    <br />
    <div class="row">
        <div class="col-md-8">
            <outdoor-heat-pumps [proposalIsApproved]="proposalIsApproved" [proposalId]="customerProposalId"></outdoor-heat-pumps>
        </div>
    </div>
    <br />
    <div class="row">
        <div class="col-md-12">
            <indoor-heat-pumps [proposalIsApproved]="proposalIsApproved" [proposalId]="customerProposalId"></indoor-heat-pumps>
        </div>
    </div>
    <br />
    <div class="row">
        <div class="col-md-8">
            <accessories [proposalIsApproved]="proposalIsApproved"></accessories>
        </div>
    </div>
</div>
<br />
<div class="row">
    <div class="col-md-3 col-md-offset-9">
        <div>
            <button class="btn btn-flat btn-primary" [disabled]="proposalIsApproved" (click)="saveHeatPumps()">Save</button>
            <button class="btn btn-flat btn-default" (click)="updateCurrentFormStep(ProposalFormSteps.BasicInfo)">Cancel</button>
        </div>
    </div>
</div>
