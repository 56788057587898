<div *ngIf="proposalPricing" class="container container-proposal">
    <div class="row" id="header-row">
        <div class="col-md-12 text-center" id="header">
            <img id="logo" src="{{ logoSrc }}" alt="Logo" />
            <b class="montserrat"
                >{{ dto.OfficeAddress.Address1 }}, {{ dto.OfficeAddress.City }} {{ dto.OfficeAddress.StateCode }} {{ dto.OfficeAddress.Zip }} |
                {{ dto.OfficePhone | phone }}</b
            >
            <p class="nunito">
                Prepared By: {{ dto.SalesPerson.FirstName }} {{ dto.SalesPerson.LastName }} | {{ dto.SalesPerson.Email }} |
                {{ dto.SalesPersonPhone | phone }}
            </p>
        </div>
    </div>
    <div class="row">
        <div class="col-md-4 date">
            <span class="montserrat">{{ today | date: 'longDate' }}</span>
        </div>
    </div>
    <div class="row">
        <div class="col-sm-4 col-sm-offset-2 text-left">
            <h1 class="montserrat">ABOUT YOU</h1>
            <div class="nunito">
                <span>{{ dto.CustomerProposal.CustomerName }}, {{ dto.JobLocationAddress.Address1 }}</span
                ><br />
                <span>{{ dto.JobLocationAddress.City }}, {{ dto.JobLocationAddress.StateCode }} {{ dto.JobLocationAddress.Zip }}</span
                ><br />
                <ng-container *ngIf="mailingAddressIsUnique()">
                    <span>{{ dto.CustomerLocationAddress.Address1 }}</span
                    ><br />
                    <span
                        >{{ dto.CustomerLocationAddress.City }},
                        {{ dto.CustomerLocationAddress.StateCode }}
                        {{ dto.CustomerLocationAddress.Zip }}</span
                    ><br />
                </ng-container>
                <span>{{ dto.CustomerProposal.PrimaryPhone | phone }}</span
                ><br />
                <span>{{ dto.CustomerProposal.EmailAddress }}</span
                ><br />
            </div>
        </div>
        <div class="col-sm-4 col-sm-offset-2 text-left">
            <h1 class="montserrat">PRICING</h1>
            <ul class="nunito">
                <li>{{ proposalPricing.OrderTotal | currency }} in total</li>
                <li>50% ({{ getDepositAmount(proposalPricing) | currency }}) Deposit Required</li>
                <li>Quote valid 30 days</li>
            </ul>
        </div>
    </div>
    <div class="row">
        <div class="col-md-12 col-about-your-project">
            <h1 class="montserrat">ABOUT YOUR PROJECT</h1>
            <p>{{ dto.CustomerProposal.CustomerJobDescription }}</p>
        </div>
        <div class="heat-pumps">
            <div *ngFor="let pump of dto.OutdoorHeatPumps" class="col-heat-pump">
                <div class="text-left roboto heat-pump-info">
                    <div>Outdoor Unit - {{ pump.Name }}</div>
                    <span class="pump-info">BTUs {{ pump.BtuCapacity }}</span
                    ><span class="pump-info">Qty: {{ pump.Quantity }}</span>
                    <a *ngIf="pump.SpecDocumentUrl" class="spec-sheet-link" href="{{ pump.SpecDocumentUrl }}" target="_blank">Spec Sheet</a>
                </div>
                <img [src]="getHeatPumpImage(pump)" />
            </div>
            <div class="col-heat-pump" *ngFor="let pump of dto.IndoorHeatPumps">
                <div class="text-left roboto heat-pump-info">
                    <div>Indoor Unit - {{ pump.Name }}</div>
                    <span class="pump-info" *ngIf="pump.Room">{{ pump.Room }}</span
                    ><span class="pump-info">BTUs {{ pump.BtuCapacity }}</span
                    ><span class="pump-info">Qty: {{ pump.Quantity }}</span>
                    <a *ngIf="pump.SpecDocumentUrl" class="spec-sheet-link" href="{{ pump.SpecDocumentUrl }}" target="_blank">Spec Sheet</a>
                </div>
                <img [src]="getHeatPumpImage(pump)" />
            </div>
        </div>
    </div>
    <br />
    <div class="row">
        <div class="col-sm-6 col-sm-offset-2 text-left">
            <span>List of Accessories</span>
            <ul id="accessory-list">
                <li *ngFor="let accessory of dto.Accessories">
                    <a (click)="openAccessorySpecSheet(accessory.SpecDocumentUrl)">{{ accessory.Name }}</a>
                </li>
            </ul>
        </div>
    </div>
    <div class="row">
        <div class="col-sm-4 col-sm-offset-2 financing-container">
            <div class="financial-info roboto">
                <span class="financial-info-header">Warranty</span>
                <ng-container>
                    <p>
                        <span *ngIf="proposalIsCommercial">
                            We recommend a 12-year Commercial Warranty with biennial cleaning (every 2 years) included for the
                            {{ dto.HeatPumpsCount }} coils quoted in this system.
                        </span>

                        <span *ngIf="!proposalIsCommercial">
                            We recommend a 12-year Residential Warranty with biennial cleaning (every 2 years) included for the
                            {{ dto.HeatPumpsCount }} coils quoted in this system.
                        </span>

                        <span *ngIf="dto.CustomerProposal.IncludeWarranty">
                            This has added an additional {{ proposalPricing.WarrantyTotal | currency }} to your order.
                        </span>

                        <span *ngIf="!dto.CustomerProposal.IncludeWarranty">
                            This would add an additional {{ proposalPricing.WarrantyTotal | currency }} to your order. If you are interested just tap
                            the checkbox below.
                        </span>
                    </p>
                    <div>
                        <label>Include Warranty in my Order</label>
                        <input
                            type="checkbox"
                            [disabled]="proposalIsLocked"
                            class="financing-checkbox"
                            [(ngModel)]="dto.CustomerProposal.IncludeWarranty"
                            (ngModelChange)="displayAccessCodeAndUpdatePricingWithWarranty()"
                        />
                    </div>
                </ng-container>
            </div>
        </div>
        <div class="col-sm-4 col-sm-offset-2 financing-container">
            <div class="financial-info roboto">
                <span class="financial-info-header">Financing</span>
                <span>Total Price Before Financing</span><br />
                <span>{{ proposalPricing.OrderTotal | currency }}</span
                ><br />
                <ng-container *ngIf="dto.OfficeFinanceUrl">
                    <a target="_blank" [href]="dto.OfficeFinanceUrl">(Click Here to Apply)</a><br />
                </ng-container>
                <span>Estimated Monthly Cost w/ Financing</span><br />
                <span>As Low As {{ getMonthlyLoanPayment() | currency }}/month @ {{ proposalLoan.YearsOfLoan }} Years</span>
                <ng-container>
                    <label>I plan to use the financing option for this order</label>
                    <input
                        type="checkbox"
                        [disabled]="proposalIsLocked"
                        class="financing-checkbox"
                        [(ngModel)]="dto.CustomerProposal.UsesFinancing"
                        (ngModelChange)="updateCustomerProposal()"
                    />
                </ng-container>
            </div>
        </div>
    </div>

    <br />
    <div class="row" *ngIf="proposalIsSigned">
        <div class="col-xs-1 signature-container">
            <div id="signature-x-container">
                <img id="signature-img" src="{{ signatureXSrc }}" />
            </div>
        </div>
        <div class="col-xs-10 text-center signature-container">
            <div class="signature-image">
                <img [src]="getProposalSignatureImage()" />
                <div class="signature-line">
                    <hr />
                </div>
            </div>
        </div>
        <div class="col-xs-6 signature-container text-right">
            <div class="signature-date">
                <i>Signed: {{ getProposalSignatureDate() | date }}</i>
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-sm-8 col-sm-offset-2 text-center">
            <div class="action-buttons">
                <button class="btn btn-default" (click)="downloadCustomerProposalPdf()">Download PDF</button>
                <button class="btn btn-default" *ngIf="!proposalIsSigned" (click)="displayAcessCodeModal()">Click Here to Sign!</button>
                <button class="btn btn-default" (click)="emailProposal()">{{ getEmailBtnText() }}</button>
                <p id="disclaimer-text">NOTE! The provided estimate is good for 30 days from the initial Review</p>
            </div>
        </div>
    </div>

    <div class="row">
        <footer>
            <b id="footer-label" class="montserrat">Maine's Most Trusted Heat Pump Provider!</b>
            <img src="{{ awesomeFontSrc }}" id="awesome-font" />
        </footer>
    </div>
</div>

<ng-container *ngIf="customerProposal && dto.BtuCalculations.length > 0">
    <div class="container container-proposal">
        <div class="row">
            <div class="col-xs-10 col-xs-offset-1 text-center">
                <h3 class="bordered-text"><b>Simple Heat Loss Calculator</b></h3>
                <p>
                    This heat loss calculator is designed to roughly determine how many BTUs per hour you and your space need. The chart below shows
                    measurements taken for the rooms you have specified, along with the insulation value you have indicated.
                </p>
                <p>We offer heat pumps that operate at -5, 5, and 17 degrees. Hyper Heat Pumps will operate down to -13 degrees.</p>
                <p>
                    The amount of BTUs a heat pump can generate changes with outside temperature. Example: An MUZFS15NAU1 Mitsubishi Hyper pump puts
                    out 14,400 BTUs at -13 degrees, 16,000 at -5, 19,360 at 5 degrees and 22,730 at 17 degrees.
                </p>
            </div>
        </div>
        <div class="row">
            <div class="col-xs-12 col-sm-10 col-sm-offset-1">
                <table class="table table-bordered table-striped">
                    <thead>
                        <tr>
                            <th>Room</th>
                            <th>Length</th>
                            <th>Width</th>
                            <th>Square Footage</th>
                            <th>Insulation Factor</th>
                            <th>Room Total</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let calculation of dto.BtuCalculations">
                            <td>{{ calculation.Room }}</td>
                            <td class="text-right">{{ calculation.Length }}</td>
                            <td class="text-right">{{ calculation.Width }}</td>
                            <td class="text-right">{{ calculation.SquareFootage }}</td>
                            <td class="text-right">{{ calculation.InsulationFactor }}</td>
                            <td class="text-right">{{ calculation.RoomTotal }}</td>
                        </tr>
                    </tbody>
                    <thead>
                        <tr>
                            <th colspan="4"></th>
                            <th>House Total</th>
                            <th class="text-right" id="house-total">{{ getHouseTotal() }}</th>
                        </tr>
                    </thead>
                </table>
            </div>
        </div>
        <div class="col-xs-10 col-xs-offset-1 text-center">
            {{ dto.HeatLossNotes }}
        </div>
    </div>
</ng-container>

<mt-modal-wrapper
    *ngIf="customerProposal"
    title=""
    [autoShow]="false"
    [options]="signaturePadModalOptions"
    [showActions]="false"
    [allowOutsideClick]="false"
    (ready)="signaturePadModalApi = $event"
>
    <proposal-signature
        [customerProposalId]="customerProposal.Id"
        (onCancelClicked)="closeSignaturePad()"
        (onCustomerProposalSigned)="onCustomerProposalSigned($event)"
    ></proposal-signature>
</mt-modal-wrapper>
<mt-modal-wrapper
    *ngIf="customerProposal"
    title="Emailing Proposal...Please Wait"
    [autoShow]="false"
    [showActions]="false"
    [allowOutsideClick]="false"
    (ready)="loadingModalApi = $event"
>
    <div class="loader-container">
        <div class="loader"></div>
    </div>
</mt-modal-wrapper>
