import { Validators } from '@angular/forms';

import {
    DynamicField,
    DynamicFieldType,
    DynamicFieldTypes,
    DynamicLabel,
    noZeroRequiredValidator,
    InputTypes,
    NumericInputTypes,
    SelectInputTypes,
} from '@mt-ng2/dynamic-form';
import { getMetaItemValue } from '@mt-ng2/common-functions';
import { IMetaItem } from '../interfaces/base';

import { IExpandableObject } from '../expandable-object';
import { IProposalLoan } from '../interfaces/proposal-loan';
import { ICustomerProposal } from '../interfaces/customer-proposal';

export interface IProposalLoanDynamicControlsParameters {
    formGroup?: string;
    proposals?: ICustomerProposal[];
}

export class ProposalLoanDynamicControls {

    formGroup: string;
    proposals?: ICustomerProposal[];

    Form: IExpandableObject;
    View: IExpandableObject;

    constructor(private proposalloan?: IProposalLoan, additionalParameters?: IProposalLoanDynamicControlsParameters) {
        this.formGroup = additionalParameters && additionalParameters.formGroup || 'ProposalLoan';
        this.proposals = additionalParameters && additionalParameters.proposals || undefined;

        this.Form = {
            Deposit: new DynamicField({
                formGroup: this.formGroup,
                label: 'Deposit',
                name: 'Deposit',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: 4,
                }),
                validation: [  ],
                validators: {  },
                value: this.proposalloan && this.proposalloan.Deposit || 0,
            }),
            InterestRate: new DynamicField({
                formGroup: this.formGroup,
                label: 'Interest Rate',
                name: 'InterestRate',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: 4,
                }),
                validation: [  ],
                validators: {  },
                value: this.proposalloan && this.proposalloan.InterestRate || 0.0499,
            }),
            MonthlyPayment: new DynamicField({
                formGroup: this.formGroup,
                label: 'Monthly Payment',
                name: 'MonthlyPayment',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: 4,
                }),
                validation: [ Validators.required ],
                validators: { 'required': true },
                value: this.proposalloan && this.proposalloan.MonthlyPayment || null,
            }),
            OrderTotal: new DynamicField({
                formGroup: this.formGroup,
                label: 'Order Total',
                name: 'OrderTotal',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: 4,
                }),
                validation: [ Validators.required ],
                validators: { 'required': true },
                value: this.proposalloan && this.proposalloan.OrderTotal || null,
            }),
            ProposalId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Proposal',
                name: 'ProposalId',
                options: this.proposals,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [ noZeroRequiredValidator ],
                validators: { 'required': true },
                value: this.proposalloan && this.proposalloan.ProposalId || null,
            }),
            YearsOfLoan: new DynamicField({
                formGroup: this.formGroup,
                label: 'Years Of Loan',
                name: 'YearsOfLoan',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.proposalloan && this.proposalloan.YearsOfLoan || 10,
            }),
        };

        this.View = {
            Deposit: new DynamicLabel({
                label: 'Deposit',
                value: this.proposalloan && this.proposalloan.Deposit || 0,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: 4,
                }),
            }),
            InterestRate: new DynamicLabel({
                label: 'Interest Rate',
                value: this.proposalloan && this.proposalloan.InterestRate || 0.0499,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: 4,
                }),
            }),
            MonthlyPayment: new DynamicLabel({
                label: 'Monthly Payment',
                value: this.proposalloan && this.proposalloan.MonthlyPayment || null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: 4,
                }),
            }),
            OrderTotal: new DynamicLabel({
                label: 'Order Total',
                value: this.proposalloan && this.proposalloan.OrderTotal || null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: 4,
                }),
            }),
            ProposalId: new DynamicLabel({
                label: 'Proposal',
                value: getMetaItemValue(this.proposals as unknown as IMetaItem[], this.proposalloan && this.proposalloan.hasOwnProperty('ProposalId') ? this.proposalloan.ProposalId : null) ?? '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            }),
            YearsOfLoan: new DynamicLabel({
                label: 'Years Of Loan',
                value: this.proposalloan && this.proposalloan.YearsOfLoan || 10,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: null,
                }),
            }),
        };

    }
}
