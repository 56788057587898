import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { StaticMetaItemService } from '@mt-ng2/base-service';
import { IElectricalIndoorLineCover } from '../../../model/interfaces/electrical-indoor-line-cover';

@Injectable({
    providedIn: 'root',
})
export class ElectricalIndoorLineCoverService extends StaticMetaItemService<IElectricalIndoorLineCover> {
    constructor(public http: HttpClient) {
        super('ElectricalIndoorLineCoverService', 'ElectricalIndoorLineCover', 'IndoorLineCoverIds', '/electrical-indoor-line-covers', http);
    }

}
