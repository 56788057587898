import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { StaticMetaItemService } from '@mt-ng2/base-service';
import { IElectricalOutdoorLineCover } from '../../../model/interfaces/electrical-outdoor-line-cover';

@Injectable({
    providedIn: 'root',
})
export class ElectricalOutdoorLineCoverService extends StaticMetaItemService<IElectricalOutdoorLineCover> {
    constructor(public http: HttpClient) {
        super('ElectricalOutdoorLineCoverService', 'ElectricalOutdoorLineCover', 'OutdoorLineCoverIds', '/electrical-outdoor-line-covers', http);
    }

}
