import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { StaticMetaItemService } from '@mt-ng2/base-service';
import { IRoom } from '@model/interfaces/room';

@Injectable({
    providedIn: 'root',
})
export class RoomService extends StaticMetaItemService<IRoom> {
    constructor(public http: HttpClient) {
        super('RoomService', 'Room', 'RoomIds', '/estimator/rooms', http);
    }
}
