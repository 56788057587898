<th>
    <select
        *ngIf="rooms.length > 0"
        class="form-control"
        id="room"
        [(ngModel)]="btuCalculations[index].RoomId"
        (ngModelChange)="onFieldUpdate(index)"
        [ngModelOptions]="{ standalone: true }"
        [disabled]="proposalIsApproved"
    >
        <option *ngFor="let r of rooms" [value]="r.Id">{{ r.Name }}</option>
    </select>
</th>
<th>
    <input
        type="number"
        class="form-control"
        id="length"
        [(ngModel)]="btuCalculations[index].Length"
        (ngModelChange)="onFieldUpdate(index)"
        [ngModelOptions]="{ standalone: true }"
        [disabled]="proposalIsApproved"
    />
</th>
<th>
    <input
        type="number"
        class="form-control"
        id="width"
        [(ngModel)]="btuCalculations[index].Width"
        (ngModelChange)="onFieldUpdate(index)"
        [ngModelOptions]="{ standalone: true }"
        [disabled]="proposalIsApproved"
    />
</th>
<th>
    {{ btuCalculations[index].SquareFootage }}
</th>
<th>
    <select
        class="form-control"
        id="insulation-factor"
        [(ngModel)]="btuCalculations[index].InsulationFactor"
        (ngModelChange)="onFieldUpdate(index)"
        [ngModelOptions]="{ standalone: true }"
        [disabled]="proposalIsApproved"
    >
        <option *ngFor="let i of insulationFactors" [value]="i">{{ i }}</option>
    </select>
</th>
<th>
    {{ btuCalculations[index].RoomTotal }}
</th>
<th>
    <button class="btn btn-default" [disabled]="proposalIsApproved" (click)="removeBtuCalculation(index)">Remove</button>
</th>
