import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { BaseService } from '@mt-ng2/base-service';
import { IProposalJobDetail } from '../../../model/interfaces/proposal-job-detail';

export const emptyProposalJobDetail: IProposalJobDetail = {
    AdditionalCharges: false,
    FoundationTypeId: null,
    Id: 0,
    JobTypeId: null,
    LiftTypeId: null,
    LineHideColorId: null,
    NoOfInstallers: null,
    OduElevationId: null,
    ProposalId: 0,
    ReferralDiscount: false,
    RoughIn: false,
    RoughInBoxes: false,
    SnakeJob: null,
    WallConstructionTypeId: null,
    JobDifficulty: ''
};

@Injectable({
    providedIn: 'root',
})
export class ProposalJobDetailService extends BaseService<IProposalJobDetail> {
    constructor(public http: HttpClient) {
        super('/customer-proposals/job-details', http);
    }

    getEmptyProposalJobDetail(): IProposalJobDetail {
        return { ...emptyProposalJobDetail };
    }
}
