import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { BaseService } from '@mt-ng2/base-service';
import { IUser } from '@model/interfaces/user';
import { ICreateUserPayload } from '@model/interfaces/custom/create-user-payload';
import { IAddress } from '@mt-ng2/dynamic-form';
import { IPhone } from '@mt-ng2/phone-control';

@Injectable({
    providedIn: 'root',
})
export class UserService extends BaseService<IUser> {
    private emptyUser: IUser = {
        AuthUserId: 0,
        Email: null,
        FirstName: null,
        Id: 0,
        LastName: null,
        RequiresBtuCalculation: true,
        StrivenId: 0,
        Version: null,
    };

    constructor(public http: HttpClient) {
        super('/users', http , null, { entityName: 'Users' });
    }

    getEmptyUser(): IUser {
        return { ...this.emptyUser };
    }

    createUser(data: ICreateUserPayload): Observable<number> {
        return this.http.post<number>(`/users/create`, data);
    }

    saveAddress(userId: number, address: IAddress): Observable<number> {
        if (!address.Id) {
            address.Id = 0;
            return this.http.post<number>(`/users/${userId}/address`, address);
        } else {
            return this.http.put<number>(`/users/${userId}/address`, address);
        }
    }

    deleteAddress(userId: number): Observable<object> {
        return this.http.delete(`/users/${userId}/address`, {
            responseType: 'text' as 'json',
        });
    }

    savePhones(userId: number, phones: IPhone): Observable<number> {
        return this.http.put<number>(`/users/${userId}/phones`, phones);
    }

    savePhoto(userId: number, photo: File): Observable<IUser> {
        const formData: FormData = new FormData();
        formData.append('file', photo, photo.name);

        return this.http.post<IUser>(`/users/${userId}/pic`, formData);
    }

    deletePhoto(userId: number): Observable<object> {
        return this.http.delete(`/users/${userId}/pic`);
    }

    getSalesPersonUsers(): Observable<IUser[]> {
        return this.http.get<IUser[]>('/users/sales-person');
    }

    formatTitleText(user: IUser): void {
        this.setTitle(`User: ${user.FirstName} ${user.LastName}`);
    }
}
