import { Component, Input } from '@angular/core';
import { IProposalPricing } from '@model/interfaces/proposal-pricing';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { OverrideComponent } from '../override.component';

@Component({
    selector: 'proposal-pricing-override',
    styles: [`
        span.overriden-value {
            text-decoration: line-through !important;
            margin-right: 5px;
        }
        .btn-default, .btn-danger {
            float: right;
        }
    `],
    templateUrl: 'proposal-pricing-override.component.html',
})

export class ProposalPricingOverrideComponent extends OverrideComponent {
    @Input() proposalIsLocked = false;
    @Input() proposalPricing: IProposalPricing;
    @Input() key: string;
    @Input() overrideKey: string;
    // Prevent the user from entering a value greater than the threshold to avoid out of range issues in SQL
    overrideThreshold = 99999;
    constructor(private notificationsService: NotificationsService) {
        super();
    }

    saveOverride(): void {
        if (this.value > this.overrideThreshold) {
            this.notificationsService.error('Override value must be less than $99,999.00');
            return;
        }
        super.saveOverride(this.overrideKey);
    }

    removeOverride(): void {
        super.removeOverride(this.overrideKey);
    }
}
