import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { StaticMetaItemService } from '@mt-ng2/base-service';
import { IHouseType } from '../../../model/interfaces/house-type';

@Injectable({
    providedIn: 'root',
})
export class HouseTypeService extends StaticMetaItemService<IHouseType> {
    constructor(public http: HttpClient) {
        super('HouseTypeService', 'HouseType', 'TypeIds', '/house-types', http);
    }

}
