import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class LocalStorageService {
    constructor() { }

    /**
     * Sets a value in local storage
     * @param key
     * @param data
     */
    set(key: string, data: string | number | boolean | Date): void {
        localStorage.setItem(key, JSON.stringify(data));
    }

    /**
     * Returns a value from local storage
     * @param key
     * @returns value from local storage (if exists)
     */
    get(key: string): string {
        return JSON.parse(localStorage.getItem(key));
    }

    /**
     * Clears all previously set local storage values
     */
    clear(): void {
        localStorage.clear();
    }
}
