import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ICustomer } from '@model/interfaces/customer';
import { ICustomerProposal } from '@model/interfaces/customer-proposal';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { Subscription } from 'rxjs';
import { CustomerService } from '../../customers/services/customer.service';
import { CustomerProposalInterfaceStateService } from '../services/customer-proposal-interface-state.service';
import { CustomerProposalService } from '../services/customer-proposal.service';
import { ProposalFormSteps } from '@model/ProposalFormSteps';
import { IHeatPumpConfigurationStatus } from '@model/interfaces/custom/heat-pump-configuartion-status';

@Component({
    providers: [CustomerProposalInterfaceStateService],
    selector: 'customer-proposal-interface',
    templateUrl: 'customer-proposal-interface.component.html',
})
export class CustomerProposalInterfaceComponent implements OnInit {
    customer: ICustomer;
    customerProposal: ICustomerProposal;
    subscriptions: Subscription = new Subscription();
    currentFormStep: ProposalFormSteps;
    ProposalFormSteps = ProposalFormSteps;
    completedFormSteps: ProposalFormSteps[];
    isLoaded: boolean;
    heatPumpConfigurationStatus: IHeatPumpConfigurationStatus;

    constructor(
        private route: ActivatedRoute,
        private customerService: CustomerService,
        private notificationsService: NotificationsService,
        private router: Router,
        private customerProposalService: CustomerProposalService,
        private stateService: CustomerProposalInterfaceStateService,
    ) {}

    ngOnInit(): void {
        const customerId = this.getIdFromRoute(this.route, 'customerId');
        const proposalId = this.getIdFromRoute(this.route, 'customerProposalId');
        if (proposalId) {
            this.getCustomerProposalById(proposalId);
        } else if (customerId) {
            this.getCustomerById(customerId);
        }
        this.initializeSubscriptions();
    }

    initializeSubscriptions(): void {
        this.subscriptions.add(
            this.stateService.currentFormStep$.subscribe((value) => {
                this.currentFormStep = value;
            }),
        );
        this.subscriptions.add(
            this.stateService.completedFormSteps$.subscribe((value) => {
                this.completedFormSteps = value;
            }),
        );
        this.subscriptions.add(
            this.stateService.heatPumpConfigurationStatus$.subscribe((value) => {
                this.heatPumpConfigurationStatus = value;
            }),
        );
    }

    isActiveFormStep(step: ProposalFormSteps): boolean {
        return step === this.currentFormStep;
    }

    getIdFromRoute(route: ActivatedRoute, param: string): number {
        const id = route.snapshot.paramMap.get(param);
        const parsedInt = parseInt(id, 10);
        return isNaN(parsedInt) ? null : parsedInt;
    }

    getCustomerById(id: number): void {
        this.customerService.getById(id).subscribe((customer) => {
            if (customer === null) {
                this.notificationsService.error('Customer not found');
                void this.router.navigate(['customers']);
            }
            this.customer = customer;
            this.isLoaded = true;
        });
    }

    getCustomerProposalById(id: number): void {
        this.customerProposalService.getById(id).subscribe((proposal) => {
            if (proposal === null) {
                this.notificationsService.error('Customer Proposal not found');
                void this.router.navigate(['customers']);
            }
            this.customerProposal = proposal;
            this.customer = proposal.Customer;
            this.stateService.customerProposal$.next(this.customerProposal);
            this.completedFormSteps.push(ProposalFormSteps.BasicInfo);
            if (this.customerProposal.ProposalOutdoorHeatPumps.length > 0) {
                this.stateService.markStepCompleted(ProposalFormSteps.HeatPumps);
            }
            if (this.customerProposal && this.customerProposal.ProposalJobDetails.length) {
                this.stateService.markStepCompleted(ProposalFormSteps.JobDetails);
            }
            if (this.customerProposal.ProposalElectricalDetails && this.customerProposal.ProposalElectricalDetails.length) {
                this.stateService.markStepCompleted(ProposalFormSteps.ElectricalInformation);
            }
            if (this.customerProposal.ProposalBtuCalculations && this.customerProposal.ProposalBtuCalculations.length) {
                this.updateBTUThreshold();
                this.stateService.markStepCompleted(ProposalFormSteps.BtuCalculations);
            }
            this.isLoaded = true;
        });
    }

    private updateBTUThreshold(): void {
        this.heatPumpConfigurationStatus.BTUThreshold = this.customerProposal.ProposalBtuCalculations.map((b) => {
            return b.InsulationFactor * b.Width * b.Length;
        }).reduce((total, value) => total + value);
        this.stateService.heatPumpConfigurationStatus$.next(this.heatPumpConfigurationStatus);
    }
}
