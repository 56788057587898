import { AfterViewInit, Component, ElementRef, EventEmitter, Input, Output } from '@angular/core';
import SignaturePad from 'signature_pad';
import { CustomerProposalService } from '../services/customer-proposal.service';
import { ModalService } from '@mt-ng2/modal-module';
import { Subscription } from 'rxjs';

@Component({
    selector: 'proposal-signature',
    styles: [`
        .wrapper {
            position: relative;
            width: 500px;
            height: 120px;
            -moz-user-select: none;
            -webkit-user-select: none;
            -ms-user-select: none;
            user-select: none;
            border: 1px solid black;
            margin-left: -2.4em;
        }
        .signature-pad {
            position: absolute;
            left: 0;
            top: 0;
            width: 500px;
            height: 120px;
            background-color: white;
        }
        .btn {
            min-width: 6.5em;
            margin: 9px;
        }
        .btn-success {
            background: #2FB8E1;
        }
    `],
    templateUrl: 'proposal-signature.component.html',
})

export class ProposalSignatureComponent implements AfterViewInit {
    @Input() customerProposalId: number;
    @Output() onCustomerProposalSigned: EventEmitter<string> = new EventEmitter<string>();
    @Output() onCancelClicked: EventEmitter<void> = new EventEmitter<void>();
    signaturePad: SignaturePad;
    canvas: HTMLCanvasElement;
    modalSubscription: Subscription;

    constructor(
        private el: ElementRef,
        private customerProposalService: CustomerProposalService,
        private modalService: ModalService,
    ) { }

    ngAfterViewInit(): void {
        this.canvas = this.el.nativeElement.querySelector('canvas');
        this.signaturePad = new SignaturePad(this.canvas);
    }

    clearSignature(): void {
        this.signaturePad.clear();
    }

    cancelClick(): void {
        this.clearSignature();
        this.onCancelClicked.emit();
    }

    saveSignature(): void {
        if (this.signaturePad.isEmpty()) {
            alert('Signature empty');
            return;
        }
        this.customerProposalService.saveDigitalSignature(this.customerProposalId, this.signaturePad.toDataURL()).subscribe(() => {
            this.onCustomerProposalSigned.emit(this.signaturePad.toDataURL());
        }, (error) => {
            this.displayError(error);
        });
    }

    private displayError(error): void {
        this.modalSubscription = this.modalService.showModal({
            text: error.error || 'An error has occurred processing this Proposal.',
            title: 'An error has occurred',
        	icon: 'error',
}).subscribe();
    }

    ngOnDestroy(): void {
        if (this.modalSubscription) {
            this.modalSubscription.unsubscribe();
        }
    }
}
