import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FileService } from '@common/services/file.service';
import { LoanCalculations } from '@common/services/loan-calculations.service';
import { environment } from '@environment';
import { ClaimTypes } from '@model/ClaimTypes';
import { CustomerProposalStatuses } from '@model/CustomerProposalStatuses';
import { ICustomerProposalPricingPageDTO, IHeatPumpDTO } from '@model/interfaces/custom/customer-proposal-pricing-page-dto';
import { ICustomerProposal } from '@model/interfaces/customer-proposal';
import { IProposalLoan } from '@model/interfaces/proposal-loan';
import { IProposalPricing } from '@model/interfaces/proposal-pricing';
import { ClaimsService, ClaimValues } from '@mt-ng2/auth-module';
import { PrintModeService } from '@mt-ng2/entity-list-module';
import { IModalOptions, IModalWrapperApi, ModalService } from '@mt-ng2/modal-module';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { switchMap } from 'rxjs/operators';
import { CustomerProposalService } from '../services/customer-proposal.service';
import { ProposalPricingService } from '../services/proposal-pricing.service';
import { Subscription } from 'rxjs';

@Component({
    encapsulation: ViewEncapsulation.Emulated,
    selector: 'proposal-pricing-page',
    styleUrls: ['./proposal-pricing-page.component.css'],
    templateUrl: 'proposal-pricing-page.component.html',
})
export class ProposalPricingPageComponent implements OnInit, OnDestroy {
    dto: ICustomerProposalPricingPageDTO;
    proposalPricing: IProposalPricing;
    logoSrc = `${environment.assetsPath}logo-awesome.png`;
    awesomeFontSrc = `${environment.assetsPath}awesome.png`;
    customerProposal: ICustomerProposal;
    signaturePadModalApi: IModalWrapperApi;
    loadingModalApi: IModalWrapperApi;
    proposalIsSigned: boolean;
    proposalIsCommercial: boolean;
    signaturePadModalOptions: IModalOptions = {
        customClass: {
            container: 'signature-pad-modal',
        },
    };
    imgPath = `${environment.docPath}heat-pump-images/`;
    today = new Date();
    signatureImgPath = `${environment.docPath}signatures/`;
    signatureXSrc = `${environment.assetsPath}proposal-signature.png`;
    tempProposalSignatureImage: string;
    tempProposalSignatureDate: Date;
    proposalIsLocked = false;
    canEdit: boolean;
    proposalLoan: IProposalLoan;
    loanCalculations = LoanCalculations;
    modalSubscription: Subscription;
    isUpdatingWarranty = false;

    constructor(
        private route: ActivatedRoute,
        private proposalPricingService: ProposalPricingService,
        private router: Router,
        private baseprintModeService: PrintModeService,
        private customerProposalService: CustomerProposalService,
        private modalService: ModalService,
        private notificationsService: NotificationsService,
        private claimsService: ClaimsService,
    ) {}

    ngOnInit(): void {
        this.canEdit = this.claimsService.hasClaim(ClaimTypes.CustomerProposals_CanEditProposal, [ClaimValues.FullAccess]);
        const proposalId = this.getIdFromRoute(this.route, 'customerProposalId');
        if (proposalId) {
            this.getCustomerProposal(proposalId);
        }
        // Wrap in a timeout to avoid fullscreen error
        setTimeout(() => {
            this.openFullScreenMode();
            this.baseprintModeService.togglePrintMode();
        }, 0);
    }

    private getCustomerProposal(proposalId: number): void {
        this.customerProposalService.getCustomerProposalPricingPageDTO(proposalId).subscribe((dto) => {
            this.dto = dto;
            this.proposalPricing = dto.ProposalPricing;
            this.customerProposal = dto.CustomerProposal;
            this.proposalLoan = dto.ProposalLoan;
            if (!this.customerProposalService.proposalIsInProgress(this.customerProposal)) {
                this.proposalIsLocked = true;
            }
            this.proposalIsSigned = dto.IsSigned;
            this.proposalIsCommercial = dto.IsCommercial;
        });
    }

    ngOnDestroy(): void {
        this.closeFullScreenMode();
        this.baseprintModeService.closePrintMode();
        if (this.modalSubscription) {
            this.modalSubscription.unsubscribe();
        }
    }

    getIdFromRoute(route: ActivatedRoute, param: string): number {
        const id = route.snapshot.paramMap.get(param);
        const parsedInt = parseInt(id, 10);
        return isNaN(parsedInt) ? null : parsedInt;
    }

    getHeatPumpImage(pump: IHeatPumpDTO): string {
        return pump && pump.Image ? this.imgPath + pump.Image : 'https://via.placeholder.com/230x140';
    }

    openFullScreenMode(): void {
        const fullscreenElement = document.documentElement;
        if (fullscreenElement.requestFullscreen) {
            void fullscreenElement.requestFullscreen();
        }
    }

    closeFullScreenMode(): void {
        void document.exitFullscreen();
    }

    displaySignaturePad(): void {
        this.signaturePadModalApi.show();
    }

    closeSignaturePad(): void {
        this.signaturePadModalApi.close();
    }

    displayLoader(): void {
        this.loadingModalApi.show();
    }

    closeLoader(): void {
        this.loadingModalApi.close();
    }

    onCustomerProposalSigned(image): void {
        this.closeSignaturePad();
        this.proposalIsSigned = true;
        // Display this info in the template without having to reload after signing
        this.tempProposalSignatureImage = image;
        this.tempProposalSignatureDate = new Date();
        this.completeProposal();
    }

    updateCustomerProposal(): void {
        this.customerProposalService.update(this.customerProposal).subscribe(() => {
            this.notificationsService.success('Successfully updated Customer Proposal');
        });
    }

    updatePricingWithWarranty(): void {
        this.isUpdatingWarranty = true;
        this.customerProposalService
            .update(this.customerProposal)
            .pipe(
                switchMap(() => {
                    return this.proposalPricingService.updatePricingWithWarranty(this.proposalPricing.Id, this.dto.CustomerProposal.IncludeWarranty);
                }),
            )
            .subscribe(() => {
                this.notificationsService.success('Successfully updated Customer Proposal');
                this.proposalPricingService.getById(this.proposalPricing.Id).subscribe((pricing) => {
                    this.proposalPricing = pricing;
                    this.proposalLoan.OrderTotal = pricing.OrderTotal;
                    this.isUpdatingWarranty = false;
                });
            }, () => {
                this.isUpdatingWarranty = false;
            });
    }

    emailProposal(): void {
        this.displayLoader();
        const emailProposalSubscription = this.customerProposalService
            .emailCustomerProposal(this.customerProposal.Id, this.proposalPricing.Id)
            .subscribe(
                () => {
                    this.closeLoader();

                    if (!this.proposalIsSigned) {
                        this.customerProposal.StatusId = CustomerProposalStatuses.Quoted;
                    }
                    this.modalService
                        .showModal({
                            text: 'Thank you for your submission. You should be receiving the proposal in an email shortly.',
                            title: 'Thank you!',
                            icon: 'success',
                        })
                        .subscribe();
                },
                (error) => {
                    // Show error modal
                    this.closeLoader();
                    this.modalService
                        .showModal({
                            text: error.error || 'An error has occurred processing this Proposal.',
                            title: 'An error has occurred',
                            icon: 'error',
                        })
                        .subscribe();
                },
            );

        this.modalSubscription = emailProposalSubscription;
    }

    completeProposal(): void {
        if (this.customerProposal.StatusId === CustomerProposalStatuses.Approved) {
            this.emailProposal();
        } else {
            this.displayLoader();
            this.customerProposalService.completeCustomerProposal(this.customerProposal.Id, this.proposalPricing.Id).subscribe((success) => {
                if (success) {
                    this.closeLoader();
                    this.customerProposal.StatusId = CustomerProposalStatuses.Approved;
                    this.emailProposal();
                } else {
                    this.closeLoader();
                    this.displayError();
                }
            });
        }
    }

    private displayError(): void {
        this.modalService
            .showModal({
                text: 'An error has occurred processing this Proposal.',
                title: 'An error has occurred',
                icon: 'error',
            })
            .subscribe();
    }

    openAccessorySpecSheet(url: string): void {
        window.open(url, '_blank');
    }

    returnToEstimator(): void {
        void this.router.navigate(['../'], { relativeTo: this.route });
    }

    downloadCustomerProposalPdf(): void {
        this.customerProposalService.getCustomerProposalPdf(this.customerProposal.Id).subscribe((answer) => {
            FileService.save(answer, `${this.customerProposal.Name}.pdf`, 'application/pdf', true);
        });
    }

    getEmailBtnText(): string {
        return this.proposalIsSigned ? 'Resend Email' : 'Maybe Later';
    }

    getDepositAmount(): number {
        return this.proposalPricing.CustomerDepositAmountOverride
            ? this.proposalPricing.CustomerDepositAmountOverride
            : this.proposalPricing.CustomerDepositAmount;
    }

    mailingAddressIsUnique(): boolean {
        return this.customerProposal && this.customerProposal.JobLocationId !== this.customerProposal.CustomerLocationId;
    }

    getProposalSignatureImage(): string {
        if (this.tempProposalSignatureImage) {
            return this.tempProposalSignatureImage;
        }
        return this.proposalIsSigned ? this.signatureImgPath + this.customerProposal.ProposalSignatureImage : '';
    }

    getProposalSignatureDate(): Date {
        if (this.tempProposalSignatureDate) {
            return this.tempProposalSignatureDate;
        }
        return this.proposalIsSigned ? this.customerProposal.ProposalSignatureDate : null;
    }

    getMonthlyLoanPayment(): number {
        return this.loanCalculations.getMonthlyPayment(this.proposalLoan);
    }

    getHouseTotal(): number {
        let total = 0;
        this.dto.BtuCalculations.forEach((calculation) => {
            total += calculation.RoomTotal;
        });
        return total;
    }
}
