import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IProposalElectricalDetail } from '@model/interfaces/proposal-electrical-detail';

import { BaseService } from '@mt-ng2/base-service';

@Injectable({
    providedIn: 'root',
})
export class ProposalElectricalDetailService extends BaseService<IProposalElectricalDetail> {
    constructor(public http: HttpClient) {
        super('/customer-proposals/electrical-details', http);
    }
}
