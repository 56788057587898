import { Injectable } from '@angular/core';
import { IHeatPumpConfigurationStatus } from '@model/interfaces/custom/heat-pump-configuartion-status';
import { ICustomerProposal } from '@model/interfaces/customer-proposal';
import { ProposalFormSteps } from '@model/ProposalFormSteps';
import { BehaviorSubject } from 'rxjs';
import { CustomerProposalService } from './customer-proposal.service';

@Injectable()
export class CustomerProposalInterfaceStateService {
    private _customerProposal: BehaviorSubject<ICustomerProposal> = new BehaviorSubject<ICustomerProposal>(this.customerProposalService.getEmptyCustomerProposal());
    public get customerProposal$(): BehaviorSubject<ICustomerProposal> {
        return this._customerProposal;
    }

    private _currentFormStep: BehaviorSubject<ProposalFormSteps> = new BehaviorSubject<ProposalFormSteps>(ProposalFormSteps.BasicInfo);
    public get currentFormStep$(): BehaviorSubject<ProposalFormSteps> {
        return this._currentFormStep;
    }

    private _completedFormSteps: BehaviorSubject<ProposalFormSteps[]> = new BehaviorSubject<ProposalFormSteps[]>([]);
    public get completedFormSteps$(): BehaviorSubject<ProposalFormSteps[]> {
        return this._completedFormSteps;
    }

    private _heatPumpConfigurationStatus: BehaviorSubject<IHeatPumpConfigurationStatus> = new BehaviorSubject<IHeatPumpConfigurationStatus>({
        BranchBoxes: 0,
        BTUThreshold: 0,
        BTUTotal: 0,
        DrainLine: 0,
        IsValid: true,
        LineSet: 0,
        MaxIDU: 0,
        MinIDU: 0,
        Quantity: 0,
        RequiredBranchBoxes: 0,
    });
    public get heatPumpConfigurationStatus$(): BehaviorSubject<IHeatPumpConfigurationStatus> {
        return this._heatPumpConfigurationStatus;
    }

    public markStepCompleted(step: ProposalFormSteps): void {
        let steps = this.completedFormSteps$.value;
        if (!steps) {
            steps = [];
        }
        if (steps.indexOf(step) === -1) {
            steps.push(step);
        }
        this.completedFormSteps$.next(steps);
    }

    constructor(private customerProposalService: CustomerProposalService) { }
}
