// tslint:disable: object-literal-sort-keys

export const estimatorPathSlugs = {
    root: 'estimator',
    settings: 'settings',
};

export const estimatorPaths = {
    estimator: estimatorPathSlugs.root,
    estimatorSettings: `${estimatorPathSlugs.root}/${estimatorPathSlugs.settings}`,
};
