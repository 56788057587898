import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { forkJoin, Subscription } from 'rxjs';
import { markAllFormFieldsAsTouched } from '@mt-ng2/common-functions';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { OfficeService } from '../../offices/services/office.service';
import { ICustomer } from '@model/interfaces/customer';
import { ICustomerProposal } from '@model/interfaces/customer-proposal';
import { CustomerProposalDynamicControlsPartial } from '@model/partials/customer-proposal.form-controls';
import { UserService } from '../../users/user.service';
import { IUser } from '@model/interfaces/user';
import { CustomerProposalService } from '../services/customer-proposal.service';
import { CustomerProposalInterfaceStateService } from '../services/customer-proposal-interface-state.service';
import { CommonService } from '@common/services/common.service';
import { ModalService } from '@mt-ng2/modal-module';
import { ICustomerLocation } from '@model/interfaces/customer-location';
import { CustomerLocationService } from '../../customers/services/customer-location.service';
import { CustomerProposalStatuses } from '@model/CustomerProposalStatuses';
import { AdminSettingService } from '../services/admin-setting.service';
import { AdminSettings } from '@model/AdminSettings';
import { IAdminSetting } from '@model/interfaces/admin-setting';
import { IMetaItem } from '@mt-ng2/base-service';
import { AuthService } from '@mt-ng2/auth-module';
import { IExpandableObject } from '@model/expandable-object';

@Component({
    selector: 'customer-proposal-form',
    styles: [
        `
            .btn-danger {
                margin-top: 10px;
            }
        `,
    ],
    templateUrl: 'customer-proposal-form.component.html',
})
export class CustomerProposalFormComponent implements OnInit, OnDestroy {
    @Input() customer: ICustomer;
    // abstract controls
    abstractCustomerProposalControls: IExpandableObject;

    customerProposalForm: UntypedFormGroup;
    doubleClickIsDisabled = false;
    formCreated = false;
    customers: ICustomer[];
    locationManagers: IMetaItem[];
    subscriptions: Subscription = new Subscription();
    currentFormValue: ICustomerProposal;
    users: IMetaItem[];
    customerProposal: ICustomerProposal;
    states: { Id: string; Name: string }[];
    // Use the Location's Address as the dropdown value
    locationMetaItems: IMetaItem[];
    locations: ICustomerLocation[];
    proposalIsApproved = false;
    boilerplateJobDescription: IAdminSetting;
    currentUser: IUser;

    get isNewCustomerProposal(): boolean {
        return this.customerProposal?.Id ? false : true;
    }

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private fb: UntypedFormBuilder,
        private cdr: ChangeDetectorRef,
        private notificationsService: NotificationsService,
        private officeService: OfficeService,
        private userService: UserService,
        private customerProposalService: CustomerProposalService,
        private stateService: CustomerProposalInterfaceStateService,
        private commonService: CommonService,
        private modalService: ModalService,
        private customerLocationService: CustomerLocationService,
        private adminSettingService: AdminSettingService,
        private authService: AuthService,
    ) {}

    ngOnInit(): void {
        forkJoin([
            this.userService.getById(this.authService.currentUser.getValue().Id),
            this.officeService.getActiveOffices(),
            this.userService.getSalesPersonUsers(),
            this.commonService.getStates(),
            this.customerLocationService.GetActiveLocationsByCustomerId(this.customer.Id),
            this.adminSettingService.getById(AdminSettings.BoilerplateJobDescription),
        ]).subscribe((answers) => {
            const [user, offices, users, states, locations, boilerplateJobDescription] = answers;
            this.currentUser = user;
            this.locationManagers = offices.map((o) => {
                return {
                    Id: o.Id,
                    Name: o.LocationManager,
                };
            });
            this.users = users.map((u) => {
                return {
                    Id: u.Id,
                    Name: `${u.FirstName} ${u.LastName}`,
                };
            });
            this.states = states.map((s) => {
                return {
                    Id: s.StateCode,
                    Name: s.StateCode,
                };
            });
            this.locations = locations;
            this.locationMetaItems = locations.map((l) => {
                return {
                    Id: l.Id,
                    Name: this.getLocationAddressName(l),
                };
            });
            this.boilerplateJobDescription = boilerplateJobDescription;
            this.initializeCustomerProposalSubscription();
            this.customerProposalForm.valueChanges.subscribe((value) => {
                const formIsDirty = this.checkIfFormIsDirty(this.customerProposal, value.CustomerProposal as ICustomerProposal);
                this.customerProposalService.setFormIsDirty(formIsDirty);
            });
        });
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    initializeCustomerProposalSubscription(): void {
        this.subscriptions.add(
            this.stateService.customerProposal$.subscribe((value) => {
                this.customerProposal = value;
                this.createForm();

                // Check if the user is an admin and the proposal is either quoted or awaiting sales order
                const isAdminAndQuotedOrAwaiting = this.customerProposalService.isCurrentUserAdminAndProposalQuotedOrAwaiting(
                    this.currentUser,
                    this.customerProposal,
                );

                // Lock a Proposal after approval only if the user is not an admin and the proposal is not quoted or awaiting sales order
                if (!isAdminAndQuotedOrAwaiting && !this.customerProposalService.proposalIsInProgress(this.customerProposal)) {
                    this.customerProposalForm.disable();
                    this.proposalIsApproved = true;
                }
                this.initializeFormSubscription();
            }),
        );
        setTimeout(() => {
            // If this is a new proposal, default the Date Created to today and the autofill customer's name
            if (this.customerProposal.Id === 0 && this.customerProposalForm) {
                this.autofillProposalFields();
            }
        }, 0);
    }

    private autofillProposalFields(): void {
        (<UntypedFormGroup>this.customerProposalForm.controls.CustomerProposal).controls.DateOfProposal.setValue(new Date(), { emitEvent: false });
        (<UntypedFormGroup>this.customerProposalForm.controls.CustomerProposal).controls.CustomerName.setValue(this.customer.Name, {
            emitEvent: false,
        });
        (<UntypedFormGroup>this.customerProposalForm.controls.CustomerProposal).controls.CustomerJobDescription.setValue(
            this.boilerplateJobDescription.Value,
            { emitEvent: false },
        );
        if (this.currentUser.OfficeId) {
            (<UntypedFormGroup>this.customerProposalForm.controls.CustomerProposal).controls.OfficeId.setValue(this.currentUser.OfficeId, {
                emitEvent: false,
            });
        }
        if (this.users.find((u) => u.Id === this.currentUser.Id)) {
            (<UntypedFormGroup>this.customerProposalForm.controls.CustomerProposal).controls.SalesPersonId.setValue(this.currentUser.Id, {
                emitEvent: false,
            });
        }
        const primaryAddress = this.locations.find((l) => l.IsPrimary);
        if (primaryAddress) {
            (<UntypedFormGroup>this.customerProposalForm.controls.CustomerProposal).controls.CustomerLocationId.setValue(primaryAddress.Id, {
                emitEvent: false,
            });
        }
        if (this.locations.length === 1) {
            (<UntypedFormGroup>this.customerProposalForm.controls.CustomerProposal).controls.JobLocationId.setValue(this.locations[0].Id, {
                emitEvent: false,
            });
        }
        if (this.customer.CustomerContacts.length > 0) {
            const primaryContact = this.customer.CustomerContacts.find((c) => c.IsPrimary);
            if (primaryContact) {
                (<UntypedFormGroup>this.customerProposalForm.controls.CustomerProposal).controls.EmailAddress.setValue(primaryContact.Email, {
                    emitEvent: false,
                });
            }
        }
    }

    initializeFormSubscription(): void {
        this.subscriptions.add(
            this.customerProposalForm.valueChanges.subscribe((value) => {
                if (value) {
                    this.currentFormValue = value.CustomerProposal;
                }
            }),
        );
    }

    createForm(): void {
        this.getControls();
        this.customerProposalForm = this.assignFormGroups();
        this.formCreated = true;
        this.cdr.detectChanges();
    }

    getControls(): void {
        this.abstractCustomerProposalControls = new CustomerProposalDynamicControlsPartial(this.customerProposal, {
            customerLocationAddresses: this.locationMetaItems,
            customers: null,
            formGroup: 'CustomerProposal',
            jobLocationAddresses: this.locationMetaItems,
            locationManagers: this.locationManagers,
            states: this.states,
            users: this.users,
        }).Form;
    }

    assignFormGroups(): UntypedFormGroup {
        return this.fb.group({
            CustomerProposal: this.fb.group({}),
        });
    }

    /**
     * Automatically strip spaces/new line characters from Email inputs
     * @param value
     */
    onEmailAddressChange(value: string): void {
        (<UntypedFormGroup>this.customerProposalForm.controls.CustomerProposal).controls.EmailAddress.setValue(value.trim(), { emitEvent: false });
    }

    formSubmitted(): void {
        if (this.customerProposalForm.valid) {
            this.assignFormValues(this.customerProposal, this.customerProposalForm.value.CustomerProposal as ICustomerProposal);
            this.saveCustomerProposal();
        } else {
            markAllFormFieldsAsTouched(this.customerProposalForm);
            this.error();
        }
    }

    assignFormValues(customerProposal: ICustomerProposal, currentFormValue: ICustomerProposal): void {
        for (const key in currentFormValue) {
            if (customerProposal.hasOwnProperty.call(currentFormValue, key)) {
                customerProposal[key] = currentFormValue[key];
            }
        }
    }

    saveCustomerProposal(): void {
        if (this.isNewCustomerProposal) {
            this.customerProposal.CustomerId = this.customer.Id;
            this.customerProposal.DateCreated = new Date();
            this.customerProposalService.create(this.customerProposal).subscribe((answer) => {
                this.customerProposal.Id = answer;
                this.customerProposalService.setFormIsDirty(false);
                this.success(true);
            });
        } else {
            this.customerProposalService.update(this.customerProposal).subscribe(() => {
                this.customerProposalService.setFormIsDirty(false);
                this.success(false);
            });
        }
    }

    cancelClick(): void {
        void this.router.navigate([`/customers/${this.customer.Id}`]);
    }

    error(): void {
        this.notificationsService.error('Save failed.  Please check the form and try again.');
    }

    success(isNewProposal: boolean): void {
        this.notificationsService.success('Customer Proposal saved successfully.');
        if (isNewProposal) {
            void this.router.navigate([`/estimator/customer/${this.customer.Id}/proposal`, this.customerProposal.Id]);
        } else {
            this.stateService.customerProposal$.next(this.customerProposal);
        }
    }

    private getLocationAddressName(location: ICustomerLocation): string {
        return `${location.Address.Address1} ${location.Address.City}, ${location.Address.StateCode} ${location.Address.Zip}`;
    }

    isAwaitingSalesOrder(): boolean {
        return this.customerProposal && this.customerProposal.StatusId === CustomerProposalStatuses.AwaitingSalesOrder;
    }

    retryCreateStrivenSalesOrder(): void {
        this.customerProposalService.retryCreateStrivenSalesOrder(this.customerProposal.Id).subscribe((result) => {
            if (result) {
                this.notificationsService.success('Successfully created Striven Sales Order for proposal');
                this.customerProposal.StatusId = CustomerProposalStatuses.Approved;
            } else {
                this.notificationsService.error('An error has occurred creating the Sales Order for this proposal');
            }
        });
    }

    private checkIfFormIsDirty(customerProposals: ICustomerProposal, currentFormValues: ICustomerProposal): boolean {
        for (const key in currentFormValues) {
            if (customerProposals.hasOwnProperty.call(currentFormValues, key)) {
                let customerProposal: string = customerProposals[key];
                let currentFormValue: string = currentFormValues[key];
                if (key === 'DateOfProposal') {
                    // DateOfProposal input field stores date in a different format than what is returned from the endpoint.
                    // Need to cast both variables to date string to properly compare them.
                    customerProposal = new Date(customerProposal).toDateString();
                    currentFormValue = new Date(currentFormValue).toDateString();
                }
                if (key === 'InternalNotes' && customerProposal === null) {
                    customerProposal = '';
                }
                if (customerProposal !== currentFormValue) {
                    return true;
                }
            }
        }
        return false;
    }
}
