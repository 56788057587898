import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { BaseService } from '@mt-ng2/base-service';

import { IOffice } from '@model/interfaces/office';
import { Observable } from 'rxjs';
import { IAddress } from '@mt-ng2/format-functions';

export const emptyOffice: IOffice = {
    Archived: false,
    FinanceUrl: null,
    Id: 0,
    LocationManager: null,
    LocationManagerEmail: null,
    Name: null,
    Phone: null,
    StrivenInventoryClassId: null,
    StrivenLocationId: null,
};

@Injectable({
    providedIn: 'root',
})
export class OfficeService extends BaseService<IOffice> {
    constructor(public http: HttpClient) {
        super('/offices', http, null, { entityName: 'Offices' });
    }

    getEmptyOffice(): IOffice {
        return { ...emptyOffice };
    }

    getActiveOffices(): Observable<IOffice[]> {
        return this.http.get<IOffice[]>('/offices/active');
    }

    formatTitleText(office: IOffice): void {
        this.setTitle(`${office.Name} ${office.Archived ? '[Archived]' : ''}`);
    }

    saveAddress(id: number, address: IAddress): Observable<number> {
        if (!address.Id) {
            address.Id = 0;
            return this.http.post<number>(`/offices/${id}/address`, address);
        } else {
            return this.http.put<number>(`/offices/${id}/address`, address);
        }
    }

    deleteAddress(id: number,addressId:number): Observable<object> {
        return this.http.delete(`/offices/${id}/address/${addressId}`, {
            responseType: 'text' as 'json',
        });
    }
}
