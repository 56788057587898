import { formatNumber } from '@angular/common';
import { Validators } from '@angular/forms';
import { IProposalLoanDynamicControlsParameters, ProposalLoanDynamicControls } from '@model/form-controls/proposal-loan.form-controls';
import { IProposalLoan } from '@model/interfaces/proposal-loan';
import { DynamicField, DynamicFieldType, DynamicFieldTypes, NumericInputTypes } from '@mt-ng2/dynamic-form';
import { NumberFormatDisplayFunction, NumericControlTypes } from '@mt-ng2/numeric-control';

export function myCurrencyDisplayFunction(value: number, type: NumericControlTypes, scale: number): string {
    return `$${formatNumber(value, 'en-us', `${1}.${scale}-${scale}`)}`;
}
export class ProposalLoanDynamicControlsPartial extends ProposalLoanDynamicControls {
    constructor(proposalLoan: IProposalLoan, additionalParameters?: IProposalLoanDynamicControlsParameters) {
        super(proposalLoan, additionalParameters);
        const currencyType = new DynamicFieldType({
            fieldType: DynamicFieldTypes.Numeric,
            inputType: NumericInputTypes.Float,
            numericFunctions: {
                numberFormatDisplay: myCurrencyDisplayFunction,
            },
            scale: 2,
        });

        (this.Form.Deposit as DynamicField).labelHtml = '';
        (this.Form.InterestRate as DynamicField).labelHtml = '';
        (this.Form.YearsOfLoan as DynamicField).labelHtml = '';
        (this.Form.InterestRate as DynamicField).type = new DynamicFieldType({
            fieldType: DynamicFieldTypes.Numeric,
            inputType: NumericInputTypes.Percentage,
            scale: 2,
        });
        (this.Form.Deposit as DynamicField).type = currencyType;
        (this.Form.YearsOfLoan as DynamicField).validation = [Validators.required, Validators.min(1)];
        (this.Form.YearsOfLoan as DynamicField).validators = { 'required': true, 'min': 1 };

        (this.Form.InterestRate as DynamicField).validation = [Validators.required, Validators.max(1)];
        (this.Form.InterestRate as DynamicField).validators = { 'required': true, 'max': 1 };
    }
}
