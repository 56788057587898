<div>
    <div class="row">
        <div class="col-md-7">
            <h2>Proposal Electrical Details</h2>
        </div>
    </div>
</div>
<div *ngIf="formCreated">
    <form [formGroup]="proposalElectricalDetailsForm" (ngSubmit)="formSubmitted()">
        <div class="row">
            <div class="col-md-6">
                <div class="miles-form padded">
                    <h4>Proposal Electrical Detail</h4>
                    <mt-dynamic-field
                        [field]="abstractProposalElectricalDetailControls.PanelTypeId"
                        [form]="proposalElectricalDetailsForm"
                    ></mt-dynamic-field>
                    <mt-dynamic-field
                        [field]="abstractProposalElectricalDetailControls.ElectricalDistanceFromOduId"
                        [form]="proposalElectricalDetailsForm"
                    ></mt-dynamic-field>
                    <div *ngIf="displayDisabledMCCableWarning">
                        <span><i>If you want to enter this field please set the job to Commercial</i></span>
                    </div>
                    <mt-dynamic-field
                        [field]="abstractProposalElectricalDetailControls.McCableLengthft"
                        [form]="proposalElectricalDetailsForm"
                    ></mt-dynamic-field>
                    <mt-dynamic-field
                        [field]="abstractProposalElectricalDetailControls.ElectricalSubPanelNeeded"
                        [form]="proposalElectricalDetailsForm"
                    ></mt-dynamic-field>
                    <mt-dynamic-field
                        [field]="abstractProposalElectricalDetailControls.ElectricalTrenching"
                        [form]="proposalElectricalDetailsForm"
                    ></mt-dynamic-field>
                    <div id="electrical-panel-upgrade__checkbox--wrapper">
                        <mt-dynamic-field
                            [field]="abstractProposalElectricalDetailControls.ElectricalPanelUpgrade"
                            [form]="proposalElectricalDetailsForm"
                        ></mt-dynamic-field>
                    </div>
                    <mt-dynamic-field
                        [field]="abstractProposalElectricalDetailControls.ElectricalOutdoorGfi"
                        [form]="proposalElectricalDetailsForm"
                        (valueChanges)="GFICount = $event"
                    ></mt-dynamic-field>
                    <div *ngIf="GFICount == 0">
                        <span><i>Are you sure? Must upload picture verifying existing GFI within 35 feet of outdoor unit location.</i></span>
                    </div>
                    <mt-dynamic-field
                        [field]="abstractProposalElectricalDetailControls.ElectricalNewConstruction"
                        [form]="proposalElectricalDetailsForm"
                    ></mt-dynamic-field>
                    <mt-dynamic-field
                        [field]="abstractProposalElectricalDetailControls.ElectricalSiteVisitRequired"
                        [form]="proposalElectricalDetailsForm"
                    ></mt-dynamic-field>
                    <mt-dynamic-field
                        [field]="abstractProposalElectricalDetailControls.EvChargingStationNeeded"
                        [form]="proposalElectricalDetailsForm"
                    ></mt-dynamic-field>
                    <mt-dynamic-field
                        *ngIf="showDistanceToPanelField"
                        [field]="abstractProposalElectricalDetailControls.DistanceToPanel"
                        [form]="proposalElectricalDetailsForm"
                    ></mt-dynamic-field>
                    <mt-dynamic-field
                        [field]="abstractProposalElectricalDetailControls.ElectricalFinishedBasementId"
                        [form]="proposalElectricalDetailsForm"
                    ></mt-dynamic-field>
                    <mt-dynamic-field
                        [field]="abstractProposalElectricalDetailControls.ElectricalIndoorLineCoverId"
                        [form]="proposalElectricalDetailsForm"
                    ></mt-dynamic-field>
                    <mt-dynamic-field
                        [field]="abstractProposalElectricalDetailControls.ElectricalOutdoorLineCoverId"
                        [form]="proposalElectricalDetailsForm"
                    ></mt-dynamic-field>
                    <div [ngClass]="getJobDifficultyStyling()">
                        <mt-dynamic-field
                            (controlCreated)="jobDifficultyId = $event.value"
                            (valueChanges)="jobDifficultyId = $event"
                            [field]="abstractProposalElectricalDetailControls.ElectricalJobDifficultyId"
                            [form]="proposalElectricalDetailsForm"
                        ></mt-dynamic-field>
                    </div>
                    <mt-dynamic-field
                        [field]="abstractProposalElectricalDetailControls.HouseTypeId"
                        [form]="proposalElectricalDetailsForm"
                    ></mt-dynamic-field>
                    <div class="row">
                        <div class="col-md-12">
                            <button type="submit" mtDisableClickDuringHttpCalls Class="btn btn-flat btn-primary" [disabled]="proposalIsApproved">
                                Save
                            </button>
                            <button type="button" Class="btn btn-flat btn-default" (click)="updateCurrentFormStep(ProposalFormSteps.BasicInfo)">
                                Cancel
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>
