export enum ClaimTypes {
    AppSettings = 1,
    Users = 2,
    Customers = 3,
    Customers_CanChangeStatus = 5,
    UserRoles = 4,
    Audit = 6,
    AccessRequests = 7,
    Estimator = 8,
    Estimator_CanUpdateSettings = 9,
    Offices = 10,
    Parts = 11,
    CustomerProposals = 12,
    ProposalPricings = 13,
    AdminSettings = 14,
    ProposalLoans = 15,
    Parts_CanViewPartsList = 16,
    CustomerProposals_CanEditProposal = 17,
    CustomerProposals_CanViewAdminCheckout = 18,
}
