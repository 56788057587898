import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';

import { AuthService } from '@mt-ng2/auth-module';

import { environment } from '@environment';
import { LocalStorageService } from '@common/services/local-storage.service';

@Component({
    selector: 'app-root',
    template: `
        <div [hidden]="appReady === null">
            <div class="app-root-wrapper wrapper">
                <div>
                    <ng-progress></ng-progress>
                    <router-outlet></router-outlet>
                </div>
            </div>
        </div>
        <div [hidden]="appReady !== null" [style.position]="'relative'">
            <div class="pulse">
                <span></span>
                <img class="logo-img " src="{{ logoFull }}" alt="Logo" />
            </div>
        </div>
    `,
})
export class AppComponent implements OnInit, OnDestroy {
    title = 'app';

    sidebarCollapsed: boolean;
    showNav: boolean;
    showFooter: boolean;
    appReady: boolean = null;
    logoFull = `${environment.imgPath}logo-full.png`;
    subscriptions: Subscription = new Subscription();

    constructor(private authService: AuthService, private cdr: ChangeDetectorRef, private localStorageService: LocalStorageService) { }

    ngOnInit(): void {
        this.subscriptions.add(
            this.authService.appReady.subscribe((answer) => {
                this.appReady = answer;
                this.cdr.detectChanges();
            }),
        );
    }

    ngOnDestroy(): void {
        this.localStorageService.clear();
        this.subscriptions.unsubscribe();
    }
}
