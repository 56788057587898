import { ModuleWithProviders, NgModule } from '@angular/core';
import { AdminPortalSharedModule } from '@common/shared.module';
import { ModalModule } from '@mt-ng2/modal-module';
import { EstimatorModule } from 'admin-portal/estimator/estimator.module';
import { PublicProposalPricingPageComponent } from './public-proposal-pricing-page/public-proposal-pricing-page.component';

@NgModule({
    declarations: [
        PublicProposalPricingPageComponent,
    ],
    imports: [
        AdminPortalSharedModule,
        EstimatorModule,
        ModalModule,
    ],
    providers: [
    ],
})
export class PublicModule {
    static forRoot(): ModuleWithProviders<PublicModule> {
        return {
            ngModule: PublicModule,
        };
    }
}
