import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { BaseService } from '@mt-ng2/base-service';

import { ICustomer } from '@model/interfaces/customer';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class CustomerService extends BaseService<ICustomer> {
    constructor(public http: HttpClient) {
        super('/customers', http, null, {entityName: 'Customers'});
    }

    getArchivedCustomersWithPendingProposals(): Observable<ICustomer[]> {
        return this.http.get<ICustomer[]>(`/customers/archived-with-pending-proposals`);
    }

    getUnarchivedCustomers(): Observable<ICustomer[]> {
        return this.http.get<ICustomer[]>(`/customers/unarchived`);
    }

}
