<td class="header-cell">{{ manufacturer.Name }} Margin</td>
<td>
    <span [ngClass]="getOverrideClass()">{{ proposalPricing.Margin | percent }}</span>
    <span *ngIf="value"
        ><b>{{ value | percent }}</b></span
    >
    <button
        class="btn btn-default"
        [disabled]="proposalIsLocked || disableOverrideButton"
        *ngIf="!isEditing && !value && canOverride"
        (click)="startEditing()"
    >
        Override
    </button>
    <ng-container *ngIf="isEditing">
        <mt-numeric [type]="numericControlTypes.Percentage" [scale]="2" [(ngModel)]="value"></mt-numeric>
        <button class="btn btn-primary" (click)="saveOverride()">Save</button>
    </ng-container>
    <button class="btn btn-danger" [disabled]="proposalIsLocked || disableOverrideButton" *ngIf="!isEditing && value" (click)="removeOverride()">
        Remove
    </button>
</td>
