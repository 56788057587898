import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { IProposalPricing } from '@model/interfaces/proposal-pricing';
import { IProposalPricingAdminCheckoutItem } from '@model/interfaces/proposal-pricing-admin-checkout-item';
import { ProposalPricingService } from '../services/proposal-pricing.service';
import { CustomerLocationService } from '../../customers/services/customer-location.service';
import { forkJoin } from 'rxjs';

@Component({
    selector: 'admin-checkout',
    styles: [
        `
            h2 {
                display: inline-block;
            }
            .table {
                color: black !important;
            }
        `,
    ],
    templateUrl: 'admin-checkout.component.html',
})
export class AdminCheckoutComponent implements OnInit {
    proposalPricing: IProposalPricing;
    adminCheckoutLineItems: IProposalPricingAdminCheckoutItem[] = [];
    adminCheckoutTotal: number;
    customerLocationContainsDynamicPermitZip = false;

    constructor(
        private route: ActivatedRoute,
        private proposalPricingService: ProposalPricingService,
        private customerLocationService: CustomerLocationService,
    ) {}

    ngOnInit(): void {
        const proposalPricingId = this.getIdFromRoute(this.route, 'proposalPricingId');
        if (proposalPricingId) {
            forkJoin([
                this.proposalPricingService.getAdminCheckout(proposalPricingId),
                this.proposalPricingService.getById(proposalPricingId),
            ]).subscribe((answers) => {
                const [adminCheckout, pricing] = answers;
                this.adminCheckoutLineItems = adminCheckout;
                this.adminCheckoutTotal = this.adminCheckoutLineItems.reduce((total, item) => total + item.Total, 0);
                this.proposalPricing = pricing;
                const customerLocationId = this.proposalPricing.CustomerProposal?.CustomerLocationId;
                if (customerLocationId > 0) {
                    this.customerLocationService.CheckAddressContainsDynamicPermitZip(customerLocationId).subscribe((result) => {
                        this.customerLocationContainsDynamicPermitZip = result;
                    });
                }
            });
        }
    }

    getIdFromRoute(route: ActivatedRoute, param: string): number {
        const id = route.snapshot.paramMap.get(param);
        const parsedInt = parseInt(id, 10);
        return isNaN(parsedInt) ? null : parsedInt;
    }

    filterItemsWithoutTotal(): IProposalPricingAdminCheckoutItem[] {
        return this.adminCheckoutLineItems.filter((x) => x.Total > 0 || x.Name === 'Miscellaneous Expenses');
    }

    checkForMiscellaneousExpenses(): boolean {
        return this.adminCheckoutLineItems.some((i) => i.Name === 'Miscellaneous Expenses');
    }
}
