import { Injectable } from '@angular/core';
import { BaseService } from '@mt-ng2/base-service';

import { IProposalPricing } from '@model/interfaces/proposal-pricing';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { IProposalPricingOverrideParams } from '@model/interfaces/custom/proposal-pricing-override-params';
import { IProposalPricingAdminCheckoutItem } from '@model/interfaces/proposal-pricing-admin-checkout-item';

@Injectable({ providedIn: 'root' })
export class ProposalPricingService extends BaseService<IProposalPricing> {

    constructor(public http: HttpClient) {
        super('/proposal-pricings', http);
    }

    generateProposalPricings(customerProposalId: number): Observable<number> {
        return this.http.get<number>(`/proposal-pricings/proposal/${customerProposalId}`);
    }

    updateProposalPricings(proposalPricingId: number): Observable<number> {
        return this.http.put<number>(`/proposal-pricings/${proposalPricingId}/update`, {});
    }

    generateProposalPricingsWithOverrides(proposalPricingId: number, overrides: IProposalPricingOverrideParams): Observable<number> {
        return this.http.put<number>(`/proposal-pricings/${proposalPricingId}/override`, overrides);
    }

    updatePricingWithWarranty(proposalPricingId: number, includeWarranty: boolean): Observable<IProposalPricing> {
        return this.http.put<IProposalPricing>(`/proposal-pricings/${proposalPricingId}/warranty`, { includeWarranty });
    }

    getAdminCheckout(proposalPricingId: number): Observable<IProposalPricingAdminCheckoutItem[]> {
        return this.http.get<IProposalPricingAdminCheckoutItem[]>(`/proposal-pricings/${proposalPricingId}/admin-checkout`);
    }

}
