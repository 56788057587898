import { IProposalLoan } from '../../../model/interfaces/proposal-loan';

export const LoanCalculations = {
    getMonthlyPayment: getMonthlyPayment,
};

function getMonthlyPayment(proposalLoan: IProposalLoan): number {
    const presentValue = proposalLoan.OrderTotal - proposalLoan.Deposit;
    const ratePerPeriod = proposalLoan.InterestRate / 12;
    const numberOfPeriods = proposalLoan.YearsOfLoan * 12;
    return (presentValue * ratePerPeriod) / (1 - Math.pow((1 + ratePerPeriod), -numberOfPeriods));
}
