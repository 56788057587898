import { Validators } from '@angular/forms';

import {
    DynamicField,
    DynamicFieldType,
    DynamicFieldTypes,
    DynamicLabel,
    noZeroRequiredValidator,
    InputTypes,
    NumericInputTypes,
    SelectInputTypes,
} from '@mt-ng2/dynamic-form';
import { getMetaItemValue } from '@mt-ng2/common-functions';
import { IMetaItem } from '../interfaces/base';

import { IExpandableObject } from '../expandable-object';
import { IProposalElectricalDetail } from '../interfaces/proposal-electrical-detail';
import { IElectricalDistanceFromOdu } from '../interfaces/electrical-distance-from-odu';
import { IElectricalFinishedBasement } from '../interfaces/electrical-finished-basement';
import { IElectricalIndoorLineCover } from '../interfaces/electrical-indoor-line-cover';
import { IElectricalJobDifficulty } from '../interfaces/electrical-job-difficulty';
import { IElectricalOutdoorLineCover } from '../interfaces/electrical-outdoor-line-cover';
import { IHouseType } from '../interfaces/house-type';
import { IElectricalPanelType } from '../interfaces/electrical-panel-type';
import { ICustomerProposal } from '../interfaces/customer-proposal';

export interface IProposalElectricalDetailDynamicControlsParameters {
    formGroup?: string;
    proposals?: ICustomerProposal[];
    panelTypes?: IElectricalPanelType[];
    electricalDistanceFromOdus?: IElectricalDistanceFromOdu[];
    electricalFinishedBasements?: IElectricalFinishedBasement[];
    electricalIndoorLineCovers?: IElectricalIndoorLineCover[];
    electricalOutdoorLineCovers?: IElectricalOutdoorLineCover[];
    electricalJobDifficulties?: IElectricalJobDifficulty[];
    houseTypes?: IHouseType[];
}

export class ProposalElectricalDetailDynamicControls {

    formGroup: string;
    proposals?: ICustomerProposal[];
    panelTypes?: IElectricalPanelType[];
    electricalDistanceFromOdus?: IElectricalDistanceFromOdu[];
    electricalFinishedBasements?: IElectricalFinishedBasement[];
    electricalIndoorLineCovers?: IElectricalIndoorLineCover[];
    electricalOutdoorLineCovers?: IElectricalOutdoorLineCover[];
    electricalJobDifficulties?: IElectricalJobDifficulty[];
    houseTypes?: IHouseType[];

    Form: IExpandableObject;
    View: IExpandableObject;

    constructor(private proposalelectricaldetail?: IProposalElectricalDetail, additionalParameters?: IProposalElectricalDetailDynamicControlsParameters) {
        this.formGroup = additionalParameters && additionalParameters.formGroup || 'ProposalElectricalDetail';
        this.proposals = additionalParameters && additionalParameters.proposals || undefined;
        this.panelTypes = additionalParameters && additionalParameters.panelTypes || undefined;
        this.electricalDistanceFromOdus = additionalParameters && additionalParameters.electricalDistanceFromOdus || undefined;
        this.electricalFinishedBasements = additionalParameters && additionalParameters.electricalFinishedBasements || undefined;
        this.electricalIndoorLineCovers = additionalParameters && additionalParameters.electricalIndoorLineCovers || undefined;
        this.electricalOutdoorLineCovers = additionalParameters && additionalParameters.electricalOutdoorLineCovers || undefined;
        this.electricalJobDifficulties = additionalParameters && additionalParameters.electricalJobDifficulties || undefined;
        this.houseTypes = additionalParameters && additionalParameters.houseTypes || undefined;

        this.Form = {
            DistanceToPanel: new DynamicField({
                formGroup: this.formGroup,
                label: 'Distance To Panel',
                name: 'DistanceToPanel',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: NumericInputTypes.Integer,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.proposalelectricaldetail && this.proposalelectricaldetail.DistanceToPanel || null,
            }),
            ElectricalDistanceFromOduId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Electrical Distance From Odu',
                name: 'ElectricalDistanceFromOduId',
                options: this.electricalDistanceFromOdus,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.proposalelectricaldetail && this.proposalelectricaldetail.ElectricalDistanceFromOduId || null,
            }),
            ElectricalFinishedBasementId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Electrical Finished Basement',
                name: 'ElectricalFinishedBasementId',
                options: this.electricalFinishedBasements,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.proposalelectricaldetail && this.proposalelectricaldetail.ElectricalFinishedBasementId || null,
            }),
            ElectricalIndoorLineCoverId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Electrical Indoor Line Cover',
                name: 'ElectricalIndoorLineCoverId',
                options: this.electricalIndoorLineCovers,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.proposalelectricaldetail && this.proposalelectricaldetail.ElectricalIndoorLineCoverId || null,
            }),
            ElectricalJobDifficultyId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Electrical Job Difficulty',
                name: 'ElectricalJobDifficultyId',
                options: this.electricalJobDifficulties,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.proposalelectricaldetail && this.proposalelectricaldetail.ElectricalJobDifficultyId || null,
            }),
            ElectricalNewConstruction: new DynamicField({
                formGroup: this.formGroup,
                label: 'Electrical New Construction',
                name: 'ElectricalNewConstruction',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.proposalelectricaldetail && this.proposalelectricaldetail.hasOwnProperty('ElectricalNewConstruction') && this.proposalelectricaldetail.ElectricalNewConstruction != null ? this.proposalelectricaldetail.ElectricalNewConstruction : false,
            }),
            ElectricalOutdoorGfi: new DynamicField({
                formGroup: this.formGroup,
                label: 'Electrical Outdoor Gfi',
                name: 'ElectricalOutdoorGfi',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: NumericInputTypes.Integer,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.proposalelectricaldetail && this.proposalelectricaldetail.ElectricalOutdoorGfi || 1,
            }),
            ElectricalOutdoorLineCoverId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Electrical Outdoor Line Cover',
                name: 'ElectricalOutdoorLineCoverId',
                options: this.electricalOutdoorLineCovers,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.proposalelectricaldetail && this.proposalelectricaldetail.ElectricalOutdoorLineCoverId || null,
            }),
            ElectricalPanelUpgrade: new DynamicField({
                formGroup: this.formGroup,
                label: 'Electrical Panel Upgrade',
                name: 'ElectricalPanelUpgrade',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.proposalelectricaldetail && this.proposalelectricaldetail.hasOwnProperty('ElectricalPanelUpgrade') && this.proposalelectricaldetail.ElectricalPanelUpgrade != null ? this.proposalelectricaldetail.ElectricalPanelUpgrade : false,
            }),
            ElectricalSiteVisitRequired: new DynamicField({
                formGroup: this.formGroup,
                label: 'Electrical Site Visit Required',
                name: 'ElectricalSiteVisitRequired',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.proposalelectricaldetail && this.proposalelectricaldetail.hasOwnProperty('ElectricalSiteVisitRequired') && this.proposalelectricaldetail.ElectricalSiteVisitRequired != null ? this.proposalelectricaldetail.ElectricalSiteVisitRequired : false,
            }),
            ElectricalSubPanelNeeded: new DynamicField({
                formGroup: this.formGroup,
                label: 'Electrical Sub Panel Needed',
                name: 'ElectricalSubPanelNeeded',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.proposalelectricaldetail && this.proposalelectricaldetail.hasOwnProperty('ElectricalSubPanelNeeded') && this.proposalelectricaldetail.ElectricalSubPanelNeeded != null ? this.proposalelectricaldetail.ElectricalSubPanelNeeded : false,
            }),
            ElectricalTrenching: new DynamicField({
                formGroup: this.formGroup,
                label: 'Electrical Trenching',
                name: 'ElectricalTrenching',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.proposalelectricaldetail && this.proposalelectricaldetail.hasOwnProperty('ElectricalTrenching') && this.proposalelectricaldetail.ElectricalTrenching != null ? this.proposalelectricaldetail.ElectricalTrenching : false,
            }),
            EvChargingStationNeeded: new DynamicField({
                formGroup: this.formGroup,
                label: 'Ev Charging Station Needed',
                name: 'EvChargingStationNeeded',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.proposalelectricaldetail && this.proposalelectricaldetail.hasOwnProperty('EvChargingStationNeeded') && this.proposalelectricaldetail.EvChargingStationNeeded != null ? this.proposalelectricaldetail.EvChargingStationNeeded : false,
            }),
            HouseTypeId: new DynamicField({
                formGroup: this.formGroup,
                label: 'House Type',
                name: 'HouseTypeId',
                options: this.houseTypes,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.proposalelectricaldetail && this.proposalelectricaldetail.HouseTypeId || null,
            }),
            McCableLengthft: new DynamicField({
                formGroup: this.formGroup,
                label: 'Mc Cable Lengthft',
                name: 'McCableLengthft',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: 2,
                }),
                validation: [  ],
                validators: {  },
                value: this.proposalelectricaldetail && this.proposalelectricaldetail.McCableLengthft || 0,
            }),
            PanelTypeId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Panel Type',
                name: 'PanelTypeId',
                options: this.panelTypes,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.proposalelectricaldetail && this.proposalelectricaldetail.PanelTypeId || null,
            }),
            ProposalId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Proposal',
                name: 'ProposalId',
                options: this.proposals,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [ noZeroRequiredValidator ],
                validators: { 'required': true },
                value: this.proposalelectricaldetail && this.proposalelectricaldetail.ProposalId || null,
            }),
        };

        this.View = {
            DistanceToPanel: new DynamicLabel({
                label: 'Distance To Panel',
                value: this.proposalelectricaldetail && this.proposalelectricaldetail.DistanceToPanel || null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: NumericInputTypes.Integer,
                    scale: null,
                }),
            }),
            ElectricalDistanceFromOduId: new DynamicLabel({
                label: 'Electrical Distance From Odu',
                value: getMetaItemValue(this.electricalDistanceFromOdus as unknown as IMetaItem[], this.proposalelectricaldetail && this.proposalelectricaldetail.hasOwnProperty('ElectricalDistanceFromOduId') ? this.proposalelectricaldetail.ElectricalDistanceFromOduId : null) ?? '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            }),
            ElectricalFinishedBasementId: new DynamicLabel({
                label: 'Electrical Finished Basement',
                value: getMetaItemValue(this.electricalFinishedBasements as unknown as IMetaItem[], this.proposalelectricaldetail && this.proposalelectricaldetail.hasOwnProperty('ElectricalFinishedBasementId') ? this.proposalelectricaldetail.ElectricalFinishedBasementId : null) ?? '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            }),
            ElectricalIndoorLineCoverId: new DynamicLabel({
                label: 'Electrical Indoor Line Cover',
                value: getMetaItemValue(this.electricalIndoorLineCovers as unknown as IMetaItem[], this.proposalelectricaldetail && this.proposalelectricaldetail.hasOwnProperty('ElectricalIndoorLineCoverId') ? this.proposalelectricaldetail.ElectricalIndoorLineCoverId : null) ?? '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            }),
            ElectricalJobDifficultyId: new DynamicLabel({
                label: 'Electrical Job Difficulty',
                value: getMetaItemValue(this.electricalJobDifficulties as unknown as IMetaItem[], this.proposalelectricaldetail && this.proposalelectricaldetail.hasOwnProperty('ElectricalJobDifficultyId') ? this.proposalelectricaldetail.ElectricalJobDifficultyId : null) ?? '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            }),
            ElectricalNewConstruction: new DynamicLabel({
                label: 'Electrical New Construction',
                value: this.proposalelectricaldetail && this.proposalelectricaldetail.hasOwnProperty('ElectricalNewConstruction') && this.proposalelectricaldetail.ElectricalNewConstruction != null ? this.proposalelectricaldetail.ElectricalNewConstruction : false,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            }),
            ElectricalOutdoorGfi: new DynamicLabel({
                label: 'Electrical Outdoor Gfi',
                value: this.proposalelectricaldetail && this.proposalelectricaldetail.ElectricalOutdoorGfi || 1,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: NumericInputTypes.Integer,
                    scale: null,
                }),
            }),
            ElectricalOutdoorLineCoverId: new DynamicLabel({
                label: 'Electrical Outdoor Line Cover',
                value: getMetaItemValue(this.electricalOutdoorLineCovers as unknown as IMetaItem[], this.proposalelectricaldetail && this.proposalelectricaldetail.hasOwnProperty('ElectricalOutdoorLineCoverId') ? this.proposalelectricaldetail.ElectricalOutdoorLineCoverId : null) ?? '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            }),
            ElectricalPanelUpgrade: new DynamicLabel({
                label: 'Electrical Panel Upgrade',
                value: this.proposalelectricaldetail && this.proposalelectricaldetail.hasOwnProperty('ElectricalPanelUpgrade') && this.proposalelectricaldetail.ElectricalPanelUpgrade != null ? this.proposalelectricaldetail.ElectricalPanelUpgrade : false,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            }),
            ElectricalSiteVisitRequired: new DynamicLabel({
                label: 'Electrical Site Visit Required',
                value: this.proposalelectricaldetail && this.proposalelectricaldetail.hasOwnProperty('ElectricalSiteVisitRequired') && this.proposalelectricaldetail.ElectricalSiteVisitRequired != null ? this.proposalelectricaldetail.ElectricalSiteVisitRequired : false,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            }),
            ElectricalSubPanelNeeded: new DynamicLabel({
                label: 'Electrical Sub Panel Needed',
                value: this.proposalelectricaldetail && this.proposalelectricaldetail.hasOwnProperty('ElectricalSubPanelNeeded') && this.proposalelectricaldetail.ElectricalSubPanelNeeded != null ? this.proposalelectricaldetail.ElectricalSubPanelNeeded : false,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            }),
            ElectricalTrenching: new DynamicLabel({
                label: 'Electrical Trenching',
                value: this.proposalelectricaldetail && this.proposalelectricaldetail.hasOwnProperty('ElectricalTrenching') && this.proposalelectricaldetail.ElectricalTrenching != null ? this.proposalelectricaldetail.ElectricalTrenching : false,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            }),
            EvChargingStationNeeded: new DynamicLabel({
                label: 'Ev Charging Station Needed',
                value: this.proposalelectricaldetail && this.proposalelectricaldetail.hasOwnProperty('EvChargingStationNeeded') && this.proposalelectricaldetail.EvChargingStationNeeded != null ? this.proposalelectricaldetail.EvChargingStationNeeded : false,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            }),
            HouseTypeId: new DynamicLabel({
                label: 'House Type',
                value: getMetaItemValue(this.houseTypes as unknown as IMetaItem[], this.proposalelectricaldetail && this.proposalelectricaldetail.hasOwnProperty('HouseTypeId') ? this.proposalelectricaldetail.HouseTypeId : null) ?? '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            }),
            McCableLengthft: new DynamicLabel({
                label: 'Mc Cable Lengthft',
                value: this.proposalelectricaldetail && this.proposalelectricaldetail.McCableLengthft || 0,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: 2,
                }),
            }),
            PanelTypeId: new DynamicLabel({
                label: 'Panel Type',
                value: getMetaItemValue(this.panelTypes as unknown as IMetaItem[], this.proposalelectricaldetail && this.proposalelectricaldetail.hasOwnProperty('PanelTypeId') ? this.proposalelectricaldetail.PanelTypeId : null) ?? '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            }),
            ProposalId: new DynamicLabel({
                label: 'Proposal',
                value: getMetaItemValue(this.proposals as unknown as IMetaItem[], this.proposalelectricaldetail && this.proposalelectricaldetail.hasOwnProperty('ProposalId') ? this.proposalelectricaldetail.ProposalId : null) ?? '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            }),
        };

    }
}
