<div *ngIf="formCreated">
    <form [formGroup]="proposalLoanForm" (ngSubmit)="formSubmitted()">
        <table class="table table-bordered">
            <tbody>
                <tr>
                    <td class="label-cell">Job Total</td>
                    <td class="label-cell">{{ orderTotal | currency }}</td>
                </tr>
                <tr>
                    <td class="label-cell">Deposit</td>
                    <td><mt-dynamic-field [field]="abstractProposalLoanControls.Deposit" [form]="proposalLoanForm"></mt-dynamic-field></td>
                </tr>
                <tr>
                    <td class="label-cell">Years of Loan</td>
                    <td><mt-dynamic-field [field]="abstractProposalLoanControls.YearsOfLoan" [form]="proposalLoanForm"></mt-dynamic-field></td>
                </tr>
                <tr>
                    <td class="label-cell">Interest Rate</td>
                    <td><mt-dynamic-field [field]="abstractProposalLoanControls.InterestRate" [form]="proposalLoanForm"></mt-dynamic-field></td>
                </tr>
                <tr>
                    <td class="label-cell">Monthly Payment</td>
                    <td class="mp-cell">{{ monthlyPayment | currency }}</td>
                </tr>
            </tbody>
        </table>
        <button type="submit" mtDisableClickDuringHttpCalls Class="btn btn-primary">Save Loan</button>
        <button type="button" Class="btn btn-flat btn-default" (click)="onCancelClick()">Cancel</button>
    </form>
</div>
