import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { StaticMetaItemService } from '@mt-ng2/base-service';
import { IOduElevation} from '../../../model/interfaces/odu-elevation';

@Injectable({
    providedIn: 'root',
})
export class OduElevationService extends StaticMetaItemService<IOduElevation> {
    constructor(public http: HttpClient) {
        super('OduElevationService', 'OduElevation', 'OduElevationIds', '/odu-elevations', http);
    }

}
