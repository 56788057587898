import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ICustomerProposalPricingPageDTO } from '@model/interfaces/custom/customer-proposal-pricing-page-dto';
import { AuthService, ClaimsService } from '@mt-ng2/auth-module';
import { PrintModeService } from '@mt-ng2/entity-list-module';
import { ModalService } from '@mt-ng2/modal-module';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { CustomerProposalService } from 'admin-portal/estimator/services/customer-proposal.service';
import { ProposalPricingService } from 'admin-portal/estimator/services/proposal-pricing.service';
import { ProposalPricingPageComponent } from '../../estimator/proposal-pricing-page/proposal-pricing-page.component';
import dayjs from 'dayjs';

@Component({
    selector: 'public-proposal-pricing-page',
    styleUrls: ['./public-proposal-pricing-page.component.css'],
    templateUrl: 'public-proposal-pricing-page.component.html',
})

export class PublicProposalPricingPageComponent extends ProposalPricingPageComponent {
    proposalIsLocked = false;

    constructor(
        private _route: ActivatedRoute,
        private _proposalPricingService: ProposalPricingService,
        private _router: Router,
        private _baseprintModeService: PrintModeService,
        private _customerProposalService: CustomerProposalService,
        private _modalService: ModalService,
        private _notificationsService: NotificationsService,
        private authService: AuthService,
        private _claimsService: ClaimsService,
    ) {
        super(_route, _proposalPricingService, _router, _baseprintModeService,
            _customerProposalService, _modalService, _notificationsService, _claimsService);
    }

    ngOnInit(): void {
        // appReady determines if an authenticated connection has been made.
        // While it's waiting it shows a loading icon.  When appReady has a
        // value the loading icon is hidden.  We always want this to be true
        // when you are on the public page.  Because you aren't authed!
        if (this.authService.appReady && !this.authService.appReady.getValue()) {
            this.authService.appReady.next(true);
        }
        const proposalUniqueId = this.getUniqueIdFromRoute(this._route, 'proposalUniqueIdParam');
        if (proposalUniqueId) {
            this.getProposalByUniqueIdentifier(proposalUniqueId);
        }
    }

    private getProposalByUniqueIdentifier(proposalUniqueId: string): void {
        this._customerProposalService.getByUniqueIdentifier(proposalUniqueId).subscribe((dto: ICustomerProposalPricingPageDTO) => {
            if (!dto) {
                this._notificationsService.error(`Unable to retrieve public customer proposal ${proposalUniqueId}.`);
                return;
            }

            const proposalIsInApproved = this._customerProposalService.proposalIsInApproved(dto.CustomerProposal);
            if (dto.IsExpired && !proposalIsInApproved) {
                this.handleExpiredProposal();
                return;
            }

            this.assignProposalValues(dto);
        });
    }

    handleExpiredProposal(): void {
        this._modalService.showModal({
            allowEnterKey: false,
            allowEscapeKey: false,
            allowOutsideClick: false,
            text: 'This Proposal has expired. Please contact Dave\'s World for a requote.',
            title: 'Whoops!',
        	icon: 'error',
}).subscribe((result) => {
            if (result.isConfirmed) {
                window.location.href = 'https://www.davesworld.com/locations-contact';
            }
        });
    }

    private assignProposalValues(dto: ICustomerProposalPricingPageDTO): void {
        this.dto = dto;
        this.proposalPricing = dto.ProposalPricing;
        this.customerProposal = dto.CustomerProposal;
        this.proposalIsSigned = dto.IsSigned;
        this.proposalIsCommercial = dto.IsCommercial;
        this.proposalLoan = dto.ProposalLoan;
        if (this._customerProposalService.proposalIsInApproved(this.customerProposal)) {
            this.proposalIsLocked = true;
        }
    }

    getUniqueIdFromRoute(route: ActivatedRoute, param: string): string {
        return route.snapshot.paramMap.get(param);
    }

    displayAccessCodeAndUpdatePricingWithWarranty(): void {
        this.displayAcessCodeModal(() => {
            this.updatePricingWithWarranty();
        });
    }

    displayAcessCodeModal(callback: () => void): void {
        this._modalService.showModal({
            input: 'text',
            text: 'Please enter the access code provided in the email for this proposal:',
            title: 'Enter Access Code',
        	icon: 'question',
}).subscribe((result) => {
            if (result.isConfirmed) {
                this._customerProposalService.getAccessCode(
                    this.customerProposal.Id, 
                    (result.value as string).trim()
                ).subscribe((answer) => {
                    if (answer) {
                        if(dayjs(answer.ExpirationDate).isBefore(new Date())) {
                            return this._notificationsService.error(
                                "The access code provided is expired. Please contact support for further assistance."
                            );
                        }
                        //callback function for updating warranty!
                        if (callback) {
                            callback();
                            return;
                        }
                        this.displaySignaturePad();
                    } else {
                        this._notificationsService.error(
                            "The access code provided is invalid. Please contact support for further assistance."
                        );
                    }
                });
            }
        });
    }

}
