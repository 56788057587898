import { Component, inject, Input, OnDestroy, OnInit } from '@angular/core';
import { IHeatPumpConfigurationStatus } from '@model/interfaces/custom/heat-pump-configuartion-status';
import { IManufacturer } from '@model/interfaces/manufacturer';
import { IProposalOutdoorHeatPump } from '@model/interfaces/proposal-outdoor-heat-pump';
import { ProposalFormSteps } from '@model/ProposalFormSteps';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { forkJoin, Subscription, switchMap } from 'rxjs';
import { CustomerProposalInterfaceStateService } from '../services/customer-proposal-interface-state.service';
import { ManufacturerService } from '../services/manufacturer.service';
import { ProposalOutdoorHeatPumpService } from '../services/proposal-outdoor-heat-pump.service';
import { ProposalIndoorHeatPumpService } from '../services/proposal-indoor-heat-pump.service';
import { IProposalIndoorHeatPump } from '@model/interfaces/proposal-indoor-heat-pump';
import { ICustomerProposal } from '@model/interfaces/customer-proposal';
import { HeatPumpFormStateService } from '../services/heat-pump-form-state.service';
import { ActivatedRoute } from '@angular/router';
import { ProposalAccessoryService } from '../services/proposal-accessory.service';
import { IProposalAccessory } from '@model/interfaces/proposal-accessory';
import { CustomerProposalService } from '../services/customer-proposal.service';
import { HeatPumpService } from '../services/heat-pump.service';
import { CategoryService } from '../services/category.service';
import { ICategory } from '@model/interfaces/category';

@Component({
    providers: [HeatPumpFormStateService, HeatPumpService],
    selector: 'heat-pumps-form',
    styles: [
        `
            .is-valid {
                border: 14px solid green;
            }
            .is-invalid {
                border: 14px solid red;
            }
        `,
    ],
    templateUrl: 'heat-pumps-form.component.html',
})
export class HeatPumpsFormComponent implements OnInit, OnDestroy {
    @Input() customerProposal: ICustomerProposal;

    categoryService: CategoryService = inject(CategoryService);

    ProposalFormSteps = ProposalFormSteps;
    manufacturers: IManufacturer[] = [];
    selectedManufacturerId: number;
    selectedManufacturerIdOnInit = 0;
    selectedOutdoorUnitIds: number[] = [];
    subscriptions: Subscription = new Subscription();
    heatPumpConfigurationStatus: IHeatPumpConfigurationStatus;
    proposalOutdoorHeatPumps: IProposalOutdoorHeatPump[];
    proposalIndoorHeatPumps: IProposalIndoorHeatPump[];
    customerProposalId: number;
    accessories: IProposalAccessory[];
    MATERIALS_MANUFACTURER = 1;
    proposalIsApproved = false;

    categoryId: number;
    categories: ICategory[] = [];

    constructor(
        private proposalStateService: CustomerProposalInterfaceStateService,
        private manufacturerService: ManufacturerService,
        private notificationsService: NotificationsService,
        private proposalOutdoorHeatPumpService: ProposalOutdoorHeatPumpService,
        private proposalIndoorHeatPumpService: ProposalIndoorHeatPumpService,
        private heatPumpService: HeatPumpService,
        private heatPumpStateService: HeatPumpFormStateService,
        private route: ActivatedRoute,
        private proposalAccessoryService: ProposalAccessoryService,
        private customerProposalService: CustomerProposalService,
    ) {}

    ngOnInit(): void {
        this.customerProposalId = this.getIdFromRoute(this.route, 'customerProposalId');

        forkJoin([
            this.manufacturerService.getAll(), 
            this.categoryService.getItems()
        ]).subscribe(([manufacturers, categories]) => {
            this.manufacturers = manufacturers.filter((m) => {
                return m.Id !== this.MATERIALS_MANUFACTURER;
            });
            this.categories = categories;
        });

        this.initalizeSubscriptions();
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    private initalizeSubscriptions(): void {
        this.subscriptions.add(
            this.proposalStateService.customerProposal$.subscribe((value) => {
                this.customerProposal = value;
                // Lock a Proposal after approval
                if (!this.customerProposalService.proposalIsInProgress(this.customerProposal)) {
                    this.proposalIsApproved = true;
                }
            }),
        );
        this.subscriptions.add(
            this.proposalStateService.heatPumpConfigurationStatus$.subscribe((value) => {
                this.heatPumpConfigurationStatus = value;
            }),
        );
        this.subscriptions.add(
            this.heatPumpStateService.proposalOutdoorHeatPumps$.subscribe((value) => {
                this.proposalOutdoorHeatPumps = value;
            }),
        );
        this.subscriptions.add(
            this.heatPumpStateService.proposalIndoorHeatPumps$.subscribe((value) => {
                this.proposalIndoorHeatPumps = value;
            }),
        );
        this.subscriptions.add(
            this.heatPumpStateService.accessories$.subscribe((value) => {
                this.accessories = value;
            }),
        );
        this.subscriptions.add(
            this.heatPumpStateService.manufacturerSelection$.subscribe((value) => {
                if (value) {
                    this.selectedManufacturerId = value.ManufacturerId;
                    if (this.selectedManufacturerIdOnInit !== 0) {
                        // Use JSON.parse to save by value instead of reference https://stackoverflow.com/a/18359187.
                        this.selectedManufacturerIdOnInit = JSON.parse(JSON.stringify(this.selectedManufacturerId));
                    }
                }
            }),
        );
        this.subscriptions.add(
            this.heatPumpStateService.categorySelection$.subscribe((value) => {
                this.categoryId = value;
            }),
        );
    }

    updateCurrentFormStep(step: ProposalFormSteps): void {
        this.proposalStateService.currentFormStep$.next(step);
    }

    onCategoryUpdate(): void {
        this.heatPumpStateService.categorySelection$.next(this.categoryId);
    }

    onManufacturerUpdate(): void {
        this.heatPumpStateService.manufacturerSelection$.next({
            ManufacturerId: this.selectedManufacturerId,
            RequiresChange: true,
        });
        this.updateHeatPumpConfigurationStatus();
        const formIsDirty = `${this.selectedManufacturerIdOnInit}` !== `${this.selectedManufacturerId}`;
        this.customerProposalService.setFormIsDirty(formIsDirty);
    }

    private updateHeatPumpConfigurationStatus(): void {
        this.heatPumpConfigurationStatus = {
            BranchBoxes: 0,
            BTUThreshold: 0,
            BTUTotal: 0,
            DrainLine: 0,
            IsValid: true,
            LineSet: 0,
            MaxIDU: 0,
            MinIDU: 0,
            Quantity: 0,
            RequiredBranchBoxes: 0,
        };
        this.proposalStateService.heatPumpConfigurationStatus$.next(this.heatPumpConfigurationStatus);
    }

    getConfigurationStatus(): string {
        if (this.heatPumpConfigurationStatus.Quantity === 0) {
            return '';
        }
        return this.heatPumpConfigurationStatus.IsValid ? 'is-valid' : 'is-invalid';
    }

    saveHeatPumps(): void {
        const errors = this.heatPumpService.validateHeatPumpConfig(this.heatPumpConfigurationStatus);
        if (errors.length > 0) {
            this.notificationsService.error(`Validation failed: \r\n -- ${errors[0]}`, null, { timeOut: 5000 });
        }

        const indoorPumpLineHides = this.proposalIndoorHeatPumps
            .filter((pump) => pump.ProposalIndoorHeatPumpLineHides.length > 0)
            .map((pump) => ({ ...pump.ProposalIndoorHeatPumpLineHides[0] }));

        this.proposalIndoorHeatPumps.forEach((p) => {
            p.ProposalIndoorHeatPumpLineHides = [];
        });

        forkJoin([
            this.proposalOutdoorHeatPumpService.updateProposalOutdoorHeatPumps(this.customerProposalId, this.proposalOutdoorHeatPumps),
            this.proposalIndoorHeatPumpService.updateProposalIndoorHeatPumps(
                this.customerProposalId,
                this.proposalIndoorHeatPumps,
                indoorPumpLineHides,
            )
        ]).pipe(
            switchMap(() => 
                this.proposalAccessoryService.updateProposalAccessories(this.customerProposalId, this.accessories)
            )
        ).subscribe({
            next: () => {
              this.notificationsService.success('Successfully updated Heat Pumps');
              this.updateCurrentFormStep(ProposalFormSteps.BasicInfo);
              this.proposalStateService.markStepCompleted(ProposalFormSteps.HeatPumps);
              this.customerProposalService.setFormIsDirty(false);
            },
            error: (errorMessage: string) => {
              this.notificationsService.error(errorMessage);
            }
          });
    }

    getIdFromRoute(route: ActivatedRoute, param: string): number {
        const id = route.snapshot.paramMap.get(param);
        const parsedInt = parseInt(id, 10);
        return isNaN(parsedInt) ? null : parseInt(id, 10);
    }
}
