<label>Outdoor Heat Pump(s)</label>
<table class="table table-bordered">
    <thead>
        <tr>
            <th>Model name</th>
            <th>Quantity</th>
            <th>Min IDU</th>
            <th>Max IDU</th>
            <th>Requires Branch Box</th>
            <th>Image</th>
            <th></th>
        </tr>
    </thead>
    <tbody>
        <tr *ngIf="selectedOutdoorHeatPumps.length === 0">
            <td colspan="5">
                <i>No selected Outdoor Heat pumps</i>
            </td>
        </tr>
        <tr
            outdoor-heat-pump-line-item
            *ngFor="let pump of selectedOutdoorHeatPumps; index as i"
            [index]="i"
            [selectedOutdoorHeatPumps]="selectedOutdoorHeatPumps"
            [parts]="parts"
            [manufacturerId]="manufacturerId"
            (onSelectedHeatPumpsUpdate)="onPumpsUpdate($event)"
            [proposalIsApproved]="proposalIsApproved"
            [proposalId]="proposalId"
            [categoryId]="categoryId"
        ></tr>
    </tbody>
    <thead>
        <tr>
            <th>TOTAL</th>
            <th>{{ getTotalValue('Quantity') }}</th>
            <th>{{ getTotalValue('MinIDU') }}</th>
            <th>{{ getTotalValue('MaxIDU') }}</th>
            <th></th>
            <th></th>
            <th></th>
        </tr>
    </thead>
</table>
<div class="btn btn-primary btn-fab-md" (click)="addOutdoorHeatPump()" *ngIf="!proposalIsApproved">
    <span class="fa fa-plus"></span>
</div>
