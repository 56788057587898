import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard, ClaimValues } from '@mt-ng2/auth-module';
import { EstimatorSettingsComponent } from './estimator-settings/estimator-settings.component';
import { estimatorPathSlugs } from '../default-routes/estimator-paths.library';
import { CustomerProposalInterfaceComponent } from './customer-proposal-interface/customer-proposal-interface.component';
import { ClaimTypes } from '@model/ClaimTypes';
import { ProposalPricingDetailComponent } from './proposal-pricing-detail/proposal-pricing-detail.component';
import { ProposalPricingPageComponent } from './proposal-pricing-page/proposal-pricing-page.component';
import { AdminCheckoutComponent } from './admin-checkout/admin-checkout.component';

const customerProposalEntityConfig = {
    claimType: ClaimTypes.CustomerProposals,
    claimValues: [ClaimValues.ReadOnly, ClaimValues.FullAccess],
    customerEntityIdParam: 'customerId',
    entityIdParam: 'customerProposalId',
    pricingIdParam: 'proposalPricingId',
    title: 'Customer Proposal',
};

const estimatorRoutes: Routes = [
    {
        component: CustomerProposalInterfaceComponent,
        data: customerProposalEntityConfig,
        path: `customer/:${customerProposalEntityConfig.customerEntityIdParam}`,
    },
    {
        component: CustomerProposalInterfaceComponent,
        data: customerProposalEntityConfig,
        path: `customer/:${customerProposalEntityConfig.customerEntityIdParam}/proposal/:${customerProposalEntityConfig.entityIdParam}`,
    },
    {
        component: ProposalPricingDetailComponent,
        data: customerProposalEntityConfig,
        path: `proposal/:${customerProposalEntityConfig.entityIdParam}/pricing/:${customerProposalEntityConfig.pricingIdParam}`,
    },
    {
        component: ProposalPricingPageComponent,
        data: customerProposalEntityConfig,
        path: `proposal/:${customerProposalEntityConfig.entityIdParam}/pricing/:${customerProposalEntityConfig.pricingIdParam}/page`,
    },
    {
        component: AdminCheckoutComponent,
        data: customerProposalEntityConfig,
        path: `proposal/:${customerProposalEntityConfig.entityIdParam}/pricing/:${customerProposalEntityConfig.pricingIdParam}/checkout`,
    },
    {
        canActivate: [AuthGuard],
        component: EstimatorSettingsComponent,
        path: estimatorPathSlugs.settings,
        pathMatch: 'full',
    },
];

@NgModule({
    exports: [RouterModule],
    imports: [RouterModule.forChild(estimatorRoutes)],
})
export class EstimatorRoutingModule { }
