import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { StaticMetaItemService } from '@mt-ng2/base-service';
import { IElectricalPanelType } from '../../../model/interfaces/electrical-panel-type';

@Injectable({
    providedIn: 'root',
})
export class ElectricalPanelTypeService extends StaticMetaItemService<IElectricalPanelType> {
    constructor(public http: HttpClient) {
        super('ElectricalPanelTypeService', 'ElectricalPanelType', 'PanelTypeIds', '/electrical-panel-types', http);
    }

}
