import { IMetaItem } from "@mt-ng2/base-service";
import { Validators } from '@angular/forms';
import { nameof } from '@model/shared-methods/shared-methods';
import { getMetaItemValue } from '@mt-ng2/common-functions';
import { DynamicField, DynamicFieldType, DynamicFieldTypes, DynamicLabel, IDynamicField, IDynamicFieldType, InputTypes, noZeroRequiredValidator, SelectInputTypes } from '@mt-ng2/dynamic-form';
import { CustomerProposalDynamicControls, ICustomerProposalDynamicControlsParameters } from '../form-controls/customer-proposal.form-controls';
import { ICustomerProposal } from '../interfaces/customer-proposal';
import { ICustomerProposalDynamicControlsParametersPartial } from './customer-proposal-dynamic-controls-parameters';

export class CustomerProposalDynamicControlsPartial extends CustomerProposalDynamicControls {
    constructor(customerProposal?: ICustomerProposal, additionalParameters?: ICustomerProposalDynamicControlsParametersPartial) {
        super(customerProposal, additionalParameters);

        const phoneType = new DynamicFieldType({
            fieldType: DynamicFieldTypes.Input,
            inputType: InputTypes.Phone,
        });

        const selectType = new DynamicFieldType({
            fieldType: DynamicFieldTypes.Select,
            inputType: SelectInputTypes.Dropdown,
        });

        // FORM OVERRIDES //

        (<DynamicField>this.Form.Name).labelHtml = '<label>Proposal Name</label>';
        (<DynamicField>this.Form.DateOfProposal).labelHtml = '<label>Date of Proposal</label>';

        (<DynamicField>this.Form.PrimaryPhone).type = phoneType;
        (<DynamicField>this.Form.AltPhone).type = phoneType;
        (<DynamicField>this.Form.CustomerLocationId).type = selectType;
        (<DynamicField>this.Form.CustomerLocationId).options = additionalParameters.customerLocationAddresses;
        (<DynamicField>this.Form.CustomerLocationId).labelHtml = '<label>Customer Primary Address</label>';

        (<DynamicField>this.Form.JobLocationId).type = selectType;
        (<DynamicField>this.Form.JobLocationId).options = additionalParameters.jobLocationAddresses;
        (<DynamicField>this.Form.JobLocationId).labelHtml = '<label>Job Address</label>';

        this.Form.EmailAddress = new DynamicField({
            formGroup: this.formGroup,
            label: 'Email',
            name: 'EmailAddress',
            options: null,
            type: new DynamicFieldType({
                fieldType: DynamicFieldTypes.Input,
                inputType: InputTypes.Textbox,
            }),
            validation: [Validators.required, Validators.email],
            validators: { 'required': true },
            value: customerProposal && customerProposal.EmailAddress || null,
        });

        this.Form.SalesPersonId = new DynamicField({
            formGroup: this.formGroup,
            label: 'Sales Person',
            name: 'SalesPersonId',
            options: additionalParameters.users,
            type: new DynamicFieldType({
                fieldType: DynamicFieldTypes.Select,
                inputType: SelectInputTypes.Dropdown,
                scale: null,
            }),
            validation: [noZeroRequiredValidator],
            validators: { 'required': true },
            value: customerProposal && customerProposal.SalesPersonId || null,
        });

        this.Form.OfficeId = new DynamicField({
            formGroup: this.formGroup,
            label: 'Location Manager',
            name: 'OfficeId',
            options: additionalParameters.locationManagers,
            type: new DynamicFieldType({
                fieldType: DynamicFieldTypes.Select,
                inputType: SelectInputTypes.Dropdown,
                scale: null,
            }),
            validation: [noZeroRequiredValidator],
            validators: { 'required': true },
            value: customerProposal && customerProposal.OfficeId || null,
        });

        // VIEW OVERRIDES //

        this.View.SalesPersonId = new DynamicLabel({
			    label: 'Customer',
			    value: getMetaItemValue(additionalParameters.users as unknown as IMetaItem[], customerProposal && customerProposal.hasOwnProperty('SalesPersonId') && customerProposal.SalesPersonId !== null ? customerProposal.SalesPersonId : null) ?? "",
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Select,
			        inputType: null,
			        scale: null,
			    })
			}
        );
    }
}
