import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { StaticMetaItemService } from '@mt-ng2/base-service';
import { IElectricalJobDifficulty } from '../../../model/interfaces/electrical-job-difficulty';

@Injectable({
    providedIn: 'root',
})
export class ElectricalJobDifficultyService extends StaticMetaItemService<IElectricalJobDifficulty> {
    constructor(public http: HttpClient) {
        super('ElectricalJobDifficultyService', 'JobDifficulty', 'JobDifficultyIds', '/electrical-job-difficulties', http);
    }
}
