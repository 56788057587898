import { Injectable } from '@angular/core';
import { BaseService } from '@mt-ng2/base-service';

import { HttpClient } from '@angular/common/http';
import { IProposalLoan } from '@model/interfaces/proposal-loan';

@Injectable({ providedIn: 'root' })
export class ProposalLoanService extends BaseService<IProposalLoan> {

    constructor(public http: HttpClient) {
        super('/proposal-loans', http);
    }

}
