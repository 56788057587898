<h2 mtSetTitle>Estimator Settings</h2>
<div>
    <div class="row">
        <div class="col-md-6">
            <manufacturer-meta-item-managed-list
                [service]="manufacturerService"
                [dynamicForm]="manufacturerDynamicForm"
                [canAdd]="canAdd"
                [canRemove]="canRemove"
                [canEdit]="canEdit"
                [sortable]="!canEdit ? false : undefined"
            ></manufacturer-meta-item-managed-list>
        </div>
        <div class="col-md-4" *ngIf="materialsManufacturer">
            <manufacturer [manufacturer]="materialsManufacturer" (onUpdate)="updateManufacturer($event)"></manufacturer><br />
        </div>
    </div>
    <br />
    <div class="row">
        <div class="col-md-6">
            <mt-meta-item-managed-list
                [service]="roomService"
                [dynamicForm]="roomDynamicForm"
                [canAdd]="canAdd"
                [canRemove]="canRemove"
                [canEdit]="canEdit"
                [sortable]="!canEdit ? false : undefined"
            ></mt-meta-item-managed-list>
        </div>
        <div class="col-md-4" *ngIf="mitsubishiManufacturer">
            <manufacturer [manufacturer]="mitsubishiManufacturer" (onUpdate)="updateManufacturer($event)"></manufacturer>
        </div>
    </div>
    <br />
    <div class="row">
        <warranty-settings></warranty-settings>
        <div class="col-md-4" *ngIf="adminFee">
            <div class="miles-card padded">
                <h4>Admin Settings</h4>
                <div class="container-fluid nopadding">
                    <div class="row-fluid">
                        <div class="nopadding">
                            <label style="padding-left: 12px">Admin Fee</label>
                        </div>
                    </div>
                </div>
                <div>
                    <div>
                        <div class="container-fluid nopadding">
                            <div class="row-fluid">
                                <div class="nopadding">
                                    <div class="input-group">
                                        <mt-numeric
                                            [type]="numericControlTypes.Percentage"
                                            [scale]="2"
                                            [(ngModel)]="adminFee.Value"
                                            (ngModelChange)="onAdminFeeChange()"
                                        ></mt-numeric>
                                        <span class="input-group-addon">%</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <br />
                <div class="container-fluid nopadding">
                    <div class="row-fluid">
                        <div class="nopadding">
                            <label style="padding-left: 12px">Boilerplate Job Description</label>
                        </div>
                    </div>
                </div>
                <div>
                    <div>
                        <div class="container-fluid nopadding">
                            <div class="row-fluid">
                                <div class="nopadding">
                                    <div class="form-group">
                                        <textarea class="form-control" [(ngModel)]="boilerplateJobDescription.Value"></textarea>
                                    </div>
                                </div>
                            </div>
                            <br />
                            <button type="submit" class="btn btn-primary" (click)="updateAdminSettings()">Save</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <br />
    <div class="row">
        <div class="col-md-6">
            <mt-meta-item-managed-list
                [service]="categoryService"
                [dynamicForm]="categoryDynamicForm"
                [canAdd]="canAdd"
                [canRemove]="canRemove"
                [canEdit]="canEdit"
                [sortable]="!canEdit ? false : undefined"
            ></mt-meta-item-managed-list>
        </div>
    </div>
</div>
<br />
