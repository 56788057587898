import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { StaticMetaItemService } from '@mt-ng2/base-service';
import { IWallConstructionType } from '../../../model/interfaces/wall-construction-type';

@Injectable({
    providedIn: 'root',
})
export class WallConstructionTypeService extends StaticMetaItemService<IWallConstructionType> {
    constructor(public http: HttpClient) {
        super('WallConstructionType', 'WallConstructionType', 'WallConstructionTypeIds', '/wall-construction-types', http);
    }

}
