/* eslint-disable no-console */
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ClaimTypes } from '@model/ClaimTypes';
import { IProposalPricingOverrideParams } from '@model/interfaces/custom/proposal-pricing-override-params';
import { IProposalPricingOverrideUpdateEvent } from '@model/interfaces/custom/proposal-pricing-override-update-event';
import { ICustomerProposal } from '@model/interfaces/customer-proposal';
import { IManufacturer } from '@model/interfaces/manufacturer';
import { IProposalLoan } from '@model/interfaces/proposal-loan';
import { IProposalPricing } from '@model/interfaces/proposal-pricing';
import { ClaimsService, ClaimValues } from '@mt-ng2/auth-module';
import { IModalWrapperApi } from '@mt-ng2/modal-module';
import { CustomerLocationService } from '../../customers/services/customer-location.service';
import { CustomerProposalService } from '../services/customer-proposal.service';
import { ManufacturerService } from '../services/manufacturer.service';
import { ProposalPricingService } from '../services/proposal-pricing.service';
import { Subject, forkJoin } from 'rxjs';
import { IProposalPricingAdminCheckoutItem } from '@model/interfaces/proposal-pricing-admin-checkout-item';

@Component({
    selector: 'proposal-pricing-detail',
    styles: [`
        .action-buttons {
            margin-top: 15px;
            display: flex;
            align-items: center;
            justify-content: space-evenly;
        }
        .table td {
            color: black;
        }
        .header-cell {
            background: #80808061;
        }
        .total-cell {
            background: #ffd40099;
        }
        .btn-action {
            padding: 20px;
            font-size: 24px;
        }
        .table-project-cost {
            border-bottom: 0;
            border-right: 0;
            border-left: 0;
        }
        .no-border {
            border: none;
        }
    `],
    templateUrl: 'proposal-pricing-detail.component.html',
})

export class ProposalPricingDetailComponent implements OnInit {
    proposalPricing: IProposalPricing;
    proposalPricingOverrideParams: IProposalPricingOverrideParams = {
        CustomerDepositAmountOverride: null,
        ElectricianFeeOverride: null,
        MarkupPercentageOverride: null,
        MaterialsMarkupPercentageOverride: null,
        MiscExpensesOverride: null,
        SumOfLaborBasedOnZoneOverride: null,
    };
    manufacturers: IManufacturer[];
    loanCalculatorModalApi: IModalWrapperApi;
    proposalIsLocked = false;
    proposalLoan: IProposalLoan;
    customerProposal: ICustomerProposal;
    customerLocationContainsDynamicPermitZip = false;
    canViewAdminCheckout: boolean;
    adminCheckoutLineItems: IProposalPricingAdminCheckoutItem[];

    overrideUpdateSubject: Subject<void> = new Subject<void>();

    MATERIALS_MANUFACTURER = 1;

    constructor(
        private route: ActivatedRoute,
        private proposalPricingService: ProposalPricingService,
        private router: Router,
        private manufacturerService: ManufacturerService,
        private customerProposalService: CustomerProposalService,
        private claimsService: ClaimsService,
        private customerLocationService: CustomerLocationService,
    ) { }

    ngOnInit(): void {
        this.canViewAdminCheckout = this.claimsService.hasClaim(ClaimTypes.CustomerProposals_CanViewAdminCheckout, [ClaimValues.FullAccess]);
        const proposalPricingId = this.getIdFromRoute(this.route, 'proposalPricingId');
        if (proposalPricingId) {
            forkJoin([
                this.proposalPricingService.getById(proposalPricingId), 
                this.manufacturerService.getAll(),
                this.proposalPricingService.getAdminCheckout(proposalPricingId),
            ]).subscribe(([pricing, manufacturers, adminCheckoutLineItems]) => {
                this.proposalPricing = pricing;
                this.customerProposal = this.proposalPricing.CustomerProposal;
                this.proposalLoan = this.proposalPricing.CustomerProposal.ProposalLoans[0];
                if (!this.customerProposalService.proposalIsInProgress(this.customerProposal)) {
                    this.proposalIsLocked = true;
                }
                this.assignOverrideValues(pricing);
                const customerLocationId = this.customerProposal?.CustomerLocationId;
                if (customerLocationId > 0) {
                    this.customerLocationService.CheckAddressContainsDynamicPermitZip(customerLocationId).subscribe((result) => {
                        this.customerLocationContainsDynamicPermitZip = result;
                    })
                }
                
                this.manufacturers = manufacturers.filter(m => this.canOverride(m.Id));
                this.adminCheckoutLineItems = adminCheckoutLineItems;
            });      
        }
    }

    canOverride(manufacturerId: number): boolean {
        return manufacturerId !== this.MATERIALS_MANUFACTURER && this.proposalPricing.ManufacturerId === manufacturerId;
    }

    private assignOverrideValues(pricing: IProposalPricing): void {
        for (const key in this.proposalPricingOverrideParams) {
            if (Object.prototype.hasOwnProperty.call(pricing, key)) {
                this.proposalPricingOverrideParams[key] = pricing[key];
            }
        }
    }

    getIdFromRoute(route: ActivatedRoute, param: string): number {
        const id = route.snapshot.paramMap.get(param);
        const parsedInt = parseInt(id, 10);
        return isNaN(parsedInt) ? null : parsedInt;
    }

    onOverrideUpdate(event: IProposalPricingOverrideUpdateEvent): void {
        if (event.Value === 0) {
            event.Value = null;
        }
        this.proposalPricingOverrideParams[event.Key] = event.Value;
        this.generateProposalPricingsWithOverrides();
    }

    navigateToPricingPage(): void {
        void this.router.navigate([`/estimator/proposal/${this.proposalPricing.CustomerProposalId}/pricing/${this.proposalPricing.Id}/page`]);
    }

    navigateToEstimator(): void {
        void this.router.navigate([`/estimator/customer/${this.proposalPricing.CustomerProposal.CustomerId}/proposal/${this.proposalPricing.CustomerProposalId}`]);
    }

    navigateToCheckoutPage(): void {
        void this.router.navigate([`/estimator/proposal/${this.proposalPricing.CustomerProposal.CustomerId}/pricing/${this.proposalPricing.Id}/checkout`]);
    }

    generateProposalPricingsWithOverrides(): void {
        this.proposalPricingService.generateProposalPricingsWithOverrides(this.proposalPricing.Id, this.proposalPricingOverrideParams)
            .subscribe((pricingId) => {
                this.proposalPricingService.getById(pricingId)
                    .subscribe((pricing) => {
                        this.proposalPricing = pricing;
                        this.overrideUpdateSubject.next(); 
                    });
            });
    }
    

    displayLoanCalculator(): void {
        this.loanCalculatorModalApi.show();
    }

    calculateWarrantySubpanelPermitsCost(): number {
        if (!this.customerLocationContainsDynamicPermitZip) {
            return this.proposalPricing.WarrantyTotal + this.proposalPricing.PermitCost;
        }

        // Add subpanel permit cost
        let warrantySubpanelPermitsCost = this.proposalPricing.PermitCost;

        // Add warranty cost
        if (this.proposalPricing.CustomerProposal.IncludeWarranty) {
            warrantySubpanelPermitsCost += this.proposalPricing.WarrantyTotal;
        }

        return warrantySubpanelPermitsCost;
    }

    calculateMiscCharge(): number {
        let total = this.calculateWarrantySubpanelPermitsCost();
        total += this.proposalPricing.MiscExpensesOverride || this.proposalPricing.MiscExpenses;
        total += this.proposalPricing.ReferralFeeAmount;
        total += this.proposalPricing.FuelCharge;
        total += this.proposalPricing.MultiRefrigerantCharge;
        total += this.proposalPricing.RoughInCharge;
        total += this.proposalPricing.BoomTruck;
        return total;
    }
}
