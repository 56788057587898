import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { BaseService } from '@mt-ng2/base-service';

import { IProposalOutdoorHeatPump } from '@model/interfaces/proposal-outdoor-heat-pump';
import { catchError, Observable, throwError } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class ProposalOutdoorHeatPumpService extends BaseService<IProposalOutdoorHeatPump> {

    constructor(public http: HttpClient) {
        super('/proposal-outdoor-heat-pumps', http);
    }

    updateProposalOutdoorHeatPumps(proposalId: number, pumps: IProposalOutdoorHeatPump[]): Observable<void> {
        return this.http.put<void>(`/proposal-outdoor-heat-pumps/proposal/${proposalId}/update`, {
            OutdoorHeatPumps: pumps
        }).pipe(
            catchError((error: HttpErrorResponse) => {
              let errorMessage = 'An error occurred while updating the proposal.';
              if (error.status === 400 && error.error?.message) {
                errorMessage = error.error.message;
              }
              return throwError(errorMessage);
            })
          );
    }

    getByProposalId(proposalId: number): Observable<IProposalOutdoorHeatPump[]> {
        return this.http.get<IProposalOutdoorHeatPump[]>(`/proposal-outdoor-heat-pumps/proposal/${proposalId}`);
    }

    uploadHeatPumpImage(imageBase64: string): Observable<string> {
        const byteString = atob(imageBase64.split(',')[1]);
        const ab = new ArrayBuffer(byteString.length);
        const ia = new Uint8Array(ab);
        for (let i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }
        const bb = new Blob([ab]);
        const formData: FormData = new FormData();
        formData.append('file', bb);
        return this.http.post<string>(`/proposal-outdoor-heat-pumps/image`, formData);
    }
}
