import { ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';
import { markAllFormFieldsAsTouched } from '@mt-ng2/common-functions';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { ProposalLoanDynamicControlsPartial } from '@model/partials/proposal-loan.form-controls';
import { IProposalLoan } from '@model/interfaces/proposal-loan';
import { debounceTime } from 'rxjs/operators';
import { ProposalLoanService } from '../../services/proposal-loan.service';
import { LoanCalculations } from '@common/services/loan-calculations.service';
import { IExpandableObject } from '@model/expandable-object';

@Component({
    selector: 'proposal-loan-calculator',
    styles: [`
        table {
            table-layout: fixed;
            font-size: 15px;
            color: black;
        }
        td {
            height: 60px !important;
            vertical-align: middle !important;
        }
        .label-cell {
            background: #80808061;
        }
        .mp-cell {
            background: #5ebd08bf;
        }
    `],
    templateUrl: './proposal-loan-calculator.component.html',
})
export class ProposalLoanCalculatorComponent implements OnInit, OnChanges {
    @Input() customerProposalId: number;
    @Input() orderTotal: number;
    @Output() onDoneEditing: EventEmitter<void> = new EventEmitter<void>();
    @Input() proposalLoan: IProposalLoan;
    // abstract controls
    abstractProposalLoanControls: IExpandableObject;

    proposalLoanForm: UntypedFormGroup;
    doubleClickIsDisabled = false;
    formCreated = false;
    subscriptions: Subscription = new Subscription();
    currentFormValue: IProposalLoan;
    monthlyPayment: number;
    loanCalculations = LoanCalculations;

    constructor(
        private fb: UntypedFormBuilder,
        private cdr: ChangeDetectorRef,
        private notificationsService: NotificationsService,
        private proposalLoanService: ProposalLoanService,
    ) { }

    ngOnInit(): void {
        this.createForm();
        this.subscriptions.add(
            this.proposalLoanForm.valueChanges.pipe(debounceTime(100)).subscribe((value) => {
                this.currentFormValue = value.ProposalLoan;
                this.currentFormValue.OrderTotal = this.orderTotal;
                this.monthlyPayment = this.getMonthlyPayment(this.currentFormValue);
            }),
        );
    }

    ngOnChanges(): void {
        if (this.currentFormValue) {
            this.currentFormValue.OrderTotal = this.orderTotal;
            this.monthlyPayment = this.getMonthlyPayment(this.currentFormValue);
        }
    }

    createForm(): void {
        this.getControls();
        this.proposalLoanForm = this.assignFormGroups();
        this.formCreated = true;
        this.cdr.detectChanges();
        this.currentFormValue = this.proposalLoanForm.value.ProposalLoan;
        this.currentFormValue.OrderTotal = this.orderTotal;
        this.monthlyPayment = this.getMonthlyPayment(this.currentFormValue);
    }

    getControls(): void {
        this.abstractProposalLoanControls = new ProposalLoanDynamicControlsPartial(
            this.proposalLoan,
            {
                formGroup: 'ProposalLoan',
                proposals: null,
            },
        ).Form;
    }

    assignFormGroups(): UntypedFormGroup {
        return this.fb.group({
            ProposalLoan: this.fb.group({}),
        });
    }

    getMonthlyPayment(proposalLoan: IProposalLoan): number {
        return this.loanCalculations.getMonthlyPayment(proposalLoan);
    }

    formSubmitted(): void {
        if (this.proposalLoanForm.valid) {
            const proposalLoan: IProposalLoan = Object.assign({}, this.currentFormValue);
            proposalLoan.OrderTotal = this.orderTotal;
            proposalLoan.ProposalId = this.customerProposalId;
            proposalLoan.MonthlyPayment = this.getMonthlyPayment(proposalLoan);
            proposalLoan.Id = this.proposalLoan.Id;
            this.proposalLoanService.update(proposalLoan)                .subscribe(() => {
                    this.notificationsService.success('Successfully created Loan');
                    this.onDoneEditing.emit();
                });
        } else {
            markAllFormFieldsAsTouched(this.proposalLoanForm);
            this.error();
        }
    }

    onCancelClick(): void {
        this.onDoneEditing.emit();
    }

    error(): void {
        this.notificationsService.error('Save failed.  Please check the form and try again.');
    }

}
