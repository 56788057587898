<div>
    <div class="row">
        <div class="col-md-7">
            <h2>Admin Checkout</h2>
            <mt-route-back-button></mt-route-back-button>
        </div>
    </div>
</div>

<div class="miles-card padded">
    <div class="row">
        <div class="col-md-12">
            <table class="table table-bordered">
                <thead>
                    <tr>
                        <th>QTY</th>
                        <th>Unit Model</th>
                        <th>Description</th>
                        <th>Total</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let item of filterItemsWithoutTotal()">
                        <td>{{ item.Quantity }}</td>
                        <td>{{ item.Name }}</td>
                        <td>{{ item.Description }}</td>
                        <td>{{ item.Total | currency }}</td>
                    </tr>
                    <tr *ngIf="proposalPricing && !checkForMiscellaneousExpenses()">
                        <td>1</td>
                        <td>Miscellaneous Expenses</td>
                        <td></td>
                        <td>{{ proposalPricing.MiscExpensesOverride | currency }}</td>
                    </tr>
                </tbody>
                <thead>
                    <tr>
                        <th colspan="3"></th>
                        <th>{{ adminCheckoutTotal | currency }}</th>
                    </tr>
                </thead>
            </table>
        </div>
    </div>
</div>
