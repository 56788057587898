import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { BaseService } from '@mt-ng2/base-service';

import { IProposalAccessory } from '@model/interfaces/proposal-accessory';
import { catchError, Observable, throwError } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class ProposalAccessoryService extends BaseService<IProposalAccessory> {

    constructor(public http: HttpClient) {
        super('/proposal-acessories', http);
    }

    updateProposalAccessories(proposalId: number, accessories: IProposalAccessory[]): Observable<void> {
        return this.http.put<void>(`/proposal-accessories/proposal/${proposalId}/update`, accessories)
          .pipe(
            catchError((error: HttpErrorResponse) => {
              let errorMessage = 'An error occurred while updating the proposal.';
              if (error.status === 400 && error.error?.message) {
                errorMessage = error.error.message;
              }
              return throwError(errorMessage);
            })
          );
      }

    getByProposalId(proposalId: number): Observable<IProposalAccessory[]> {
        return this.http.get<IProposalAccessory[]>(`/proposal-accessories/proposal/${proposalId}`);
    }
}
