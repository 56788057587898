import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { LocalStorageService } from '@common/services/local-storage.service';
import { IOutdoorHeatPumpLineItem } from '@model/interfaces/custom/outdoor-heat-pump-line-item';
import { IOutdoorHeatPumpLineItemUpdateEvent } from '@model/interfaces/custom/outdoor-heat-pump-line-item-update-event';
import { IProposalOutdoorHeatPump } from '@model/interfaces/proposal-outdoor-heat-pump';
import { ModalService } from '@mt-ng2/modal-module';
import { CustomerProposalInterfaceStateService } from 'admin-portal/estimator/services/customer-proposal-interface-state.service';
import { CustomerProposalService } from 'admin-portal/estimator/services/customer-proposal.service';
import { HeatPumpFormStateService } from 'admin-portal/estimator/services/heat-pump-form-state.service';
import { HeatPumpService } from 'admin-portal/estimator/services/heat-pump.service';
import { ProposalOutdoorHeatPumpService } from 'admin-portal/estimator/services/proposal-outdoor-heat-pump.service';
import { PartService } from '../../../parts/services/part.service';
import { HeatPumpsComponent } from '../heat-pumps.component';

@Component({
    selector: 'outdoor-heat-pumps',
    styles: [
        `
            .table {
                color: black !important;
            }
            .btn-fab-md {
                float: right;
            }
        `,
    ],
    templateUrl: 'outdoor-heat-pumps.component.html',
})
export class OutdoorHeatPumpsComponent extends HeatPumpsComponent implements OnInit {
    @Input() proposalIsApproved: boolean;
    @Input() proposalId: number;
    selectedOutdoorHeatPumps: IOutdoorHeatPumpLineItem[] = [];
    selectedOutdoorHeatPumpsOnInit: IOutdoorHeatPumpLineItem[] = [];
    manufacturerId: number;
    categoryId: number;

    constructor(
        private partService: PartService,
        private proposalStateService: CustomerProposalInterfaceStateService,
        private proposalOutdoorHeatPumpService: ProposalOutdoorHeatPumpService,
        private heatPumpStateService: HeatPumpFormStateService,
        private route: ActivatedRoute,
        private localStorageService: LocalStorageService,
        private modalService: ModalService,
        private customerProposalService: CustomerProposalService,
        private heatPumpService: HeatPumpService,
    ) {
        super(proposalStateService, heatPumpStateService, localStorageService, modalService, heatPumpService);
    }

    ngOnInit(): void {
        this.customerProposalId = this.getIdFromRoute(this.route, 'customerProposalId');
        if (this.customerProposalId) {
            this.getOutdoorHeatPumpsByProposalId(this.customerProposalId);
        }
        this.initializeSubscriptions();
    }

    getOutdoorHeatPumpsByProposalId(proposalId: number): void {
        this.proposalOutdoorHeatPumpService.getByProposalId(proposalId).subscribe((pumps) => {
            if (pumps.length > 0) {
                this.heatPumpStateService.manufacturerSelection$.next({
                    ManufacturerId: pumps[0].Part.ManufacturerId,
                    RequiresChange: false,
                });
                this.mapOutdoorPumps(pumps);
                this.outputSelectedOutdoorUnitIds();

                // Use JSON.parse to save by value instead of reference https://stackoverflow.com/a/18359187.
                this.selectedOutdoorHeatPumpsOnInit = JSON.parse(JSON.stringify(this.selectedOutdoorHeatPumps));
            }
        });
    }

    private initializeSubscriptions(): void {
        this.subscriptions.add(
            this.proposalStateService.heatPumpConfigurationStatus$.subscribe((value) => {
                this.heatPumpConfigurationStatus = value;
            }),
        );
        this.subscriptions.add(
            this.heatPumpStateService.manufacturerSelection$.subscribe((value) => {
                if (value && value.ManufacturerId) {
                    if (this.manufacturerId !== value.ManufacturerId) {
                        this.manufacturerId = value.ManufacturerId;
                        this.partService.getAvailableOutdoorUnitsByManufacturer(value.ManufacturerId, this.customerProposalId).subscribe((parts) => {
                            this.parts = parts;
                        });
                    }
                }
                if (value && value.RequiresChange) {
                    this.selectedOutdoorHeatPumps = [];
                    this.outputSelectedOutdoorUnitIds();
                }
            }),
        );
        this.subscriptions.add(
            this.heatPumpStateService.categorySelection$.subscribe((value) => {
                this.categoryId = value;
            }),
        );
    }

    private mapOutdoorPumps(pumps: IProposalOutdoorHeatPump[]): void {
        this.selectedOutdoorHeatPumps = pumps.map((p) => {
            return {
                Id: p.Id,
                Image: p.Part.Image,
                MaxIDU: p.Part.MaxIduConnections * p.Quantity,
                MinIDU: p.Part.MinIduConnections * p.Quantity,
                PartId: p.PartId,
                Quantity: p.Quantity,
                RequiresBranchBox: p.Part?.RequiresBranchBox,
            };
        });
        this.updateTotalIDUValues();
        this.updateAmountOfRequiredBranchBoxes();
        this.updateHeatPumpConfigurationStatus();
        this.updateOutdoorHeatPumps();
    }

    addOutdoorHeatPump(): void {
        this.selectedOutdoorHeatPumps.push({
            Id: 0,
            MaxIDU: 0,
            MinIDU: 0,
            PartId: null,
            Quantity: 0,
            RequiresBranchBox: false,
        });
    }

    onPumpsUpdate(updatedValue: IOutdoorHeatPumpLineItemUpdateEvent): void {
        this.selectedOutdoorHeatPumps = updatedValue.SelectedPumps;
        this.outputSelectedOutdoorUnitIds();
        this.updateTotalIDUValues();
        this.updateAmountOfRequiredBranchBoxes();
        this.updateHeatPumpConfigurationStatus();
        this.updateOutdoorHeatPumps();
        if (updatedValue.RequiresBranchBoxPrompt) {
            this.validateBranchBoxPrompt();
        }
        if (!updatedValue.SelectedPumps) {
            return;
        }
        const formIsDirty = this.checkIfFormIsDirty(updatedValue.SelectedPumps, this.selectedOutdoorHeatPumpsOnInit);
        this.customerProposalService.setFormIsDirty(formIsDirty);
    }

    private updateTotalIDUValues(): void {
        this.heatPumpConfigurationStatus.MinIDU = this.getTotalValue('MinIDU');
        this.heatPumpConfigurationStatus.MaxIDU = this.getTotalValue('MaxIDU');
    }

    private updateAmountOfRequiredBranchBoxes(): void {
        if (!this.selectedOutdoorHeatPumps) {
            return;
        }
        const requiredBranchBoxes = this.selectedOutdoorHeatPumps
            .filter((item) => item.RequiresBranchBox)
            .reduce((sum, current) => sum + current.Quantity, 0);
        this.heatPumpConfigurationStatus.RequiredBranchBoxes = requiredBranchBoxes;
    }

    outputSelectedOutdoorUnitIds(): void {
        const outdoorUnitIds = this.selectedOutdoorHeatPumps
            .map((p) => {
                return p.PartId;
            })
            .filter((ou) => !!ou);
        this.heatPumpStateService.compatibleOutdoorUnitIds$.next(outdoorUnitIds);
    }

    private updateOutdoorHeatPumps(): void {
        const pumps: IProposalOutdoorHeatPump[] = this.selectedOutdoorHeatPumps.map((p) => {
            return {
                Distance: null,
                Id: p.Id,
                PartId: p.PartId,
                ProposalId: this.customerProposalId,
                Quantity: p.Quantity,
            };
        });
        this.heatPumpStateService.proposalOutdoorHeatPumps$.next(pumps);
    }

    getTotalValue(key: string): number {
        let value = 0;
        this.selectedOutdoorHeatPumps.map((p) => {
            value += p[key];
        });
        return value;
    }

    private checkIfFormIsDirty(proposedPumps: IOutdoorHeatPumpLineItem[], currentPumps: IOutdoorHeatPumpLineItem[]): boolean {
        if (currentPumps.length !== proposedPumps.length) {
            return true;
        }
        for (let i = 0; i < currentPumps.length; i++) {
            const currentPump = currentPumps[i];
            const proposedPump = proposedPumps[i];
            const pumpsAreDifferent: boolean = this.checkPumpsForDifferences(proposedPump, currentPump);
            if (pumpsAreDifferent) {
                return true;
            }
        }
        return false;
    }

    private checkPumpsForDifferences(proposedPump: IOutdoorHeatPumpLineItem, currentPump: IOutdoorHeatPumpLineItem): boolean {
        for (const key in currentPump) {
            if (proposedPump.hasOwnProperty.call(currentPump, key)) {
                const proposedPumpField = proposedPump[key];
                const currentPumpField = currentPump[key];
                if (proposedPumpField !== currentPumpField) {
                    return true;
                }
            }
        }
        return false;
    }
}
