
import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { markAllFormFieldsAsTouched } from '@mt-ng2/common-functions';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { ICustomerProposal } from '@model/interfaces/customer-proposal';
import { CustomerProposalDynamicControlsPartial } from '@model/partials/customer-proposal.form-controls';
import { CustomerProposalService } from '../../services/customer-proposal.service';
import { CustomerProposalStatuses } from '@model/CustomerProposalStatuses';
import { IExpandableObject } from '@model/expandable-object';

@Component({
    selector: 'proposal-job-description',
    styles: [`
        .btn-danger {
            margin-top: 10px;
        }
    `],
    templateUrl: 'proposal-job-description.component.html',
})

export class ProposalJobDescriptionComponent implements OnInit {
    @Input() customerProposal: ICustomerProposal;

    abstractCustomerProposalControls: IExpandableObject;
    customerProposalForm: UntypedFormGroup;
    formCreated: boolean;
    doubleClickIsDisabled = false;
    proposalIsApproved = false;

    constructor(
        private fb: UntypedFormBuilder,
        private cdr: ChangeDetectorRef,
        private notificationsService: NotificationsService,
        private customerProposalService: CustomerProposalService,
    ) { }

    ngOnInit(): void {

        this.createForm();
        if (!this.customerProposalService.proposalIsInProgress(this.customerProposal)) {
            this.customerProposalForm.disable();
            this.proposalIsApproved = true;
        }
    }

    createForm(): void {
        this.getControls();
        this.customerProposalForm = this.assignFormGroups();
        this.formCreated = true;
        this.cdr.detectChanges();
    }

    assignFormGroups(): UntypedFormGroup {
        return this.fb.group({
            CustomerProposal: this.fb.group({}),
        });
    }

    getControls(): void {
        this.abstractCustomerProposalControls = new CustomerProposalDynamicControlsPartial(
            this.customerProposal,
            {
                customerLocationAddresses: [],
                customerLocations: [],
                customers: [],
                jobLocationAddresses: [],
                jobLocations: [],
                locationManagers: [],
                offices: [],
                states: [],
                statuses: [],
                users: [],
                formGroup: 'CustomerProposal'
            }
        ).Form;
    }

    formSubmitted(): void {
        if (this.customerProposalForm.valid) {
            this.assignFormValues(this.customerProposal, this.customerProposalForm.value.CustomerProposal as ICustomerProposal);
            this.saveCustomerProposal();
        } else {
            markAllFormFieldsAsTouched(this.customerProposalForm);
            this.error();
        }
    }

    assignFormValues(customerProposal: ICustomerProposal, currentFormValue: ICustomerProposal): void {
        for (const key in currentFormValue) {
            if (customerProposal.hasOwnProperty.call(currentFormValue, key)) {
                customerProposal[key] = currentFormValue[key];
            }
        }
    }

    saveCustomerProposal(): void {
        this.customerProposalService.update(this.customerProposal)            .subscribe(() => {
                this.customerProposalService.setFormIsDirty(false);
                this.success();
            });
    }

    error(): void {
        this.notificationsService.error('Save failed.  Please check the form and try again.');
    }

    success(): void {
        this.notificationsService.success('Customer Proposal saved successfully.');
    }

    isAwaitingSalesOrder(): boolean {
        return this.customerProposal && this.customerProposal.StatusId === CustomerProposalStatuses.AwaitingSalesOrder;
    }
}
