import { Component, OnInit } from '@angular/core';
import { ResidentialWarrantySettingService } from '../../services/residential-warranty-setting.service';
import { CommercialWarrantySettingService } from '../../services/commercial-warranty-setting.service';
import { IWarrantySetting } from '@model/interfaces/warranty-setting';
import { NumericControlTypes } from '@mt-ng2/numeric-control';
import { WarrantySettings } from '@model/WarrantySettings';
import { NotificationsService } from '@mt-ng2/notifications-module';

@Component({
    selector: 'warranty-settings',
    styles: [`
        .full {
            width: 100%;
        }
    `],
    templateUrl: 'warranty-settings.component.html',
})

export class WarrantySettingsComponent implements OnInit {
    residentialWarrantySettings: IWarrantySetting[];
    commercialWarrantySettings: IWarrantySetting[];
    numericControlTypes = NumericControlTypes;
    warrantySettingMappings = [
        {
            AddOn: 'Currency',
            Id: WarrantySettings.CommissionPerUnit,
            Scale: 2,
            Type: this.numericControlTypes.Currency,
        },
        {
            AddOn: 'Currency',
            Id: WarrantySettings.FlatPrice,
            Scale: 2,
            Type: this.numericControlTypes.Currency,
        },
        {
            AddOn: 'Currency',
            Id: WarrantySettings.PricePerUnit,
            Scale: 2,
            Type: this.numericControlTypes.Currency,
        },
    ];

    constructor(
        private residentialWarrantySettingService: ResidentialWarrantySettingService,
        private commercialWarrantySettingService: CommercialWarrantySettingService,
        private notificationsService: NotificationsService,
    ) { }

    ngOnInit(): void {
        this.residentialWarrantySettingService.getAll().subscribe((settings) => {
            this.residentialWarrantySettings = settings;
        });

        this.commercialWarrantySettingService.getAll().subscribe((settings) => {
            this.commercialWarrantySettings = settings;
        });
    }

    getInputType(id: number): NumericControlTypes {
        return this.warrantySettingMappings.find((s) => s.Id === id).Type;
    }

    getInputScale(id: number): NumericControlTypes {
        return this.warrantySettingMappings.find((s) => s.Id === id).Scale;
    }

    hasCurrencyAddOn(id: number): boolean {
        return this.warrantySettingMappings.find((s) => s.Id === id).AddOn === 'Currency';
    }

    saveResidentialWarrantySettings(): void {
        this.residentialWarrantySettingService.updateItems(this.residentialWarrantySettings).subscribe(() => {
            this.notificationsService.success('Successfully updated Residential Warranty Settings');
        });
    }

    saveCommercialWarrantySettings(): void {
        this.commercialWarrantySettingService.updateItems(this.commercialWarrantySettings).subscribe(() => {
            this.notificationsService.success('Successfully updated Commercial Warranty Settings');
        });
    }
}
