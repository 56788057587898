<div class="col-md-4">
    <div class="miles-card padded" *ngIf="residentialWarrantySettings">
        <h4>Residential Warranty Settings</h4>
        <ng-container *ngFor="let setting of residentialWarrantySettings">
            <div class="container-fluid nopadding">
                <div class="row-fluid">
                    <div class="nopadding">
                        <label style="padding-left: 12px">{{ setting.Name }}</label>
                    </div>
                </div>
            </div>
            <div>
                <div>
                    <div class="container-fluid nopadding">
                        <div class="row-fluid">
                            <div class="nopadding">
                                <div class="input-group" [ngClass]="{ full: !hasCurrencyAddOn(setting.Id) }">
                                    <span *ngIf="hasCurrencyAddOn(setting.Id)" class="input-group-addon">$</span>
                                    <mt-numeric
                                        [type]="getInputType(setting.Id)"
                                        [scale]="getInputScale(setting.Id)"
                                        [(ngModel)]="setting.Value"
                                    ></mt-numeric>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> </ng-container
        ><br />
        <button type="submit" class="btn btn-primary" (click)="saveResidentialWarrantySettings()">Save</button>
    </div>
</div>

<div class="col-md-4">
    <div class="miles-card padded" *ngIf="commercialWarrantySettings">
        <h4>Commercial Warranty Settings</h4>
        <ng-container *ngFor="let setting of commercialWarrantySettings">
            <div class="container-fluid nopadding">
                <div class="row-fluid">
                    <div class="nopadding">
                        <label style="padding-left: 12px">{{ setting.Name }}</label>
                    </div>
                </div>
            </div>
            <div>
                <div>
                    <div class="container-fluid nopadding">
                        <div class="row-fluid">
                            <div class="nopadding">
                                <div class="input-group" [ngClass]="{ full: !hasCurrencyAddOn(setting.Id) }">
                                    <span *ngIf="hasCurrencyAddOn(setting.Id)" class="input-group-addon">$</span>
                                    <mt-numeric
                                        [type]="getInputType(setting.Id)"
                                        [scale]="getInputScale(setting.Id)"
                                        [(ngModel)]="setting.Value"
                                    ></mt-numeric>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> </ng-container
        ><br />
        <button type="submit" class="btn btn-primary" (click)="saveCommercialWarrantySettings()">Save</button>
    </div>
</div>
