import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { StaticMetaItemService } from '@mt-ng2/base-service';
import { IElectricalDistanceFromOdu } from '../../../model/interfaces/electrical-distance-from-odu';

@Injectable({
    providedIn: 'root',
})
export class ElectricalDistanceFromOduService extends StaticMetaItemService<IElectricalDistanceFromOdu> {
    constructor(public http: HttpClient) {
        super('ElectricalDistanceFromOduService', 'ElectricalDistanceFromOdu', 'DistanceIds', '/electrical-distance-from-odu', http);
    }

}
