import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { MetaItemService } from '@mt-ng2/base-service';
import { ICommercialWarrantySetting } from '@model/interfaces/commercial-warranty-setting';

@Injectable({
    providedIn: 'root',
})
export class CommercialWarrantySettingService extends MetaItemService<ICommercialWarrantySetting> {
    constructor(public http: HttpClient) {
        super('CommercialWarrantySettingService', 'Setting', 'SettingIds', '/commercial-warranty-settings', http);
    }
}
