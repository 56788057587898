import { Injectable } from '@angular/core';
import { IManufacturerSelection } from '@model/interfaces/custom/manufacturer-selection';
import { IProposalAccessory } from '@model/interfaces/proposal-accessory';
import { IProposalIndoorHeatPump } from '@model/interfaces/proposal-indoor-heat-pump';
import { IProposalOutdoorHeatPump } from '@model/interfaces/proposal-outdoor-heat-pump';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class HeatPumpFormStateService {

    private _branchBoxPromptTimeout = 30;
    get branchBoxPromptTimeout(): number {
        return this._branchBoxPromptTimeout;
    }

    private _manufacturerSelection: BehaviorSubject<IManufacturerSelection> = new BehaviorSubject(null);
    public get manufacturerSelection$(): BehaviorSubject<IManufacturerSelection> {
        return this._manufacturerSelection;
    }

    private _compatibleOutdoorUnitIds: BehaviorSubject<number[]> = new BehaviorSubject([]);
    public get compatibleOutdoorUnitIds$(): BehaviorSubject<number[]> {
        return this._compatibleOutdoorUnitIds;
    }

    private _proposalOutdoorHeatPumps: BehaviorSubject<IProposalOutdoorHeatPump[]> = new BehaviorSubject<IProposalOutdoorHeatPump[]>([]);
    public get proposalOutdoorHeatPumps$(): BehaviorSubject<IProposalOutdoorHeatPump[]> {
        return this._proposalOutdoorHeatPumps;
    }

    private _proposalIndoorHeatPumps: BehaviorSubject<IProposalIndoorHeatPump[]> = new BehaviorSubject<IProposalIndoorHeatPump[]>([]);
    public get proposalIndoorHeatPumps$(): BehaviorSubject<IProposalIndoorHeatPump[]> {
        return this._proposalIndoorHeatPumps;
    }

    private _accessories: BehaviorSubject<IProposalAccessory[]> = new BehaviorSubject<IProposalAccessory[]>([]);
    public get accessories$(): BehaviorSubject<IProposalAccessory[]> {
        return this._accessories;
    }

    private _categorySelection = new BehaviorSubject<number>(0);
    get categorySelection$(): BehaviorSubject<number> {
        return this._categorySelection;
    }

    constructor() { }
}
