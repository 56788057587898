import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { IBtuCalculationLineItem } from '@model/interfaces/custom/btu-calculation-line-item';
import { IProposalBtuCalculation } from '@model/interfaces/proposal-btu-calculation';
import { IRoom } from '@model/interfaces/room';
import { RoomService } from '../services/room.service';
import { ProposalBtuCalculationService } from '../services/proposal-btu-calculation.service';
import { ProposalFormSteps } from '@model/ProposalFormSteps';
import { CustomerProposalInterfaceStateService } from '../services/customer-proposal-interface-state.service';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { forkJoin, Subscription } from 'rxjs';
import { ICustomerProposal } from '@model/interfaces/customer-proposal';
import { IHeatPumpConfigurationStatus } from '@model/interfaces/custom/heat-pump-configuartion-status';
import { FileService } from '../../common/services/file.service';
import { CustomerProposalService } from '../services/customer-proposal.service';

@Component({
    selector: 'btu-calculations-form',
    styles: [
        `
            .table {
                color: black !important;
            }
            .btn-fab-md {
                float: right;
            }
        `,
    ],
    templateUrl: 'btu-calculations-form.component.html',
})
export class BtuCalculationsFormComponent implements OnInit, OnDestroy {
    rooms: IRoom[];
    btuCalculations: IBtuCalculationLineItem[] = [];
    btuCalculationsOnInit: IBtuCalculationLineItem[] = [];
    heatLossNotesOnInit: string;
    customerProposalId: number;
    proposalIsApproved = false;
    houseTotal = 0;
    ProposalFormSteps = ProposalFormSteps;
    subscriptions: Subscription = new Subscription();
    customerProposal: ICustomerProposal;
    heatPumpConfigurationStatus: IHeatPumpConfigurationStatus;
    completedFormSteps: ProposalFormSteps[];

    constructor(
        private roomService: RoomService,
        private route: ActivatedRoute,
        private btuCalculationService: ProposalBtuCalculationService,
        private proposalStateService: CustomerProposalInterfaceStateService,
        private notificationsService: NotificationsService,
        private customerProposalService: CustomerProposalService,
    ) {}

    ngOnInit(): void {
        this.customerProposalId = this.getIdFromRoute(this.route, 'customerProposalId');
        if (this.customerProposalId) {
            forkJoin([this.roomService.getAll(), this.btuCalculationService.getByProposalId(this.customerProposalId)]).subscribe(
                ([rooms, btuCalculations]) => {
                    this.rooms = rooms;
                    this.mapBtuCalculations(btuCalculations);
                    this.btuCalculationsOnInit = JSON.parse(JSON.stringify(this.btuCalculations));
                    this.heatLossNotesOnInit = this.customerProposal?.HeatLossNotes ?? '';
                },
            );
        } else {
            this.roomService.getAll().subscribe((rooms) => {
                this.rooms = rooms;
            });
        }
        this.initalizeSubscriptions();
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    private initalizeSubscriptions(): void {
        this.subscriptions.add(
            this.proposalStateService.customerProposal$.subscribe((value) => {
                this.customerProposal = value;
                // Lock a Proposal after approval
                if (!this.customerProposalService.proposalIsInProgress(this.customerProposal)) {
                    this.proposalIsApproved = true;
                }
            }),
        );
        this.subscriptions.add(
            this.proposalStateService.heatPumpConfigurationStatus$.subscribe((value) => {
                this.heatPumpConfigurationStatus = value;
            }),
        );
        this.subscriptions.add(
            this.proposalStateService.completedFormSteps$.subscribe((value) => {
                this.completedFormSteps = value;
            }),
        );
    }

    isCompletedFormStep(step: ProposalFormSteps): boolean {
        return this.completedFormSteps.indexOf(step) > -1;
    }

    private mapBtuCalculations(btuCalculations: IProposalBtuCalculation[]): void {
        this.btuCalculations = btuCalculations.map((b) => {
            return {
                Id: b.Id,
                InsulationFactor: b.InsulationFactor,
                Length: b.Length,
                RoomId: b.RoomId,
                RoomTotal: b.Length * b.Width * b.InsulationFactor,
                SquareFootage: b.Length * b.Width,
                Width: b.Width,
            };
        });
        this.updateHouseTotal();
    }

    addBtuCalculation(): void {
        this.btuCalculations.push({
            Id: 0,
            InsulationFactor: 0,
            Length: 0,
            RoomId: null,
            RoomTotal: 0,
            SquareFootage: 0,
            Width: 0,
        });
    }

    getIdFromRoute(route: ActivatedRoute, param: string): number {
        const id = route.snapshot.paramMap.get(param);
        const parsedInt = parseInt(id, 10);
        return isNaN(parsedInt) ? null : parsedInt;
    }

    updateCurrentFormStep(step: ProposalFormSteps): void {
        this.proposalStateService.currentFormStep$.next(step);
    }

    onCalculationsUpdate(btuCalculations: IBtuCalculationLineItem[]): void {
        this.btuCalculations = btuCalculations;
        this.updateHouseTotal();
        if (!btuCalculations) {
            return;
        }
        const formIsDirty = this.checkIfFormIsDirty(this.btuCalculationsOnInit, this.btuCalculations);
        this.customerProposalService.setFormIsDirty(formIsDirty);
    }

    onHeatLossNotesUpdate(): void {
        const formIsDirty = this.heatLossNotesOnInit !== this.customerProposal?.HeatLossNotes;
        this.customerProposalService.setFormIsDirty(formIsDirty);
    }

    private updateHouseTotal(): void {
        this.houseTotal = this.btuCalculations.reduce((total, item) => total + item.RoomTotal, 0);
        this.heatPumpConfigurationStatus.BTUThreshold = this.houseTotal;
        this.proposalStateService.heatPumpConfigurationStatus$.next(this.heatPumpConfigurationStatus);
    }

    saveBtuCalculations(): void {
        if (!this.customerProposal.HeatLossNotes) {
            this.notificationsService.error('Please add a valid Heat Loss Note.');
            return;
        }
        if (this.btuCalculations.length === 0) {
            this.notificationsService.error('Please add a valid BTU Calculation');
            return;
        }
        if (this.btuCalculations.some((b) => !b.Length || !b.Width)) {
            this.notificationsService.error('A valid Length and Width is required for BTU Calculations');
            return;
        }
        const mappedCalculations: IProposalBtuCalculation[] = this.btuCalculations.map((b) => {
            return {
                Id: b.Id,
                InsulationFactor: b.InsulationFactor,
                Length: b.Length,
                ProposalId: this.customerProposalId,
                RoomId: b.RoomId,
                Width: b.Width,
            };
        });
        forkJoin([
            this.customerProposalService.update(this.customerProposal),
            this.btuCalculationService.updateProposalBtuCalculations(this.customerProposalId, mappedCalculations),
        ]).subscribe(() => {
            this.notificationsService.success('Successfully updated BTU Calculations');
            this.updateCurrentFormStep(ProposalFormSteps.BasicInfo);
            this.proposalStateService.markStepCompleted(ProposalFormSteps.BtuCalculations);
            this.customerProposalService.setFormIsDirty(false);
        });
    }

    downloadBtuCalculationsPdf(): void {
        this.btuCalculationService.getBtuCalculationsPdf(this.customerProposalId).subscribe((answer) => {
            FileService.save(answer, 'BTU Calculations.pdf', 'application/pdf', true);
        });
    }

    private checkIfFormIsDirty(proposedLineItems: IBtuCalculationLineItem[], currentLineItems: IBtuCalculationLineItem[]): boolean {
        if (currentLineItems.length !== proposedLineItems.length) {
            return true;
        }
        for (let i = 0; i < currentLineItems.length; i++) {
            const currentLineItem = currentLineItems[i];
            const proposedLineItem = proposedLineItems[i];
            const lineItemsAreDifferent: boolean = this.checkLineItemsForDifferences(proposedLineItem, currentLineItem);
            if (lineItemsAreDifferent) {
                return true;
            }
        }
        return false;
    }

    private checkLineItemsForDifferences(proposedLineItem: IBtuCalculationLineItem, currentLineItem: IBtuCalculationLineItem): boolean {
        for (const key in currentLineItem) {
            if (proposedLineItem.hasOwnProperty.call(currentLineItem, key)) {
                let proposedLineItemField: string = proposedLineItem[key];
                let currentLineItemField: string = currentLineItem[key];
                if (['RoomId', 'InsulationFactor'].indexOf(key) >= 0) {
                    // RoomId & InsulationFactor are select lists, so values are stored as strings.
                    // Need to cast both values to strings to properly compare them.
                    proposedLineItemField = `${proposedLineItemField}`;
                    currentLineItemField = `${currentLineItemField}`;
                }
                if (proposedLineItemField !== currentLineItemField) {
                    return true;
                }
            }
        }
        return false;
    }
}
