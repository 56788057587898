import { Pipe } from '@angular/core';
import { format } from '@mt-ng2/format-functions';
import { IPhone } from '@mt-ng2/phone-control';

@Pipe({
    name: 'phone',
})
export class PhonePipe {
    transform(value: string | IPhone): string {
        if (typeof value === 'string') {
            return format.phone(value);
        }
        if (value?.Phone && typeof value.Phone === 'string') {
            if (value?.Extension && typeof value.Extension === 'string') {
                return format.phoneWithExt(value.Phone, value.Extension);
            }
            return format.phone(value.Phone);
        }
        return '';

    }
}
