import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IProposalPricingOverrideUpdateEvent } from '@model/interfaces/custom/proposal-pricing-override-update-event';

@Component({
    selector: 'override',
    template: '',
})

export class OverrideComponent {
    @Input() value: number;
    isEditing = false;
    @Output() onOverrideUpdate: EventEmitter<IProposalPricingOverrideUpdateEvent> = new EventEmitter<IProposalPricingOverrideUpdateEvent>();
    constructor(
    ) { }

    getOverrideClass(): string {
        return this.value ? 'overriden-value' : '';
    }

    saveOverride(key: string): void {
        this.onOverrideUpdate.emit({
            Key: key,
            Value: this.value,
        });
        this.isEditing = false;
    }

    removeOverride(key: string): void {
        this.onOverrideUpdate.emit({
            Key: key,
            Value: null,
        });
    }
}
