import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { MetaItemService } from '@mt-ng2/base-service';
import { IResidentialWarrantySetting } from '@model/interfaces/residential-warranty-setting';

@Injectable({
    providedIn: 'root',
})
export class ResidentialWarrantySettingService extends MetaItemService<IResidentialWarrantySetting> {
    constructor(public http: HttpClient) {
        super('ResidentialWarrantySettingService', 'Setting', 'SettingIds', '/residential-warranty-settings', http);
    }
}
