import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IBtuCalculationLineItem } from '@model/interfaces/custom/btu-calculation-line-item';
import { IRoom } from '@model/interfaces/room';
import { InsulationFactors } from '@model/InsulationFactors';
@Component({
    selector: '[btu-calculation-line-item]',
    templateUrl: 'btu-calculation-line-item.component.html',
})

export class BtuCalculationLineItemComponent {
    @Input() btuCalculations: IBtuCalculationLineItem[];
    @Input() index: number;
    @Input() rooms: IRoom[];
    @Input() proposalIsApproved: boolean;
    @Output() onBtuCalculationsUpdate: EventEmitter<IBtuCalculationLineItem[]> = new EventEmitter<IBtuCalculationLineItem[]>();
    insulationFactors = [InsulationFactors.Tight, InsulationFactors.Moderate, InsulationFactors.Average, InsulationFactors.Poor];

    constructor() { }

    onFieldUpdate(index: number): void {
        this.updateCalculationSquareFootage(index);
        this.updateCalculationRoomTotal(index);
        this.onBtuCalculationsUpdate.emit(this.btuCalculations);
    }

    updateCalculationRoomTotal(index: number): void {
        this.btuCalculations[index].RoomTotal = this.btuCalculations[index].Width * this.btuCalculations[index].Length * this.btuCalculations[index].InsulationFactor;
    }

    private updateCalculationSquareFootage(index: number): void {
        this.btuCalculations[index].SquareFootage = this.btuCalculations[index].Width * this.btuCalculations[index].Length;
    }

    removeBtuCalculation(index: number): void {
        this.btuCalculations.splice(index, 1);
        this.onBtuCalculationsUpdate.emit(this.btuCalculations);
    }
}
