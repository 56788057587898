<div>
    <div class="row">
        <div class="col-md-7">
            <h2>Proposal Job Details</h2>
        </div>
    </div>
</div>
<div *ngIf="formCreated">
    <form [formGroup]="proposalJobDetailsForm" (ngSubmit)="formSubmitted()">
        <div class="row">
            <div class="col-md-8">
                <div class="miles-form padded">
                    <h4>Proposal Job Detail</h4>
                    <div class="row">
                        <div class="col-md-6">
                            <mt-dynamic-field
                                (valueChanges)="jobTypeChanged($event)"
                                [field]="abstractProposalJobDetailControls.JobTypeId"
                                [form]="proposalJobDetailsForm"
                            ></mt-dynamic-field>
                        </div>
                        <div class="col-md-6">
                            <mt-dynamic-field
                                [field]="abstractProposalJobDetailControls.LineHideColorId"
                                [form]="proposalJobDetailsForm"
                            ></mt-dynamic-field>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6">
                            <mt-dynamic-field
                                [field]="abstractProposalJobDetailControls.JobDifficulty"
                                [form]="proposalJobDetailsForm"
                            ></mt-dynamic-field>
                        </div>
                        <div class="col-md-6">
                            <mt-dynamic-field
                                [field]="abstractProposalJobDetailControls.NoOfInstallers"
                                [form]="proposalJobDetailsForm"
                            ></mt-dynamic-field>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6">
                            <mt-dynamic-field
                                [field]="abstractProposalJobDetailControls.FoundationTypeId"
                                [form]="proposalJobDetailsForm"
                            ></mt-dynamic-field>
                        </div>
                        <div class="col-md-6">
                            <mt-dynamic-field
                                [field]="abstractProposalJobDetailControls.WallConstructionTypeId"
                                [form]="proposalJobDetailsForm"
                            ></mt-dynamic-field>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6">
                            <mt-dynamic-field
                                [field]="abstractProposalJobDetailControls.LiftTypeId"
                                [form]="proposalJobDetailsForm"
                            ></mt-dynamic-field>
                        </div>
                        <div class="col-md-6">
                            <mt-dynamic-field
                                [field]="abstractProposalJobDetailControls.OduElevationId"
                                [form]="proposalJobDetailsForm"
                            ></mt-dynamic-field>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6">
                            <mt-dynamic-field
                                [field]="abstractProposalJobDetailControls.DrainLineId"
                                [form]="proposalJobDetailsForm"
                            ></mt-dynamic-field>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <div class="pull-left">
                                <mt-dynamic-field
                                    [field]="abstractProposalJobDetailControls.SnakeJob"
                                    [form]="proposalJobDetailsForm"
                                ></mt-dynamic-field>
                            </div>
                            <div class="pull-left form-checkbox">
                                <mt-dynamic-field
                                    [field]="abstractProposalJobDetailControls.AdditionalCharges"
                                    [form]="proposalJobDetailsForm"
                                ></mt-dynamic-field>
                            </div>
                            <div class="pull-left form-checkbox">
                                <mt-dynamic-field
                                    [field]="abstractProposalJobDetailControls.RoughIn"
                                    [form]="proposalJobDetailsForm"
                                ></mt-dynamic-field>
                            </div>
                            <div class="pull-left form-checkbox">
                                <mt-dynamic-field
                                    [field]="abstractProposalJobDetailControls.RoughInBoxes"
                                    [form]="proposalJobDetailsForm"
                                ></mt-dynamic-field>
                            </div>
                            <div class="pull-left form-checkbox">
                                <mt-dynamic-field
                                    [field]="abstractProposalJobDetailControls.ReferralDiscount"
                                    [form]="proposalJobDetailsForm"
                                ></mt-dynamic-field>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <button type="submit" mtDisableClickDuringHttpCalls Class="btn btn-flat btn-primary" [disabled]="proposalIsApproved">
                                Save
                            </button>
                            <button type="button" Class="btn btn-flat btn-default" (click)="updateCurrentFormStep(ProposalFormSteps.BasicInfo)">
                                Cancel
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-4">
                <proposal-job-description [customerProposal]="customerProposal"></proposal-job-description>
            </div>
        </div>
    </form>
    <br />
</div>
