import { Component, Input, OnInit } from '@angular/core';
import { IManufacturer } from '@model/interfaces/manufacturer';
import { IProposalPricing } from '@model/interfaces/proposal-pricing';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { NumericControlTypes } from '@mt-ng2/numeric-control';
import { OverrideComponent } from '../override.component';
import { Subject } from 'rxjs';

@Component({
    selector: '[manufacturer-override]',
    styles: [`
        span.overriden-value {
            text-decoration: line-through !important;
            margin-right: 5px;
        }
        .btn-default, .btn-danger {
            float: right;
        }
        td {
            color: black;
        }
        .header-cell {
            background: #80808061;
        }
    `],
    templateUrl: 'manufacturer-override.component.html',
})

export class ManufacturerOverrideComponent extends OverrideComponent implements OnInit {
    @Input() proposalIsLocked = false;
    @Input() manufacturer: IManufacturer;
    @Input() proposalPricing: IProposalPricing;
    @Input() overrideUpdateSubject: Subject<void>;
    overrideThreshold = 1;
    numericControlTypes = NumericControlTypes;
    MATERIALS_MANUFACTURER = 1;
    key = 'MarkupPercentageOverride';
    disableOverrideButton = false;
    isProcessing = false;

    get canOverride(): boolean {
        return this.manufacturer.Id !== this.MATERIALS_MANUFACTURER && this.proposalPricing.ManufacturerId === this.manufacturer.Id;
    }

    constructor(private notificationsService: NotificationsService) {
        super();
    }

    ngOnInit(): void {
        if (this.proposalPricing.MarkupPercentageOverride && this.proposalPricing.ManufacturerId === this.manufacturer.Id) {
            this.value = this.proposalPricing.MarkupPercentageOverride;
        }
    }

    startEditing(): void {
        this.isEditing = true;
        this.isProcessing = true;
        this.disableOverrideButton = true; // Disable the button when editing starts
    }

    saveOverride(): void {
        if (this.manufacturer.Id === this.proposalPricing.ManufacturerId) {
            if (this.value > this.overrideThreshold) {
                this.notificationsService.error('Override value must be less than 100%');
                return;
            }

            super.saveOverride(this.key)
            this.overrideUpdateSubject.subscribe(()=>{              
                this.isProcessing = false;
                this.disableOverrideButton = false; 
                this.isEditing = false; 
            });


        }
    }

    removeOverride(): void {
        this.isProcessing = true;
        this.disableOverrideButton = true;
        if (this.manufacturer.Id === this.proposalPricing.ManufacturerId) {
            super.removeOverride(this.key);
        }
        this.overrideUpdateSubject.subscribe(()=>{              
            this.isProcessing = false;
            this.disableOverrideButton = false; 
        });
            this.value = null;
    }

    ngOnDestroy() {
        this.overrideUpdateSubject.unsubscribe();
    }
}
