<label>Accessories</label>
<table class="table table-bordered">
    <thead>
        <tr>
            <th>Part</th>
            <th class="th-numeric-input">Quantity</th>
            <th>Input distance from ODU to Branch Box</th>
            <th></th>
        </tr>
    </thead>
    <tbody>
        <tr *ngIf="selectedAccessories.length === 0">
            <td colspan="3">
                <i>No selected Accessories</i>
            </td>
        </tr>
        <tr *ngFor="let accessory of selectedAccessories; index as i">
            <th>
                <select
                    *ngIf="parts.length > 0"
                    class="form-control"
                    id="part"
                    [(ngModel)]="accessory.PartId"
                    (ngModelChange)="onPartUpdate($event, i)"
                    [ngModelOptions]="{ standalone: true }"
                    [disabled]="proposalIsApproved"
                >
                    <option *ngFor="let p of parts" [value]="p.Id">{{ p.Name }}</option>
                </select>
            </th>
            <th>
                <input
                    type="number"
                    class="form-control"
                    min="0"
                    [(ngModel)]="accessory.Quantity"
                    (ngModelChange)="updateHeatPumpStateService()"
                    [ngModelOptions]="{ standalone: true }"
                    [disabled]="proposalIsApproved"
                />
            </th>
            <th>
                <input
                    *ngIf="accessory.Part?.IsBranchBox"
                    type="number"
                    class="form-control"
                    min="0"
                    [(ngModel)]="accessory.Distance"
                    (ngModelChange)="updateHeatPumpStateService()"
                    [ngModelOptions]="{ standalone: true }"
                    [disabled]="proposalIsApproved"
                />
                <span *ngIf="!accessory.Part || !accessory.Part.IsBranchBox">N/A</span>
            </th>
            <th>
                <button class="btn btn-default" [disabled]="proposalIsApproved" (click)="removeAccessory(i)">Remove</button>
            </th>
        </tr>
    </tbody>
</table>
<div class="btn btn-primary btn-fab-md" (click)="addAccessory()" *ngIf="!proposalIsApproved">
    <span class="fa fa-plus"></span>
</div>
