import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { StaticMetaItemService } from '@mt-ng2/base-service';
import { ILiftType } from '../../../model/interfaces/lift-type';

@Injectable({
    providedIn: 'root',
})
export class LiftTypeService extends StaticMetaItemService<ILiftType> {
    constructor(public http: HttpClient) {
        super('LiftTypeService', 'LiftType', 'LiftTypeIds', '/lift-types', http);
    }

}
