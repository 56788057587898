<span [ngClass]="getOverrideClass()">{{ proposalPricing[key] | currency }}</span>
<span *ngIf="value"
    ><b>{{ value | currency }}</b></span
>
<button class="btn btn-default" [disabled]="proposalIsLocked" *ngIf="!isEditing && !value" (click)="isEditing = true">Override</button>
<ng-container *ngIf="isEditing">
    <input placeholder="Enter amount to override" class="form-control" type="number" min="0" max="99999" [(ngModel)]="value" />
    <button class="btn btn-primary" (click)="saveOverride()">Save</button>
</ng-container>
<button class="btn btn-danger" [disabled]="proposalIsLocked" *ngIf="!isEditing && value" (click)="removeOverride()">Remove</button>
