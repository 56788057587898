import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { StaticMetaItemService } from '@mt-ng2/base-service';
import { IFoundationType } from '../../../model/interfaces/foundation-type';

@Injectable({
    providedIn: 'root',
})
export class FoundationTypeService extends StaticMetaItemService<IFoundationType> {
    constructor(public http: HttpClient) {
        super('FoundationTypeService', 'FoundationType', 'FoundationTypeIds', '/foundation-types', http);
    }

}
