<div>
    <div class="row">
        <div class="col-md-7">
            <h2>Pricing Details</h2>
        </div>
    </div>
</div>
<div *ngIf="proposalPricing">
    <div class="row">
        <div class="col-md-6">
            <div class="miles-form padded">
                <h4>Hardware Totals</h4>
                <table class="table table-bordered">
                    <tbody>
                        <tr>
                            <td class="header-cell">Indoor Unit(s) w/Margin</td>
                            <td>{{ proposalPricing.IndoorUnitsCostWithMargin | currency }}</td>
                        </tr>
                        <tr>
                            <td class="header-cell">Outdoor Unit(s) w/Margin</td>
                            <td>{{ proposalPricing.OutdoorUnitsCostWithMargin | currency }}</td>
                        </tr>
                        <tr>
                            <td class="header-cell">Inventory Materials Total</td>
                            <td>{{ proposalPricing.InventoryMaterialsAndSuppliesCost | currency }}</td>
                        </tr>
                    </tbody>
                </table>
                <table class="table table-bordered">
                    <tbody>
                        <tr>
                            <td class="total-cell">Equipment Total</td>
                            <td>{{ proposalPricing.EquipmentTotal | currency }}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <div class="col-md-6">
            <proposal-job-description [customerProposal]="customerProposal"></proposal-job-description>
        </div>
    </div>

    <br />
    <div class="row">
        <div class="col-md-6">
            <div class="padded miles-form">
                <h4></h4>
                <table class="table table-bordered">
                    <tbody>
                        <tr>
                            <td class="total-cell">Labor Total</td>
                            <td>{{ proposalPricing.LaborTotal | currency }}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <div class="col-md-6">
            <div class="padded miles-form">
                <h4></h4>
                <table class="table table-bordered">
                    <tbody>
                        <tr>
                            <td class="total-cell">Misc. Charges Total</td>
                            <td>{{ calculateMiscCharge() | currency }}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
    <br />
    <div class="row">
        <div class="col-md-12">
            <div class="miles-form padded">
                <h4>Overrides</h4>
                <div class="row">
                    <div class="col-md-6">
                        <table class="table table-bordered">
                            <tbody>
                                <tr>
                                    <td class="header-cell">Installer Labor</td>
                                    <td>
                                        <proposal-pricing-override
                                            [proposalPricing]="proposalPricing"
                                            key="SumOfLaborBasedOnZone"
                                            overrideKey="SumOfLaborBasedOnZoneOverride"
                                            [value]="proposalPricing.SumOfLaborBasedOnZoneOverride"
                                            (onOverrideUpdate)="onOverrideUpdate($event)"
                                            [proposalIsLocked]="proposalIsLocked"
                                        ></proposal-pricing-override>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="header-cell">Electrician's Fee</td>
                                    <td>
                                        <proposal-pricing-override
                                            [proposalPricing]="proposalPricing"
                                            key="ElectricianFee"
                                            overrideKey="ElectricianFeeOverride"
                                            [value]="proposalPricing.ElectricianFeeOverride"
                                            (onOverrideUpdate)="onOverrideUpdate($event)"
                                            [proposalIsLocked]="proposalIsLocked"
                                        ></proposal-pricing-override>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="col-md-6">
                        <table class="table table-bordered" *ngIf="manufacturers && manufacturers.length > 0">
                            <tbody>
                                <tr
                                    *ngFor="let m of manufacturers"
                                    manufacturer-override
                                    [manufacturer]="m"
                                    [proposalPricing]="proposalPricing"
                                    (onOverrideUpdate)="onOverrideUpdate($event)"
                                    [proposalIsLocked]="proposalIsLocked"
                                    [overrideUpdateSubject]="overrideUpdateSubject"
                                ></tr>
                                <tr>
                                    <td class="header-cell">Misc. Expenses</td>
                                    <td>
                                        <proposal-pricing-override
                                            [proposalPricing]="proposalPricing"
                                            key="MiscExpenses"
                                            overrideKey="MiscExpensesOverride"
                                            [value]="proposalPricing.MiscExpensesOverride"
                                            (onOverrideUpdate)="onOverrideUpdate($event)"
                                            [proposalIsLocked]="proposalIsLocked"
                                        ></proposal-pricing-override>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <br />
    <div class="row">
        <div class="col-md-12">
            <div class="miles-form padded">
                <h4>Final Project Cost</h4>
                <div class="row">
                    <div class="col-md-6">
                        <table class="table table-bordered table-project-cost">
                            <tbody>
                                <tr>
                                    <td class="header-cell">Landed</td>
                                    <td>{{ proposalPricing.LandedCost | currency }}</td>
                                    <td class="header-cell">Admin Fee</td>
                                    <td>{{ proposalPricing.AdminFee | currency }}</td>
                                </tr>
                                <br />
                                <tr>
                                    <td class="header-cell">Commission (Estimated)</td>
                                    <td>{{ proposalPricing.ExpectedCommission | currency }}</td>
                                    <td class="header-cell">Customer Deposit Amount</td>
                                    <td>
                                        <customer-deposit-amount-override
                                            [proposalPricing]="proposalPricing"
                                            key="CustomerDepositAmount"
                                            overrideKey="CustomerDepositAmountOverride"
                                            [value]="proposalPricing.CustomerDepositAmountOverride"
                                            (onOverrideUpdate)="onOverrideUpdate($event)"
                                            [proposalIsLocked]="proposalIsLocked"
                                        ></customer-deposit-amount-override>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="col-md-6">
                        <table class="table table-bordered table-project-cost">
                            <tbody>
                                <tr>
                                    <td class="total-cell">Job Total</td>
                                    <td>{{ proposalPricing.OrderTotal | currency }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="action-buttons">
        <button class="btn btn-default btn-action" (click)="navigateToPricingPage()">View Proposal</button>
        <button *ngIf="canViewAdminCheckout" class="btn btn-default btn-action" (click)="navigateToCheckoutPage()">View Admin Checkout</button>
        <button class="btn btn-default btn-action" [disabled]="proposalIsLocked" (click)="displayLoanCalculator()">Loan Calculator</button>
        <button class="btn btn-default btn-action" id="btn-return" (click)="navigateToEstimator()">Return to Estimator</button>
    </div>
    <mt-modal-wrapper
        *ngIf="proposalPricing"
        title="Loan Calculator"
        [autoShow]="false"
        [showActions]="false"
        [allowOutsideClick]="false"
        (ready)="loanCalculatorModalApi = $event"
    >
        <proposal-loan-calculator
            *ngIf="proposalLoan"
            [proposalLoan]="proposalLoan"
            [customerProposalId]="proposalPricing.CustomerProposalId"
            [orderTotal]="proposalPricing.OrderTotal"
            (onDoneEditing)="loanCalculatorModalApi.close()"
        ></proposal-loan-calculator>
    </mt-modal-wrapper>
</div>
