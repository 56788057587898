import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ICustomerProposal } from '@model/interfaces/customer-proposal';
import { ProposalFormSteps } from '@model/ProposalFormSteps';
import { AuthService, ClaimsService, ClaimValues } from '@mt-ng2/auth-module';
import { UserService } from '../../users/user.service';
import { Subscription } from 'rxjs';
import { CustomerProposalInterfaceStateService } from '../services/customer-proposal-interface-state.service';
import { ProposalPricingService } from '../services/proposal-pricing.service';
import { IUser } from '@model/interfaces/user';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { CustomerProposalService } from '../services/customer-proposal.service';
import { ModalService } from '@mt-ng2/modal-module';
import { ClaimTypes } from '@model/ClaimTypes';
import { CustomerProposalStatuses } from '@model/CustomerProposalStatuses';

@Component({
    selector: 'proposal-status',
    styles: [
        `
            .nav-option {
                display: inline-block;
            }
            .fa-times-circle {
                color: red !important;
            }
            .fa-check-circle {
                color: green !important;
            }
            .btn-finalize {
                padding: 20px;
                font-size: 20px;
                min-width: 180px;
            }
        `,
    ],
    templateUrl: 'proposal-status.component.html',
})
export class ProposalStatusComponent implements OnInit {
    subscriptions: Subscription = new Subscription();
    completedFormSteps: ProposalFormSteps[];
    ProposalFormSteps = ProposalFormSteps;
    private COMPLETED_THRESHOLD = 5;
    customerProposal: ICustomerProposal;
    currentUser: IUser;
    canEdit: boolean;
    formIsDirty: boolean;

    get proposalHasPricings(): boolean {
        return this.customerProposal.ProposalPricings.length > 0;
    }
    get btuCalculationIsRequired(): boolean {
        return this.currentUser && this.currentUser.RequiresBtuCalculation;
    }

    constructor(
        private stateService: CustomerProposalInterfaceStateService,
        private proposalPricingService: ProposalPricingService,
        private router: Router,
        private authService: AuthService,
        private userService: UserService,
        private notificationsService: NotificationsService,
        private customerProposalService: CustomerProposalService,
        private modalService: ModalService,
        private claimsService: ClaimsService,
    ) {}

    ngOnInit(): void {
        this.canEdit = this.claimsService.hasClaim(ClaimTypes.CustomerProposals_CanEditProposal, [ClaimValues.FullAccess]);
        this.userService.getById(this.authService.currentUser.getValue().Id).subscribe((user) => {
            this.currentUser = user;
        });
        this.subscriptions.add(
            this.stateService.customerProposal$.subscribe((value) => {
                this.customerProposal = value;
            }),
        );
        this.subscriptions.add(
            this.stateService.completedFormSteps$.subscribe((value) => {
                this.completedFormSteps = value;
            }),
        );
        this.customerProposalService.dataTransferObservable.subscribe((formIsDirty) => {
            this.formIsDirty = formIsDirty;
        });
    }

    isCompletedFormStep(step: ProposalFormSteps): boolean {
        return this.completedFormSteps.indexOf(step) > -1;
    }

    updateCurrentFormStep(step: ProposalFormSteps): void {
        if (!this.formIsDirty) {
            this.stateService.currentFormStep$.next(step);
            return;
        }

        this.modalService
            .showModal({
                allowOutsideClick: false,
                showCancelButton: true,
                text: 'Are you sure you want to proceed without saving? Unsaved changes may be lost.',
                title: 'Are you sure?',
                icon: 'warning',
            })
            .subscribe((result) => {
                if (!result.isConfirmed) {
                    return;
                }

                this.stateService.currentFormStep$.next(step);
                this.formIsDirty = false;
            });
    }

    proposalIsComplete(): boolean {
        if (this.customerProposal && this.customerProposal.StatusId !== CustomerProposalStatuses.InProgress) {
            return true;
        }
        if (this.currentUser) {
            return (
                (!this.currentUser.RequiresBtuCalculation && this.completedFormSteps.length === this.COMPLETED_THRESHOLD - 1) ||
                this.completedFormSteps.length === this.COMPLETED_THRESHOLD
            );
        }
        return false;
    }

    checkIfUserRequiresBtuCalculation(): void {
        if (
            this.btuCalculationIsRequired &&
            this.completedFormSteps.indexOf(ProposalFormSteps.BtuCalculations) === -1 &&
            !this.proposalIsComplete()
        ) {
            this.notificationsService.error('You need to add BTU Calcuations before configuring Heat Pumps');
            return;
        }
        this.updateCurrentFormStep(ProposalFormSteps.HeatPumps);
    }

    finalizeProposalDetails(): void {
        if (this.proposalHasPricings) {
            // If they have updated any details we need to regenerate the Pricing on the Proposal
            this.proposalPricingService.updateProposalPricings(this.customerProposal.ProposalPricings[0].Id).subscribe((proposalId) => {
                void this.router.navigate([`/estimator/proposal/${this.customerProposal.Id}/pricing`, proposalId]);
            });
        } else {
            this.proposalPricingService.generateProposalPricings(this.customerProposal.Id).subscribe((proposalId) => {
                void this.router.navigate([`/estimator/proposal/${this.customerProposal.Id}/pricing`, proposalId]);
            });
        }
    }

    navigateToPricingPage(): void {
        if (this.proposalHasPricings) {
            // If they have updated any details we need to regenerate the Pricing on the Proposal
            this.proposalPricingService.updateProposalPricings(this.customerProposal.ProposalPricings[0].Id).subscribe(() => {
                void this.router.navigate([
                    `/estimator/proposal/${this.customerProposal.Id}/pricing/${this.customerProposal.ProposalPricings[0].Id}/page`,
                ]);
            });
        }
    }

    copyCustomerProposal(): void {
        this.modalService
            .showModal({
                input: 'text',
                text: 'Please enter a name for this copy',
                title: 'Copy Proposal',
                icon: 'question',
            })
            .subscribe((result) => {
                if (result.isConfirmed) {
                    this.customerProposalService.copyCustomerProposal(this.customerProposal.Id, result.value as string).subscribe((answer) => {
                        void this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
                            void this.router.navigate([`/estimator/customer/${this.customerProposal.CustomerId}/proposal`, answer]);
                        });
                    });
                }
            });
    }
}
