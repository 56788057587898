import { Injectable } from '@angular/core';
import { IHeatPumpConfigurationStatus } from '@model/interfaces/custom/heat-pump-configuartion-status';

@Injectable()
export class HeatPumpService {
    constructor() { }

    validateHeatPumpConfig(status: IHeatPumpConfigurationStatus): string[] {
        const errors: string[] = [];

        if (status.Quantity < status.MinIDU) {
          errors.push(`The number of heat pumps is less than the number of minimum IDUs.`);
        }

        if (status.Quantity > status.MaxIDU) {
          errors.push(`The number of heat pumps is greater than the number of maximum IDUs.`);
        }

        if (status.BTUTotal < status.BTUThreshold) {
            errors.push(`The BTU Total is less than the BTU Threshold. HEAT PUMPS WILL HAVE TO BE RE-ENTERED`);
        }

        if (status.LineSet <= 0) {
            errors.push(`The Line Set is less than or equal to zero.`);
        }

        if (status.DrainLine <= 0) {
            errors.push(`The Drain Line is less than or equal to zero.`);
        }

        if (status.RequiredBranchBoxes > status.BranchBoxes) {
            errors.push(`The number of outdoor heat pumps requiring branch boxes is less than the number of branch box accessories.`);
        }

        return errors;
      }
}
