import { RoomDynamicControls, IRoomDynamicControlsParameters } from '../form-controls/room.form-controls';
import { DynamicField, DynamicFieldType, DynamicFieldTypes, DynamicLabel, InputTypes, NumericInputTypes } from '@mt-ng2/dynamic-form';
import { Validators } from '@angular/forms';
import { IRoom } from '@model/interfaces/room';

export class RoomDynamicControlsPartial extends RoomDynamicControls {
    constructor(room?: IRoom, additionalParamters?: IRoomDynamicControlsParameters) {
        super(room, additionalParamters);

        this.Form = {
            Name: new DynamicField({
                formGroup: this.formGroup,
                label: 'Name',
                name: 'Name',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [Validators.maxLength(50)],
                validators: { 'maxlength': 50 },
                value: room && room.hasOwnProperty('Name') && room.Name !== null ? room.Name.toString() : '',
            }),
        };

        this.View = {
            Name: new DynamicLabel({
			    label: 'Name',
			    value: room && this.hasOwnProperty('Name') && room.Name !== null ? room.Name.toString() : '',
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Input,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
        };

    }
}
