import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { environment } from '@environment';
import { IIndoorHeatPumpLineItem } from '@model/interfaces/custom/indoor-heat-pump-line-item';
import { IIndoorHeatPumpLineItemUpdateEvent } from '@model/interfaces/custom/indoor-heat-pump-line-item-update-event';
import { ILineHideType } from '@model/interfaces/line-hide-type';
import { IPart } from '@model/interfaces/part';
import { IRoom } from '@model/interfaces/room';
import { ProposalIndoorHeatPumpService } from 'admin-portal/estimator/services/proposal-indoor-heat-pump.service';

@Component({
    selector: '[indoor-heat-pump-line-item]',
    styles: [`
        .customerProposalForm__numberSelect--error {
            color: red;
            border-color: red;
        }
        #lineHideValue, #lineset {
            width: 100px;
        }
    `],
    templateUrl: 'indoor-heat-pump-line-item.component.html',
})

export class IndoorHeatPumpLineItemComponent implements OnChanges {
    @Input() proposalIsApproved: boolean;
    @Input() index: number;
    @Input() selectedIndoorHeatPumps: IIndoorHeatPumpLineItem[];
    @Input() rooms: IRoom[];
    @Input() lineHideTypes: ILineHideType[];
    @Input() parts: IPart[];
    @Output() onSelectedHeatPumpsUpdate: EventEmitter<IIndoorHeatPumpLineItemUpdateEvent> = new EventEmitter<IIndoorHeatPumpLineItemUpdateEvent>();
    imgPath = `${environment.docPath}heat-pump-images/`;
    heatPumpImgPath: string;

    constructor(
        private proposalIndoorHeatPumpService: ProposalIndoorHeatPumpService,
    ) { }

    ngOnChanges(): void {
        if (this.parts.length > 0 && this.selectedIndoorHeatPumps) {
            const partId = this.selectedIndoorHeatPumps[this.index].PartId;
            if (partId) {
                const selectedPart = this.parts.find((p) => p.Id === +partId);
                this.heatPumpImgPath = selectedPart?.Image ? this.imgPath + selectedPart?.Image : '';
            }
        }
    }

    onPartUpdate(partId: string, index: number): void {
        const selectedPart = this.parts.find((p) => p.Id === +partId);
        this.selectedIndoorHeatPumps[index].PartId = selectedPart.Id;
        this.selectedIndoorHeatPumps[index].Quantity = 1;
        this.selectedIndoorHeatPumps[index].BTUCapacity = selectedPart.BtuCapacity;
        // BUG: The property name is being generated incorrectly by the model generator
        // i.e. should be 'IndoorUnitType' not 'IndoorUnit'
        this.selectedIndoorHeatPumps[index].UnitType = selectedPart['IndoorUnitType']?.Name;
        this.selectedIndoorHeatPumps[index].LineSetPorts = this.proposalIndoorHeatPumpService.buildLineSetPortLabel(selectedPart);
        this.heatPumpImgPath = selectedPart.Image ? this.imgPath + selectedPart.Image : '';
        this.onSelectedHeatPumpsUpdate.emit({
            RequiresBranchBoxPrompt: selectedPart.RequiresBranchBox,
            SelectedPumps: this.selectedIndoorHeatPumps,
        });
    }

    onRoomUpdate(): void {
        this.onSelectedHeatPumpsUpdate.emit({
            RequiresBranchBoxPrompt: false,
            SelectedPumps: this.selectedIndoorHeatPumps,
        });
    }

    onFieldUpdate(index: number, propertyName: string): void {
        if (!this.selectedIndoorHeatPumps[index][propertyName]) {
            this.selectedIndoorHeatPumps[index][propertyName] = 0;
        }
        this.onSelectedHeatPumpsUpdate.emit({
            RequiresBranchBoxPrompt: false,
            SelectedPumps: this.selectedIndoorHeatPumps,
        });
    }

    adjustPartQuantity(quantity: number, index: number): void {
        if (this.selectedIndoorHeatPumps[index].PartId) {
            const selectedPart = this.parts.find((p) => p.Id === this.selectedIndoorHeatPumps[index].PartId);
            this.selectedIndoorHeatPumps[index].Quantity += quantity;
            this.selectedIndoorHeatPumps[index].BTUCapacity = selectedPart.BtuCapacity * this.selectedIndoorHeatPumps[index].Quantity;
            this.onSelectedHeatPumpsUpdate.emit({
                RequiresBranchBoxPrompt: false,
                SelectedPumps: this.selectedIndoorHeatPumps,
            });
        }
    }

    removeIndoorHeatPump(index: number): void {
        this.selectedIndoorHeatPumps.splice(index, 1);
        this.onSelectedHeatPumpsUpdate.emit({
            RequiresBranchBoxPrompt: false,
            SelectedPumps: this.selectedIndoorHeatPumps,
        });
    }
}
