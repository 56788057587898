import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MetaItemService, StaticMetaItemService } from '@mt-ng2/base-service';
import { ICategory } from '@model/interfaces/category';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class CategoryService extends StaticMetaItemService<ICategory> {
    constructor(public http: HttpClient) {
        super('CategoryService', 'Category', 'CategoryIds', '/estimator/categories', http);
    }

    override updateItems(items: ICategory[]): Observable<ICategory[]> {
        return this.http.put<ICategory[]>('/estimator/categories', items);
    }
}
